import { observable, action, computed } from "mobx";
import { getAll, getContractById, create, update, archive, unarchive } from "./ContractService";
import MeetingStore from "../meeting/MeetingStore";

/**
 * the menu doesn't rerender when a contract changes because
 * you aren't updating the contracts observable.
 */
class ContractStore {

  @observable contracts = [];
  @observable currentContract = null;
  @observable meetingStore = null

  constructor() {
    this.meetingStore = new MeetingStore();
    this.fetchContracts();
  }

  @computed get archivedContracts() {
    if (!this.contracts) return null;
    return this.contracts.filter(c => c.is_archived === 1);
  }

  @computed get unarchivedContracts() {
    if (!this.contracts) return null;
    return this.contracts.filter(c => c.is_archived === 0);
  }

  @action.bound
  fetchContracts() {
    return getAll()
      .then(data => {
        this.contracts = data;
        return Promise.resolve(data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  @action.bound
  fetchContract(contractId, forceRefresh = false) {
    //read from list in memory first.
    if (this.contracts.length && !forceRefresh) {
      const match = this.contracts.find(c => c.id === contractId) || null;
      if (match) {
        this.currentContract = match;
        return Promise.resolve(match);
      }
    }
    return getContractById(contractId).then(data => {
      this.currentContract = data;
      return Promise.resolve(data);
    });
  }

  @action.bound
  createContract(formData) {
    return create(formData).then(data => {
      this.currentContract = data;
      this.contracts.push(data);
      return Promise.resolve(data);
    });
  }

  @action.bound
  updateContract(formData) {
    return update(this.currentContract.id, formData).then(data => {
      this.currentContract = data;
      const updatedContracts = this.contracts.map(c => {
        if (c.id === data.id) {
          return data;
        }
        return c;
      });
      this.contracts.replace(updatedContracts);
      return Promise.resolve(data);
    });
  }

  @action.bound
  archiveContract(id) {
    return archive(id).then(data => {
      const updatedContracts = this.contracts.map(c => {
        if (c.id === data.id) {
          return data;
        }
        return c;
      });
      this.contracts.replace(updatedContracts);
      return Promise.resolve(data);
    });
  }

  @action.bound
  unarchiveContract(id) {
    return unarchive(id).then(data => {
      const updatedContracts = this.contracts.map(c => {
        if (c.id === data.id) {
          return data;
        }
        return c;
      });
      this.contracts.replace(updatedContracts);
      return Promise.resolve(data);
    });
  }
}

export default ContractStore;
