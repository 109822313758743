import _isArray from "lodash/fp/isArray";
import _isObject from "lodash/fp/isObject";
import _mapValues from "lodash/fp/mapValues";
import { format, parseISO } from "date-fns";

/**
 * Library for shared form related functions
 */

/**
 * setEmptyValuesToNull
 *  Recursively change string values with length 0 to null
 * @param  {Object} values
 * @return {Object}
 */
export function setEmptyValuesToNull(values) {
  if (typeof values === "string" && values.length === 0) {
    return null;
  }

  if (_isArray(values)) {
    return values.map(setEmptyValuesToNull);
  }

  if (_isObject(values)) {
    return _mapValues(setEmptyValuesToNull, values);
  }
  return values;
}

export function convertDates(values) {
  if (!values) return;
  return Object.keys(values).reduce((acc, key) => {
    if (["start_date", "end_date"].includes(key)) {
      acc[key] = format(new Date(parseISO(values[key])), "yyyy-MM-dd");
      return acc;
    }
    acc[key] = values[key];
    return acc;
  }, {});
}
