import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { format, addHours, roundToNearestMinutes } from "date-fns";

import Box from "../../uikit/box/Box";
import Icon from "../../uikit/icon/Icon";
import { Table } from "../../uikit/table";
import Modal from "../../uikit/modal/Modal";
import Button from "../../uikit/button/Button";
import Text, { H3 } from "../../uikit/text/Text";

import MeetingForm from "../form/MeetingForm";
import AverageLabel from "../../shared/average/AverageLabel";
import RatingDotLabel from "../../shared/rating/RatingDotLabel";

const STATUS = {
  LIST: "LIST",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

function createMarkup(value) {
  return { __html: value };
}

function MeetingList({
  projectStore,
  meetings,
  isProjectPage = false,
  onRowClick,
  onPrevClick,
  onNextClick,
  isDashboard = false,
  pages = [],
  currentPage = 0,
  ...rest
}) {
  const [status, setStatus] = useState(STATUS.LIST);
  return (
    <Box {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <H3 mb={4}>Recent Meetings</H3>
        {isProjectPage && (
          <Button
            m={2}
            onClick={() => {
              setStatus(STATUS.CREATE);
            }}
          >
            + New Meeting
          </Button>
        )}
      </Box>

      {meetings && (
        <Table>
          <thead>
            <tr>
              <th>
                <Text fontFamily="montserrat">Date</Text>
              </th>
              <th>
                <Text fontFamily="montserrat">Contract</Text>
              </th>
              <th>
                <Text fontFamily="montserrat">Project</Text>
              </th>
              <th>
                <Text fontFamily="montserrat">Meeting</Text>
              </th>
              <th width="120px">
                <Text fontFamily="montserrat">Progress</Text>
              </th>
              <th>
                <Text fontFamily="montserrat">Average Rating</Text>
              </th>
              <th width="40px"></th>
            </tr>
          </thead>
          <tbody>
            {meetings.map((meeting, i) => (
              <tr key={i}>
                <td className="date">
                  <Text fontSize={1}>
                    {format(new Date(meeting.meeting_start_date), "MM.dd.yy")}
                  </Text>
                </td>
                <td className="contract">
                  <Text
                    el={NavLink}
                    to={`/contract/${meeting.contract_id}`}
                    fontSize={1}
                  >
                    {meeting.contract_name}
                  </Text>
                </td>
                <td className="project">
                  <Text
                    el={NavLink}
                    to={`/project/${meeting.project_id}`}
                    fontSize={1}
                  >
                    {meeting.project_name}
                  </Text>
                </td>
                <td className="meeting">
                  <Text
                    fontSize={1}
                    fontWeight="bold"
                    dangerouslySetInnerHTML={createMarkup(meeting.meeting_name)}
                  ></Text>
                </td>
                <td className="progress" width="120px">
                  <AverageLabel average={meeting.progress.toFixed(2)} />
                </td>
                <td>
                  <RatingDotLabel rating={meeting.average_rating} />
                </td>
                <td style={{ padding: 0 }} width="40px">
                  <Icon
                    color="primarys.0"
                    icon={FiChevronRight}
                    onClick={() => {
                      onRowClick && onRowClick(meeting);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          {isDashboard && pages && (
            <tfoot>
              <tr>
                <td style={{ backgroundColor: "#ffffff" }} colSpan="2"></td>
                {currentPage > 0 ? (
                  <td style={{ backgroundColor: "#ffffff" }}>
                    <Icon
                      color="primarys.0"
                      icon={FiChevronLeft}
                      onClick={() => {
                        onPrevClick();
                      }}
                    />
                  </td>
                ) : (
                  <td style={{ backgroundColor: "#ffffff" }}></td>
                )}
                <td style={{ backgroundColor: "#ffffff" }}>
                  {pages.map((page) => (
                    <span>
                      {currentPage == page - 1 ? (
                        <strong
                          style={{
                            backgroundColor: "#f4f5f7",
                            border: "solid 1px #4a4a4a",
                            padding: "1rem",
                          }}
                        >
                          {page}
                        </strong>
                      ) : (
                        <span
                          style={{
                            backgroundColor: "#f4f5f7",
                            padding: "1rem",
                          }}
                        >
                          {page}
                        </span>
                      )}
                    </span>
                  ))}
                </td>
                {currentPage < pages.length - 1 ? (
                  <td style={{ backgroundColor: "#ffffff" }}>
                    <Icon
                      color="primarys.0"
                      icon={FiChevronRight}
                      onClick={() => {
                        onNextClick();
                      }}
                    />
                  </td>
                ) : (
                  <td style={{ backgroundColor: "#ffffff" }}></td>
                )}
                <td style={{ backgroundColor: "#ffffff" }} colSpan="2"></td>
              </tr>
            </tfoot>
          )}
        </Table>
      )}

      <Modal
        title="Create Meeting"
        isOpen={status === STATUS.CREATE}
        onRequestClose={() => {
          setStatus(STATUS.LIST);
        }}
      >
        <MeetingForm
          defaultValues={{
            start_date: roundToNearestMinutes(new Date(), { nearestTo: 15 }),
            end_date: roundToNearestMinutes(addHours(new Date(), 1), {
              nearestTo: 15,
            }),
          }}
          onSubmit={(data) => {
            return projectStore.meetingStore.create(data).then(() => {
              setStatus(STATUS.LIST);
            });
          }}
          onCancel={() => {
            setStatus(STATUS.LIST);
          }}
        />
      </Modal>

      <Modal
        title="Update Meeting"
        isOpen={
          status === STATUS.UPDATE &&
          projectStore.meetingStore.currentMeeting !== null
        }
        onRequestClose={() => {
          setStatus(STATUS.LIST);
        }}
      >
        <MeetingForm
          defaultValues={
            projectStore.meetingStore &&
            projectStore.meetingStore.getCurrentMeetingFormValues()
          }
          onSubmit={(data) => {
            return projectStore.meetingStore.update(data).then(() => {
              setStatus(STATUS.LIST);
            });
          }}
          onCancel={() => {
            setStatus(STATUS.LIST);
          }}
        />
      </Modal>
    </Box>
  );
}

export default inject("projectStore")(observer(MeetingList));
