import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Button from "../../uikit/button/Button";
import TextField from "../../uikit/form/textfield/TextField";
import { useForm } from "react-hook-form";
import { string, object } from "yup";

const Schema = object().shape({
  email_address: string()
    .email()
    .required("Email is required"),
  password: string().required("Password is required")
});

function LoginForm({ authStore, redirect, history}) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema
  });
  const [serverErrors, setServerErrors] = useState(null);

  const onSubmit = data => {
    setServerErrors(null);
    authStore.login(data).then(res => {
      if(redirect) {
        history.push(redirect);
      }
    })
    .catch(error => {
      if(!error.success) {
        setServerErrors("Incorrect username or password");
      } else {
        setServerErrors("We could not log you in. Please try again.");
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box my={5}>
          <TextField
            required
            label="Email"
            name="email_address"
            ref={register}
            error={errors.email_address && errors.email_address.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            required
            type="password"
            label="Password"
            name="password"
            ref={register}
            error={errors.password && errors.password.message}
          />
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Text el={Link} to="/reset-password" fontFamily="montserrat" fontSize={0} color="white" display="block" mb={6}>
            Forgot your password?
          </Text>
          {serverErrors && (
            <Text textAlign="center" color="accents.0" mb={4}>
              {serverErrors}
            </Text>
          )}
          <Button variant="secondary" color="white" type="submit" px={8}>
            Log In
          </Button>
        </Box>
      </form>
    </>
  );
}

export default inject("authStore")(observer(LoginForm));
