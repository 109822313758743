import React from "react";
import { withTheme } from "styled-components";
import Box from "../../box/Box";
import Icon from "../../icon/Icon";
import {
  MdCancel,
  MdCheckBox,
  MdAddCircleOutline,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdCheckBoxOutlineBlank,
} from "react-icons/md";

const CheckToggleIcon = ({ checked, isFeedback, ...rest }) =>  {
  if (!isFeedback) {
    return checked ? (
      <Icon size={24} icon={MdCheckBox} {...rest} />
    ) : (
      <Icon size={24} icon={MdCheckBoxOutlineBlank} {...rest} />
    );
  } else {
    return checked ? (
      <Icon size={24} icon={MdCancel} {...rest} />
    ) : (
      <Icon size={24} icon={MdAddCircleOutline} {...rest} />
    );
  }
} 

const RadioToggleIcon = ({ checked, isFeedback, ...rest }) =>
  checked ? (
    <Icon size={24} icon={MdRadioButtonChecked} {...rest} />
  ) : (
    <Icon size={24} icon={MdRadioButtonUnchecked} {...rest} />
  );

function ToggleIcon({ theme, checked, isFeedback = false, type, color }) {
  const Comp = type === "radio" ? RadioToggleIcon : CheckToggleIcon;
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Comp
        checked={checked}
        size={22}
        isFeedback={isFeedback}
        color={color ? color : checked ? theme.component.toggle.active.borderColor : theme.component.toggle.initial.borderColor}
      />
    </Box>
  );
}

ToggleIcon.defaultProps = {
  type: "checkbox",
};

export default withTheme(ToggleIcon);
