import { APIFetch, toBlob } from "../../lib/HttpClient";

export function getContractMeetings(contractId) {
  return APIFetch(`/meeting/get/meetingsByContract/${contractId}`).then(resp => {
    return Promise.resolve(resp.data.meetings ? resp.data.meetings : null);
  });
}

export function getProjectMeetings(projectId) {
  return APIFetch(`/meeting/get/meetingsByProject/${projectId}`).then(resp => {
    return Promise.resolve(resp.data.meetings ? resp.data.meetings : null);
  });
}

export function getRecentMeetings(offset) {
  return APIFetch(`/meeting/get/recentMeetings/${offset}`).then(resp => {
    return Promise.resolve(resp.data.meetings ? resp.data.meetings : null);
  }).catch(errors => {
    return Promise.reject(errors);
  });
}

export function getMeetingById(meetingId) {
  return APIFetch(`/meeting/${meetingId}`)
    .then(resp => {
      return Promise.resolve(resp.data.meeting ? resp.data.meeting : null);
    });
}

export function getReviewByMeetingId(meetingId) {
  return APIFetch(`/review/${meetingId}`)
    .then(resp => {
      return Promise.resolve(resp.data ? resp.data : null);
    });
}

export function createMeeting(payload) {
  return APIFetch(`/meeting/create`, {
      method: "POST",
      body: toBlob(payload)
    })
    .then(resp => {
      return Promise.resolve(resp.data.meeting ? resp.data.meeting : null);
    })
    .catch(e => {
      return Promise.reject(e);
    });
}

export function createOrUpdate(payload) {
  return APIFetch(`/meeting/create`, {
      method: "POST",
      body: toBlob(payload)
    })
    .then(resp => {
      return Promise.resolve(resp.data.meeting ? resp.data.meeting : null);
    })
    .catch(e => {
      console.log(e)
      //error object isn't the same as other endpoints.
      let errors = e.errors.reduce((acc, x) => {
        acc[x.param] = x.msg;
        return acc;
      }, {});
      return Promise.reject(errors);
    });
}

export function submitReview(meetingId, payload) {

  const promises = [];

  for (let i = 0; i < payload.length; ++i) {
    promises.push(APIFetch(`/review/create/${meetingId}`, { method: "POST", body: toBlob(payload[i]) }));
  }

  return Promise.all(promises)
    .then((res) => {
      return Promise.resolve(true);
    })
    .catch(errors => {
      return Promise.reject(errors);
    });
}

export function submitNonAttendance(meetingId, payload) {
  return APIFetch(`/review/didNotAttend/${meetingId}`, {
      method: "POST",
      body: toBlob(payload)
    })
    .then(resp => {
      return Promise.resolve(true);
    })
    .catch(e => {
      //error object isn't the same as other endpoints.
      let errors = e.errors.reduce((acc, x) => {
        acc[x.param] = x.msg;
        return acc;
      }, {});
      return Promise.reject(errors);
    });
}
