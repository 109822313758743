const REG = RegExp(/^([mpfw][trblxy]?|width|height|display|flex|alignItems|justifyContent|bg)$/);

export function splitBoxProps(props, regEx = REG) {
  let otherProps = {};
  let boxProps = Object.keys(props).reduce((acc, key) => {
    if (regEx.test(key)) {
      acc[key] = props[key];
    } else {
      otherProps[key] = props[key];
    }
    return acc;
  }, {});

  return [boxProps, otherProps];
}

export function hasValue(value) {
  return value !== "" && value !== undefined && value !== null && !(Array.isArray(value) && value.length === 0);
}
