import React, { useState, useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import Box from "../../uikit/box/Box";
import { theme } from "styled-tools";
import Logo from "../../uikit/logo/Logo";
import Text from "../../uikit/text/Text";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import Icon from "../../uikit/icon/Icon";
import { NavLink } from "react-router-dom";
import Button from "../../uikit/button/Button";

const StyledNavItem = styled(Box)`
  display: block;
  cursor: pointer;
  text-decoration: none;
  padding: ${theme("space.3")}px 0;
  span {
    user-select: none;
  }
  span {
    color: ${(p) =>
      p.color ? theme(`colors.${p.color}`) : theme("colors.primarys.0")};
  }

  &.active {
    background: ${theme("colors.primarys.0")};
    span {
      color: ${theme("colors.white")};
    }
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const ToggleButton = styled.div`
  width: 40px;
  height: 40px;
  background: ${theme("colors.accents.1")};
  border-radius: 50%;
  box-shadow: ${theme("shadows.2")};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

function NavItem({ icon, text, to, ...rest }) {
  return (
    <StyledNavItem el={NavLink} to={to} exact {...rest}>
      <Text textAlign="center" display="block" width={1}>
        {text}
      </Text>
    </StyledNavItem>
  );
}

const MobileContainer = styled.section`
  background: ${theme("colors.white")};
  position: absolute;
  width: 100%;
  z-index: 10000;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.41);
  bottom: 0;
  height: 76px;
`;

function Mobile(props) {
  const [open, setOpen] = useState(false);
  const [openHeight, setOpenHeight] = useState(0);
  const menuRef = useRef();

  useEffect(() => {
    if (menuRef.current) {
      setOpenHeight(menuRef.current.getBoundingClientRect().height);
    }
  }, [menuRef.current]);

  return (
    <MobileContainer
      style={{
        bottom: open ? openHeight : 0,
        transition: "all 300ms cubic-bezier(0, 0, 0, 1)",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        bg="white"
        zIndex="2"
        position="fixed"
        bottom="0"
        width={1}
      >
        <Logo width="180px" />
      </Box>
      <ToggleButton
        onClick={() => setOpen((s) => !s)}
        style={{
          zIndex: 3,
          right: 20,
          top: -18,
        }}
      >
        <Icon
          size={24}
          icon={open ? FiChevronDown : FiChevronUp}
          color="primarys.0"
        />
      </ToggleButton>
      <Box
        bg="white"
        width={1}
        position="absolute"
        zIndex="1"
        ref={menuRef}
        onClick={(e) => setOpen(false)}
      >
        <Box pt={4} borderBottom="1px solid" borderColor="grays.0">
          <NavItem text="Dashboard" to="/" />
          <NavItem text="Contracts" to="/contract" />
          <NavItem text="Archived" to="/contract/archive" />
          <NavItem text="Profile" to="/profile" />
          <StyledNavItem onClick={(e) => props.authStore.logout()}>
            <Text textAlign="center" display="block" width={1}>
              Logout
            </Text>
          </StyledNavItem>
        </Box>
        <Box pt={2} display="flex" flexDirection="column" alignItems="center">
          <Button
            m={2}
            width="140px"
            onClick={() => {
              props.uiStore.projectModalOpen = true;
            }}
          >
            + New Project
          </Button>
          <Button
            m={2}
            width="140px"
            variant="secondary"
            color="black"
            onClick={() => {
              props.uiStore.contractModalOpen = true;
            }}
          >
            + New Contract
          </Button>
        </Box>
      </Box>
    </MobileContainer>
  );
}

export default inject("authStore", "uiStore")(observer(Mobile));
