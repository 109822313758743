import React from "react";
import { FieldProvider, FieldConsumer } from "../field/FieldContext";
import { splitBoxProps } from "../field/utils";
import Box from "../../box/Box";
import ToggleIcon from "./ToggleIcon";
import { HelperText } from "../field";
import Text from "../../text/Text";

function CheckBox({ labelPosition = "right", type = "checkbox", ...rest }, ref) {

  const [boxProps, otherProps] = splitBoxProps(rest);
  const {
    children,
    helperText,
    label,
    error,
    icon,
    isValid,
    onBlur,
    onFocus,
    color,
    isFeedback,
    ...inputProps
  } = otherProps;

  return (
    <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
      <FieldConsumer>
        {({ fieldState, onBlur, onFocus }) => (
          <Box {...boxProps}>
            <Box as="label" display="flex" alignItems="flex-start" style={{ position: "relative", cursor: "pointer" }}>
              {labelPosition === "left" && (
                <Text pt={1} mr={2}>
                  {label}
                </Text>
              )}
              <input
                type={type}
                style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                onBlur={onBlur}
                onFocus={onFocus}
                {...inputProps}
                ref={ref}
              />
              <ToggleIcon checked={inputProps.checked} type={type} isFeedback={isFeedback} color={color} />
              {labelPosition === "right" && (
                <Text pt={1} ml={2} lineHeight={5}>
                  {label}
                </Text>
              )}
            </Box>
            {error || helperText ? (
              <HelperText fieldState={fieldState} pl={labelPosition === "right" ? "30px" : 0}>
                {error ? error : helperText}
              </HelperText>
            ) : null}
          </Box>
        )}
      </FieldConsumer>
    </FieldProvider>
  );
}

export default React.forwardRef(CheckBox);
