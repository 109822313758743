/**
 * Adapted from: https://github.com/werk85/fetch-intercept
 */

//decorate native Fetch API with interceptor capabilities.
window.fetch = (function (fetch) {
  return function (...args) {
    return interceptor(fetch, ...args);
  };
})(window.fetch);


let interceptors = [];


function interceptor(fetch, ...args) {
  const reversedInterceptors = interceptors.reduce((array, interceptor) => [interceptor].concat(array), []);
  let promise = Promise.resolve(args);

  // Register request interceptors
  reversedInterceptors.forEach(({ request, requestError }) => {
    if (request || requestError) {
      promise = promise.then(args => request(...args), requestError);
    }
  });

  // Register fetch call
  promise = promise.then(args => fetch(...args));

  // Register response interceptors
  reversedInterceptors.forEach(({ response, responseError }) => {
    if (response || responseError) {
      promise = promise.then(response, responseError);
    }
  });

  return promise;
}


/**
 * Register a new interceptor.
 * @param  {object} interceptor object with methods for intercepting requests and responses.
 * @return {object}             return object with unregister method.
 */
export function register(interceptor) {
  interceptors.push(interceptor);
  return {
    unregister: () => {
      const index = interceptors.indexOf(interceptor);
      if (index >= 0) {
        interceptors.splice(index, 1);
      }
    }
  }
}

export function clear() {
  interceptors = [];
}
