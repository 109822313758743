import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { observer, inject } from "mobx-react";
import { string, object } from "yup";

import Box from "../uikit/box/Box";
import Text from "../uikit/text/Text";
import Button from "../uikit/button/Button";
import SelectField from "../uikit/form/selectfield/SelectField";

import { mapServerErrors } from "../../lib/utils";
import { setEmptyValuesToNull } from "../shared/form/utils";

const Schema = object().shape({
  plan: string().required(),
});

function ProfileSubscriptionForm(props) {

  console.log(props);

  const [ values, setValues ] = useState(props.plans);
  const [ selectedValue, setSelectedValue ] = useState('');

  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema
  });

  const [serverErrors, setServerErrors] = useState(null);

  const onSubmit = data => {
    setServerErrors(null);
    props.onSubmit(setEmptyValuesToNull(data)).catch(error => {
      console.log("server errors", error);
      if (error.response.error_number) {
        setServerErrors(mapServerErrors(error.response.error_number));
      } else {
        setServerErrors("An error occurred");
      }
    });
  };

  const selectFieldOptions = values.length && values.map((data) =>
    <option 
      key={data.id}
      value={data.id}
    >
      {data.name} - ${data.price}
    </option>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>

      <Box mb={5}>
        <Box my={3}>
          <SelectField
            required
            label="Plan"
            name="plan"
            value={selectedValue}
            onChange={e => setSelectedValue(e.target.value)}
            ref={register}
            error={errors.plan && errors.plan.message}>
            {selectFieldOptions}
          </SelectField>
        </Box>
      </Box>

      <Box>
        {serverErrors && (
          <Text textAlign="center" color="accents.0" mb={4}>
            {serverErrors}
          </Text>
        )}
        <Button type="submit" mr={4}>
          Save
        </Button>
        <Button variant="secondary" color="black" onClick={props.onClose}>
          Cancel
        </Button>
      </Box>
    </form>
  );
}

export default inject("authStore")(observer(ProfileSubscriptionForm));
