import React from "react";
import Field from "../field/Field";
import { FieldProvider, FieldConsumer } from "../field/FieldContext";
import { splitBoxProps } from "../field/utils";
import { FaChevronDown } from "react-icons/fa";
import Icon from "../../icon/Icon";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

const StyledSelect = styled.select`
  box-sizing: border-box;
  border: none;
  background: none;
  color: ${theme("colors.text")};
  font-family: ${theme("fonts.montserrat")};
  font-size: ${theme("fontSizes.2")}px;
  letter-spacing: normal;
  line-height: 1;
  padding: 15px 10px 14px 10px;
  padding-right: ${ifProp("hasIcon", "50px", 0)};
  position: relative;
  width: 100%;
  z-index: 10;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
`;

function SelectField(props, ref) {
  const [boxProps, otherProps] = splitBoxProps(props);
  const { helperText, label, error, isValid, children, onBlur, onFocus, placeholder, ...inputProps } = otherProps;

  return (
    <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
      <FieldConsumer>
        {({ onBlur, onFocus, fieldState }) => (
          <Field
            fieldState={fieldState}
            boxProps={boxProps}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            error={error}
            icon={<Icon icon={FaChevronDown} size={16} color="secondarys.0" />}
          >
            <StyledSelect hasLabel={!!label} hasIcon={true} ref={ref} onBlur={onBlur} onFocus={onFocus} placeholder={placeholder} {...inputProps}>
              {children}
            </StyledSelect>
          </Field>
        )}
      </FieldConsumer>
    </FieldProvider>
  );
}

export default React.forwardRef(SelectField);
