import React from "react";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    text-align: left;
  }
  tbody tr {
    border-bottom: 20px solid ${theme("colors.white")};
  }
  th {
    padding: 8px 20px;
  }
  td {
    padding: 22px 20px;
    background-color: ${theme("colors.grays.0")};
  }
`;

export const SlimTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    text-align: left;
  }
  tbody tr {
    border-top: 1px solid ${theme("colors.grays.1")};
  }
  tbody tr.feedback-row {
    border-top: 0;
  }
  tbody tr.feedback-row td {
    padding-top: 15px;
  }
  th {
    padding: 8px 0;
  }
  td {
    padding: 22px 10px;
    background-color: ${theme("colors.white")};
  }
  tr td label {
    justify-content: center;
  }
  .feedback {
    background-color: ${theme("colors.grays.0")};
  }
`;
