import React from "react";
import { theme } from "styled-tools";
import { observer, inject } from "mobx-react";
import { ThemeProvider } from "styled-components";
import { createGlobalStyle } from "styled-components";

import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Logo from "../../uikit/logo/Logo";
import agmTheme from "../../uikit/themes/darkTheme";

import Review from "./Review";

const GlobalStyle = createGlobalStyle`  
    html, body{
      height: 100vh;      
      overflow:hidden;
    }
    #root{
      height: 100vh;      
      overflow: auto;
    }
    body{
      background: ${theme("colors.primarys.1")};
    }      
`;

//using dark theme here to have white TextFields

function ReviewContainer({ authStore, match }) {

  
  return (
    <ThemeProvider theme={agmTheme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <GlobalStyle />
        <Box width={1} maxWidth="724px" m={[6, "auto"]}>
          <Box my={[5, 8]} maxWidth={["260px", "300px"]} mx="auto">
            <Logo color="white" style={{ width: "100%" }} />
            <Text
              color="white"
              fontFamily="montserrat"
              fontSize={[4, 7]}
              ml="20%"
            >
              Mind Your Business
            </Text>
          </Box>
          <Review meetingId={match.params.meetingId} userId={match.params.userId} match={match} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default inject("authStore")(observer(ReviewContainer));
