import React from "react";
import Field from "../field/Field";
import { FieldProvider, FieldConsumer } from "../field/FieldContext";
import { splitBoxProps } from "../field/utils";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

const StyledInput = styled.input`
  border: none;
  box-sizing: border-box;
  background: ${p => (p.disabled ? theme("colors.grays.1") : p.bg ? theme(`colors.${p.bg}`) : "none")};
  color: ${p => (p.disabled ? theme("colors.grays.2") : p.color ? theme(`colors.${p.color}`) : theme("colors.text"))};
  display: flex;
  font-family: ${theme("fonts.montserrat")};
  font-size: ${theme("fontSizes.2")}px;
  letter-spacing: normal;
  line-height: 1;
  padding: 12px 10px 11px 10px;
  position: relative;
  width: ${ifProp("hasIcon", "calc(100% - 57px)", "100%")};
  -webkit-font-smoothing: antialiased;
  z-index: 3;
  &:focus {
    outline: none;
  }
`;

export function TextField(props, ref) {

  const [boxProps, otherProps] = splitBoxProps(props);
  const { helperText, label, error, icon, isValid, onBlur, onFocus, multiline = false, ...inputProps } = otherProps;

  return (
    <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
      <FieldConsumer>
        {({ fieldState, onBlur, onFocus }) => {
          return (
            <Field
              fieldState={fieldState}
              boxProps={boxProps}
              helperText={helperText}
              label={label}
              error={error}
              icon={icon}
            >
              <StyledInput
                as={multiline ? "textarea" : "input"}
                bg={boxProps.bg}
                color={boxProps.color}
                hasLabel={!!label}
                hasIcon={!!icon}
                onBlur={onBlur}
                onFocus={onFocus}
                type="text"
                {...inputProps}
                ref={ref}
              />
            </Field>
          );
        }}
      </FieldConsumer>
    </FieldProvider>
  );
}

export default React.forwardRef(TextField);
