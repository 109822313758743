import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { useForm } from "react-hook-form";
import { string, object } from "yup";

import Box from "../uikit/box/Box";
import Text from "../uikit/text/Text";
import Button from "../uikit/button/Button";
import TextField from "../uikit/form/textfield/TextField";

import { mapServerErrors } from "../../lib/utils";
import { setEmptyValuesToNull } from "../shared/form/utils";

const Schema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  email_address: string()
    .email()
    .required("Email is required"),
  phone: string(),
  company_name: string(),
  job_title: string()
});

function ProfileInformationForm(props) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema,
    defaultValues: props.user
  });

  const [serverErrors, setServerErrors] = useState(null);

  const onSubmit = data => {
    setServerErrors(null);
    props.onSubmit(setEmptyValuesToNull(data)).catch(error => {
      console.log("server errors", error);
      if (error.response.error_number) {
        setServerErrors(mapServerErrors(error.response.error_number));
      } else {
        setServerErrors("An error occurred");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box mb={5}>
        <Box my={3}>
          <TextField
            required
            label="First Name"
            name="first_name"
            ref={register}
            error={errors.first_name && errors.first_name.message}
          />
        </Box>
        <Box my={3}>
          <TextField
            required
            label="Last Name"
            name="last_name"
            ref={register}
            error={errors.last_name && errors.last_name.message}
          />
        </Box>
        <Box my={3}>
          <TextField
            required
            label="Email"
            name="email_address"
            ref={register}
            error={errors.email_address && errors.email_address.message}
          />
        </Box>
        <Box my={3}>
          <TextField label="Phone" name="phone" ref={register} error={errors.phone && errors.phone.message} />
        </Box>
        <Box my={3}>
          <TextField
            label="Job Title"
            name="job_title"
            ref={register}
            error={errors.job_title && errors.job_title.message}
          />
        </Box>
        <Box my={3}>
          <TextField
            label="Company Name"
            name="company_name"
            ref={register}
            error={errors.company_name && errors.company_name.message}
          />
        </Box>
      </Box>

      <Box>
        {serverErrors && (
          <Text textAlign="center" color="accents.0" mb={4}>
            {serverErrors}
          </Text>
        )}
        <Button type="submit" mr={4}>
          Save
        </Button>
        <Button variant="secondary" color="black" onClick={props.onClose}>
          Cancel
        </Button>
      </Box>
    </form>
  );
}

export default inject("authStore")(observer(ProfileInformationForm));
