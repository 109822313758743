import React from "react";
import { observer, inject } from "mobx-react";
import { Switch, Route } from "react-router-dom";
import ReviewContainer from "../meeting/review/ReviewContainer";
import NonAttendanceContainer from "../meeting/non-attendance/NonAttendanceContainer";
import Verify from "./auth/Verify";
import App from "../app/App";

function AppWrapper({ authStore, history }) {
  
  return (
    <Switch>
      <Route path="/review/:meetingId/:userId" component={ReviewContainer} />
      <Route path="/meeting/did-not-attend/:meetingId/:userId" component={NonAttendanceContainer} />
      <Route path="/verify/:code" component={Verify} />
      <Route path="/" component={App} />
    </Switch>
  )
}

export default inject("authStore")(observer(AppWrapper));
