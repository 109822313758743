import { observable, action, toJS, computed } from "mobx";
import {
  getProjectById,
  getProjectStatsById,
  create,
  update,
  archive,
  addUser,
  unarchive,
  deleteUser,
  getMembers,
  updateUserPermissions
} from "./ProjectService";
import MeetingStore from "../meeting/MeetingStore";

class ProjectStore {

  @observable project = null;
  @observable members = null;
  @observable meetingStore = null;
  @observable stats = null;

  constructor(projectId) {
    if (projectId) {
      this.initProject(projectId);
    }
  }

  @computed get admins() {
    if (!this.members) return null;
    return this.members.filter(user => user.is_admin === 1 || user.is_owner === 1);
  }

  @computed get users() {
    if (!this.members) return null;
    return this.members.filter(user => user.is_admin === 0 && user.is_owner === 0);
  }

  @action.bound
  initProject(projectId){
    return Promise.all(
      [
        this.fetchProject(projectId),
        this.getProjectStats(projectId),
        this.getProjectMembers(projectId)
      ])
      .then((res) => {
        this.meetingStore = new MeetingStore(projectId, this);
      });
  }

  @action.bound
  createProject(formData) {
    return create(formData).then(data => {
      this.project = data;
      return Promise.resolve(data);
    });
  }

  @action.bound
  fetchProject(projectId) {
    return getProjectById(projectId).then(data => {
      this.project = data;
      return Promise.resolve(data);
    });
  }

  @action.bound
  getProjectStats(projectId) {
    return getProjectStatsById(projectId).then(data => {
      this.stats = data;
      return Promise.resolve(data);
    });
  }

  @action.bound
  updateProject(formData) {
    return update(this.project.id, formData)
      .then(data => {
        this.project = data;
        return Promise.resolve(data);
      });
  }

  @action.bound
  archiveProject(projectId) {
    return archive(projectId).then(data => {
      this.project = data;
      return Promise.resolve(data);
    });
  }

  @action.bound
  unarchiveProject(id) {
    return unarchive(id).then(data => {
      return Promise.resolve(data);
    });
  }

  @action.bound
  getProjectMembers(projectId) {
    return getMembers(projectId).then(members => {
      this.members = members;
      return Promise.resolve(members);
    });
  }

  @action.bound
  addUserToProject(formData) {
    formData.contract_id = this.project.contract_id;
    return addUser(this.project.id, formData).then(data => {
      //refresh members list
      this.getProjectMembers(this.project.id);
      return Promise.resolve(data);
    });
  }

  @action.bound
  updateUserPermissions(memberId, is_owner, is_admin) {
    const formData = {
      user_id: memberId,
      is_owner: is_owner,
      is_admin: is_admin
    };
    return updateUserPermissions(this.project.id, formData).then(data => {
      this.getProjectMembers(this.project.id);
      return Promise.resolve(data);
    });
  }

  @action.bound
  deleteUserFromProject(projectId, memberId) {
    const formData = {
      user_id: memberId
    };
    return deleteUser(projectId, formData).then(data => {
      this.getProjectMembers(this.project.id);
      return Promise.resolve(data);
    });
  }
}

export default ProjectStore;
