const API_VERSION = '1.0';
let local = 'http://localhost:8080/';
let staging = 'https://staging.api.agencyminder.com/';
//local as default
let config = {
  API_VERSION,
  API_URL: `${local}${API_VERSION}`,
};

switch (process.env.BUILD_ENV) {
  case 'production':
    config.API_URL = `https://api.agencyminder.com/${API_VERSION}`;
    break;

  case 'staging':
    config.API_URL = `${staging}${API_VERSION}`;
    break;
}

export default config;
