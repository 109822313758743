import { APIFetch, toBlob } from "../../lib/HttpClient";

export function getAll() {
  return APIFetch(`/contract`).then(resp => {
    if (resp.data.contracts) {
      return Promise.resolve(resp.data.contracts);
    }
    return Promise.resolve(null);
  });
}

export function getContractById(contractId) {
  //for some reason the API for /contract/:id doesn't return joined projects
  //which makes it useless. the endpoing below returns what we want.
  return APIFetch(`/project/getProjects/${contractId}`).then(resp => {
    if (resp.data.contract) {
      return Promise.resolve(resp.data.contract);
    }
    return Promise.reject();
  }).catch(error => {
    return Promise.reject();
  });
}

export function create(formData) {
  return APIFetch(`/contract/create`, {
    method: "POST",
    body: toBlob(formData)
  }).then(resp => {
    if (resp.data.contract) {
      return Promise.resolve(resp.data.contract);
    }
    return Promise.resolve(null);
  });
}

export function update(id, formData) {
  return APIFetch(`/contract/${id}`, {
    method: "PUT",
    body: toBlob(formData)
  }).then(resp => {
    if (resp.data.contract) {
      return Promise.resolve(resp.data.contract);
    }
    return Promise.resolve(null);
  });
}

export function archive(id) {
  return APIFetch(`/contract/archive/${id}`).then(resp => {
    if (resp.data.contract) {
      return Promise.resolve(resp.data.contract);
    }
    return Promise.resolve(null);
  });
}

export function unarchive(id) {
  return APIFetch(`/contract/unarchive/${id}`).then(resp => {
    if (resp.data.contract) {
      return Promise.resolve(resp.data.contract);
    }
    return Promise.resolve(null);
  });
}

