import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import TitleBlock from "../../shared/TitleBlock";

const Member = ({ member }) => {
  return (
    <Box mx={3} my={4}>
      <Text fontFamily="montserrat" lineHeight={5}>
        {`${member.first_name} ${member.last_name}`}
        {member.job_title && ` - ${member.job_title}`}
      </Text>

      <Text fontFamily="montserrat" lineHeight={5}>
        {member.email_address}
      </Text>
    </Box>
  );
};

const MemberList = ({ title, members, max = 5, ...rest }) => {
  const [viewAll, setViewAll] = useState(false);
  const people = viewAll ? members : members.slice(0, max);

  return (
    <Box {...rest}>
      <TitleBlock>{title}</TitleBlock>
      {people.map((x, i) => (
        <Member member={x} key={i} />
      ))}
      {members.length > max && (
        <Text
          fontFamily="montserrat"
          fontSize={1}
          mx={3}
          el="a"
          href="#"
          color="accents.0"
          onClick={e => setViewAll(s => !s)}
        >
          {viewAll ? "- View Less" : "+ View More"}
        </Text>
      )}
    </Box>
  );
};

function ProjectUsers({ projectStore, ...rest }) {
  if (!projectStore.members) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="space-between" {...rest}>
      <MemberList title="Administrators" members={projectStore.admins} flex="0 0 48%" />
      <MemberList title="Users" members={projectStore.users} flex="0 0 48%" />
    </Box>
  );
}

export default inject("projectStore")(observer(ProjectUsers));
