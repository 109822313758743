import React from "react";
import { boxStyleProps } from "../box/Box";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import propTypes from "@styled-system/prop-types";
import { switchProp, theme } from "styled-tools";
import PropTypes from "prop-types";
import CleanElement from "../shared/CleanElement";

const ButtonPropTypes = {
  ...propTypes.border,
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.grid,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.space,
  ...propTypes.typography,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["normal", "large"])
};

const raiseHover = css`
  &:hover {
    box-shadow: ${themeGet("shadows.0")};
  }
  &:active {
    box-shadow: none;
    box-shadow: ${themeGet("shadows.0")} inset;
  }
`;

const disabled = css`
  &:disabled, [disabled] {
    color: ${themeGet("colors.grays.2")};;
    cursor: not-allowed;
  }
`;

const Button = styled(CleanElement(ButtonPropTypes))`
  border: none;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  text-align: center;
  line-height: 1;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  ${disabled};

  ${switchProp("size", {
    large: css`
      font-size: ${theme("fontSizes.4")}px;
      padding: 14px 16px 11px 16px;
    `,
    normal: css`
      font-size: ${theme("fontSizes.2")}px;
      padding: 10px 12px 8px 12px;
    `
  })};

  transition: box-shadow 200ms ease;
  ${boxStyleProps};
  font-family: ${themeGet("fonts.primary")};
  ${switchProp("variant", {
    primary: css`
      background-color: ${themeGet("colors.accents.0")};
      border-color: ${themeGet("colors.accents.0")};
      color: ${p => themeGet(`colors.${p.color}`)(p)};
      ${raiseHover};
    `,
    secondary: css`
      background-color: transparent;
      color: ${p => themeGet(`colors.${p.color}`)(p)};
      ${raiseHover};
    `,
    tertiary: css`
      background: transparent !important;
      background-color: transparent !important;
      border-color: transparent;
      color: ${p => (p.color ? themeGet(`colors.${p.color}`)(p) : themeGet("colors.black"))};
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      &:hover {
        text-decoration: underline;
      }
    `
  })};
`;

Button.defaultProps = {
  variant: "primary",
  color: "white",
  size: "normal",
  el: "button",
  type: "button"
};

Button.propTypes = ButtonPropTypes;

export default Button;
