import React from "react";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";

function Rating({ rating, width, height, ...rest }) {
  const defaultColor = "rgba(150,150,150,0.15)";

  return (
    <Box position="relative" width={width} height={height} {...rest}>
      <Text
        fontWeight="bold"
        fontFamily="montserrat"
        fontSize={3}
        color="white"
        style={{ position: "absolute", right: -10, top: 10 }}
      >
        { rating !== 0 ? rating : '' }
      </Text>
      <svg width={width} height={height} viewBox="0 0 45 46">
        <path
          fill={rating > 1 ? "#EC333E" : defaultColor}
          d="M41.434 25.728c-.598 5.045-3.16 9.504-6.903 12.582a.612.612 0 0 0-.144.786l1.024 1.731a.611.611 0 0 0 .902.172c4.577-3.656 7.692-9.055 8.339-15.17a.61.61 0 0 0-.617-.669l-2.002.021a.614.614 0 0 0-.6.547"
        />

        <path
          fill={rating > 2 ? "#F9A11B" : defaultColor}
          d="M22.546 42.685a19.15 19.15 0 0 1-6.947-1.216.61.61 0 0 0-.751.268l-.985 1.753a.608.608 0 0 0 .3.866 22.298 22.298 0 0 0 8.419 1.544 22.298 22.298 0 0 0 9.358-2.166.61.61 0 0 0 .256-.861l-1.018-1.718a.613.613 0 0 0-.788-.24 19.119 19.119 0 0 1-7.844 1.77"
        />

        <path
          fill={rating > 3 ? "#674E9F" : defaultColor}
          d="M11.276 39.184c-4.243-2.99-7.232-7.651-7.991-13.031a.611.611 0 0 0-.611-.532l-2.001.021a.61.61 0 0 0-.602.681C.9 32.794 4.49 38.401 9.61 41.934a.61.61 0 0 0 .874-.207l.979-1.741a.61.61 0 0 0-.187-.802"
        />

        <g transform="translate(0 4.901)">
          <path
            fill={rating > 4 ? "#8DCD64" : defaultColor}
            d="M3.221 16.36c.616-5.415 3.494-10.165 7.672-13.266a.61.61 0 0 0 .168-.804L10.043.567a.61.61 0 0 0-.88-.187C4.119 4.045.664 9.755.003 16.267a.61.61 0 0 0 .617.665l2.002-.022a.611.611 0 0 0 .599-.55"
          />
        </g>
        <g transform="translate(13 .901)">
          <path
            fill={rating > 4 ? "#F3EF5D" : defaultColor}
            d="M9.118 3.315a19.124 19.124 0 0 1 7.922 1.613c.29.127.629.02.784-.256l.979-1.741a.608.608 0 0 0-.273-.853A22.327 22.327 0 0 0 9.082.1 22.315 22.315 0 0 0 .657 1.848a.61.61 0 0 0-.281.873L1.4 4.45a.611.611 0 0 0 .758.25 19.124 19.124 0 0 1 6.96-1.385"
          />
        </g>
      </svg>
    </Box>
  );
}

Rating.defaultProps = {
  width: "45px",
  height: "46px"
};

export default Rating;
