import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { format, addYears } from "date-fns";

import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Button from "../../uikit/button/Button";
import TextField from "../../uikit/form/textfield/TextField";
import SelectField from "../../uikit/form/selectfield/SelectField";

import { validateBotPrefix } from "../ProjectService";
import { convertDates, setEmptyValuesToNull } from "../../shared/form/utils";

function ProjectForm({
  onSubmit,
  onCancel,
  onArchive,
  onUnarchive,
  contractList,
  defaultValues,
  contractName,
}) {
  const { register, handleSubmit, errors } = useForm();
  const [formErrors, setFormErrors] = useState({});
  const [isEdit] = useState(!!defaultValues);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const onFormSubmit = (data) => {
    onSubmit &&
      onSubmit(setEmptyValuesToNull(data)).catch((serverErrors) => {
        setFormErrors(serverErrors);
      });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <Box px={4}>
        <Box my={5}>
          <>
            {contractName ? (
              <SelectField
                label="Contract Name"
                name="contract_id"
                disabled={contractName && contractName.is_archived}
                ref={register({
                  required: "Contract is required",
                })}
                error={formErrors.contract_id && formErrors.contract_id.message}
              >
                <option key={contractName.id} value={contractName.id}>
                  {contractName.name}
                </option>
              </SelectField>
            ) : (
              <SelectField
                label="Contract Name"
                name="contract_id"
                disabled={defaultValues && defaultValues.is_archived}
                defaultValue={defaultValues && defaultValues.contract_id}
                ref={register({
                  required: "Contract is required",
                })}
                error={formErrors.contract_id && formErrors.contract_id.message}
              >
                <option key="_00"></option>
                {contractList.length > 0 &&
                  contractList.map((contract) => (
                    <option key={contract.id} value={contract.id}>
                      {contract.name}
                    </option>
                  ))}
              </SelectField>
            )}
          </>
        </Box>
        <Box my={5}>
          <TextField
            label="Project Name"
            name="name"
            disabled={defaultValues && defaultValues.is_archived}
            defaultValue={defaultValues && defaultValues.name}
            ref={register({
              required: "Name is required",
            })}
            error={formErrors.name && formErrors.name.message}
          />
        </Box>
        <Box my={5}>
          <TextField
            label="Bot Prefix"
            name="bot_prefix"
            defaultValue={defaultValues && defaultValues.bot_prefix}
            disabled={isEdit || (defaultValues && defaultValues.is_archived)}
            ref={register({
              required: "Prefix is required",
              validate: async (newValue) => {
                if (defaultValues && defaultValues.bot_prefix) {
                  const prevValue = defaultValues.bot_prefix;
                  if (prevValue !== newValue) {
                    const res = await validateBotPrefix(newValue);
                    if (res) {
                      return "Prefix is already taken";
                    }
                  }
                }
              },
            })}
            error={formErrors.bot_prefix && formErrors.bot_prefix.message}
          />
        </Box>
        <Box my={5}>
          <TextField
            label="Start Date"
            type="date"
            name="start_date"
            disabled={defaultValues && defaultValues.is_archived}
            defaultValue={
              defaultValues && defaultValues.start_date
                ? format(new Date(defaultValues.start_date), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM-dd")
            }
            ref={register({
              required: "Start date is required",
            })}
            error={formErrors.start_date && formErrors.start_date.message}
          />
        </Box>
        <Box my={5}>
          <TextField
            label="End Date"
            type="date"
            name="end_date"
            disabled={defaultValues && defaultValues.is_archived}
            defaultValue={
              defaultValues && defaultValues.end_date
                ? format(new Date(defaultValues.end_date), "yyyy-MM-dd")
                : format(addYears(new Date(), 1), "yyyy-MM-dd")
            }
            ref={register({
              required: "End date is required",
            })}
            error={formErrors.end_date && formErrors.end_date.message}
          />
        </Box>
        <Box my={5}>
          <TextField
            multiline={true}
            rows={4}
            label="Description"
            name="description"
            disabled={defaultValues && defaultValues.is_archived}
            defaultValue={defaultValues && defaultValues.description}
            ref={register}
          />
        </Box>
        {formErrors.message ||
          (formErrors.error_message && (
            <Box my={5}>
              <Text textAlign="center" color="accents.0" mb={4}>
                {formErrors.message || formErrors.error_message}
              </Text>
            </Box>
          ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        borderTop="1px solid"
        borderColor="grays.1"
        pt={5}
      >
        <Button variant="tertiary" color="black" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Box>
          {defaultValues &&
            defaultValues.id &&
            defaultValues.is_archived === 1 &&
            onUnarchive && (
              <Button
                variant="secondary"
                mr={3}
                color="black"
                onClick={() => onUnarchive(defaultValues.id)}
              >
                Unarchive
              </Button>
            )}

          {defaultValues &&
            defaultValues.id &&
            !defaultValues.is_archived &&
            onArchive && (
              <Button
                variant="secondary"
                mr={3}
                color="black"
                onClick={() => onArchive(defaultValues.id)}
              >
                Archive
              </Button>
            )}
          <Button
            type="submit"
            disabled={defaultValues && defaultValues.is_archived}
          >
            Save
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default ProjectForm;
