import React from "react";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Icon from "../../uikit/icon/Icon";
import { FiAlertCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";

export default ({ title, value, isPercentage= false, to, ...rest }) => {

  const linkProps = to
    ? {
        to,
        el: NavLink
      }
    : {};

  let borderPercentage = "none";
  if (isPercentage) {
    if (value < 50) {
      borderPercentage = "2px solid #ed343e";
    } else if (value >= 50 && value < 80) {
      borderPercentage = "2px solid #f9a11b";
    } else {
      borderPercentage = "2px solid #8dcd64";
    }

    value = `${value}%`
  }

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Box
        bg="primarys.0"
        borderRadius="50%"
        width="48px"
        height="48px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        border={borderPercentage}
        style={{ position: 'relative'}}
        mr={5}
      >
        {isPercentage && value < 50  && (
          <Icon icon={FiAlertCircle} color="ratings.0" bg="primarys.0" style={{ borderRadius: '50%', position: 'absolute', top: '-3px', right: '-3px' }} />
        )}
        <Text fontSize={2} color="white" fontFamily="montserrat" fontWeight="bold">
          {value}
        </Text>
      </Box>
      {title && (
        <Text color="white" fontSize={4} fontFamily="montserrat" {...linkProps}>
          {title}
        </Text>
      )}
    </Box>
  );
};
