import React from 'react';
import Text from '../../uikit/text/Text';
import { FiAlertCircle } from 'react-icons/fi';
import { FaRegCheckCircle, FaRegPlayCircle } from 'react-icons/fa';
import Icon from '../../uikit/icon/Icon';

function AverageLabel({ average = 0, ...rest }) {
  const styles = {
    transform: `rotateZ(150deg)`,
  };

  let averageIcon,
    _average = Number(average);
  if (_average < 50) {
    averageIcon = (
      <Icon icon={FiAlertCircle} color="ratings.0" ml="-2px" mr="5px" />
    );
  } else if (_average >= 50 && _average < 85) {
    averageIcon = (
      <Icon
        icon={FaRegPlayCircle}
        style={styles}
        color="ratings.1"
        ml="-2px"
        mr="5px"
      />
    );
  } else {
    averageIcon = (
      <Icon icon={FaRegCheckCircle} color="ratings.3" ml="-2px" mr="5px" />
    );
  }

  return (
    <Text fontSize={1}>
      {averageIcon}
      {_average}% Completed
    </Text>
  );
}

export default AverageLabel;
