import React from "react";
import Field from "../field/Field";
import { FieldProvider, FieldConsumer } from "../field/FieldContext";
import { splitBoxProps } from "../field/utils";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StyledContainer = styled.div`
  .react-datepicker-popper {
    z-index: 10;
  }
  input {
    box-sizing: border-box;
    position: relative;
    color: ${theme("colors.text")};
    font-family: ${theme("fonts.montserrat")};
    font-size: ${theme("fontSizes.2")}px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: normal;
    border: none;
    background: none;
    line-height: 1;
    padding: 12px 10px 11px 10px;
    width: ${ifProp("hasIcon", "calc(100% - 57px)", "100%")};
    z-index: 3;
    &:focus {
      outline: none;
    }
  }
`;

export function DateTime(props, ref) {
  const [boxProps, otherProps] = splitBoxProps(props);
  const { helperText, label, error, isValid, onBlur, onFocus, value, onChange, ...inputProps } = otherProps;

  return (
    <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
      <FieldConsumer>
        {({ fieldState, onBlur, onFocus }) => {
          return (
            <Field fieldState={fieldState} boxProps={boxProps} helperText={helperText} label={label} error={error}>
              <StyledContainer>
                <DatePicker
                  ref={ref}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  {...inputProps}
                  selected={new Date(value) || new Date()}
                  onChange={onChange}
                  showTimeSelect
                  timeFormat="h:mm aa"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMM d, yyyy h:mm aa"
                  minDate={new Date()}
                />
              </StyledContainer>
            </Field>
          );
        }}
      </FieldConsumer>
    </FieldProvider>
  );
}

export default React.forwardRef(DateTime);
