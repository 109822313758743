import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { format } from "date-fns";
import { FiChevronLeft } from "react-icons/fi";

import Box from "../../uikit/box/Box";
import Icon from "../../uikit/icon/Icon";
import Text from "../../uikit/text/Text";
import { Table } from "../../uikit/table";
import Button from "../../uikit/button/Button";

import { AsideHeading } from "../../shared/Aside";
import MainLayout from "../../shared/layout/MainLayout";

function ArchivedProjectsPage({ contractStore, projectStore, history }) {

  const [currentContract, setCurrentContract] = useState(contractStore.currentContract);
  const projects = contractStore.currentContract && contractStore.currentContract.projects || [];

  const onUnarchive = (id) => {
    projectStore.unarchiveProject(id).then((project) => {
      contractStore.fetchContract(project.contract_id, true);
    }).then(() => {
      history.push(`/contract/${currentContract.id}`);
    });
  }

  return (
    <>
      <MainLayout
        aside={
          <Box display="flex" height="100%" flexDirection="column">
            <AsideHeading
              title={currentContract.name}
              mt={5}
            />
          </Box>
        }
        main={
          <>
            <Box pt={5} pl={5} pr={5}>
              <Link to={`/contract/${currentContract.id}`} style={{ display: "flex", alignItems: "center" }}>
                <Icon icon={FiChevronLeft} style={{ transform: "translateY(-1px)" }} />
                <Text>Back to contract</Text>
              </Link>
            </Box>
            <Box p={5}>
              <Text fontSize={4} fontWeight="bold" mb={3}>Archived Projects</Text>
              {
                !projects.filter(p => p.is_archived).length ? <Text fontSize={1} mt={1}>No archived projects</Text> :
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          <Text fontFamily="montserrat">Name</Text>
                        </th>
                        <th>
                          <Text fontFamily="montserrat">Start Date</Text>
                        </th>
                        <th>
                          <Text fontFamily="montserrat">End Date</Text>
                        </th>
                        <th>
                          <Text fontFamily="montserrat">Options</Text>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      projects.filter(p => p.is_archived).map(project => (
                        <tr key={project.id}>
                          <td>
                            <Text el={NavLink} to={`/project/${project.id}`} fontSize={1} fontWeight="bold">{project.name}</Text>
                          </td>
                          <td className="date">
                            <Text fontSize={1}>
                              {format(new Date(project.start_date), "MM.dd.yy")}
                            </Text>
                          </td>
                          <td className="date">
                            <Text fontSize={1}>
                              {format(new Date(project.end_date), "MM.dd.yy")}
                            </Text>
                          </td>
                          <td>
                            <Text fontSize={1}>
                              <Button
                                variant="secondary"
                                mr={3}
                                color="black"
                                onClick={() => onUnarchive(project.id)}
                              >
                                Unarchive
                              </Button>
                            </Text>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
              }
            </Box>
          </>
        }
      />
    </>
  );
}

export default inject("contractStore", "projectStore")(observer(ArchivedProjectsPage));
