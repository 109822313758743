import React from "react";
import { observer, inject } from "mobx-react";
import Auth from "./auth/Auth";
import AppWrapper from "./AppWrapper";

function AppContainer({ authStore }) {
  return <>{authStore.isLoggedIn ? <AppWrapper /> : <Auth />}</>;
}

export default inject("authStore")(observer(AppContainer));
