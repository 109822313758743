import React from "react";
import { PropTypes } from "prop-types";
import styled, { css } from "styled-components";
import propTypes from "@styled-system/prop-types";
import { border, color, flexbox, layout, position, space, typography, textStyle } from "styled-system";
import { prop, ifProp } from "styled-tools";
import CleanElement from "../shared/CleanElement";

const BasePropTypes = {
  ...propTypes.border,
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.space,
  ...propTypes.typography,
  ...propTypes.textStyle,
  textTransform: PropTypes.string,
  textDecoration: PropTypes.string
};

const Base = styled(CleanElement(BasePropTypes)).attrs(p => ({
  textDecoration: p.href && !p.textDecoration ? "underline" : p.textDecoration
}))`
    /* normalize browser styles */
    margin: 0;
    padding: 0;
    ${border};    
    ${color};
    ${flexbox};           
    ${layout};
    ${position};
    ${space};
    ${typography};    
    font-style: ${prop("fontStyle", "initial")};
    text-decoration: ${prop("textDecoration", "initial")};
    text-transform: ${prop("textTransform", "none")};
    -webkit-font-smoothing: ${p => (p.fontWeight === "light" ? "subpixel-antialiased" : "antialiased")};}
    ${ifProp(
      "onClick",
      css`
        text-decoration: underline;
        cursor: pointer;
      `
    )};
    &[href]:hover, &[onClick]:hover{
      text-decoration: underline;
      cursor: pointer;
    }  
    a {
        ${color};              
    }
    ${textStyle}
`;

Base.propTypes = BasePropTypes;
Base.defaultProps = {
  el: "span"
};

export function Text(props) {
  return <Base {...props} />;
}

Text.defaultProps = {
  display: "block",
  fontSize: 3,
  fontWeight: "regular",
  fontFamily: "primary",
  color: "primarys.0",
  lineHeight: 3
};

Text.displayName = "Text";

export default Text;

export function H1(props) {
  return <Text el="h1" fontSize={8} fontWeight="bold" mb={3} {...props} />;
}

export function H2(props) {
  return <Text el="h2" fontSize={6} fontWeight="bold" mb={3} {...props} />;
}

export function H3(props) {
  return <Text el="h3" fontSize={4} fontWeight="bold" mb={3} {...props} />;
}

export function H4(props) {
  return <Text el="h3" fontSize={4} fontWeight="bold" mb={3} {...props} />;
}
