import React from "react";
import styled from "styled-components";
import { theme, prop } from "styled-tools";

const Dot = styled.div`
  width: ${prop("size", 14)}px;
  height: ${prop("size", 14)}px;
  border-radius: 50%;
  background-color: ${p => theme(`colors.ratings.${p.colorIndex}`, "black")(p)};
`;

function RatingDot({ rating, size, ...rest }) {
  let colorIndex = 0;
  for (var i = 0; i < 5; i++) {
    if (rating > i) {
      colorIndex = i;
      continue;
    } else {
      break;
    }
  }

  return <Dot colorIndex={colorIndex} size={size} />;
}

export default RatingDot;
