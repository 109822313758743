import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import Box from "../../uikit/box/Box";
import Button from "../../uikit/button/Button";
import Text from "../../uikit/text/Text";
import TextField from "../../uikit/form/textfield/TextField";
import { useForm } from "react-hook-form";
import { string, object, ref } from "yup";
import { mapServerErrors } from "../../../lib/utils";

const Schema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  email_address: string()
    .email()
    .required("Email is required"),
  phone: string(),
  job_title: string(),
  company_name: string().required(),
  password: string().required("Password is required"),
  password_confirmation: string().oneOf([ref("password"), null], "Passwords must match")
});

function RegisterForm(props) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema
  });

  const [serverErrors, setServerErrors] = useState(null);

  const { email, submit } = props;

  useEffect(() => {
    submit &&
    handleSubmit(onSubmit);
  })

  const onSubmit = data => {
    setServerErrors(null);
    props.authStore.register(data).catch(error => {
      console.log("server errors", error);
      if (error.response && error.response.error_number) {
        setServerErrors(mapServerErrors(error.response.error_number));
      } else {
        setServerErrors("An error occurred");
      }
    });
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box mb={5}>
        <Box my={5} bg="#ffffff">
          <TextField
            required
            placeholder="First Name"
            name="first_name"
            ref={register}
            error={errors.first_name && errors.first_name.message}
            color="#111111"
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
        <Box my={5} bg="#ffffff">
          <TextField
            required
            placeholder="Last Name"
            name="last_name"
            ref={register}
            error={errors.last_name && errors.last_name.message}
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
        <Box my={5} bg="#ffffff">
          <TextField
            required
            placeholder="Email"
            name="email_address"
            ref={register}
            error={errors.email_address && errors.email_address.message}
            value={email}
            color="#111111"
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
        <Box my={5} bg="#ffffff">
          <TextField 
            placeholder="Phone" 
            name="phone" 
            ref={register} 
            error={errors.phone && errors.phone.message}
            color="#111111"
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
        <Box my={5} bg="#ffffff">
          <TextField
            required
            placeholder="Job Title"
            name="job_title"
            ref={register}
            color="#111111"
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
        <Box my={5} bg="#ffffff">
          <TextField
            required
            placeholder="Company Name"
            name="company_name"
            ref={register}
            error={errors.company_name && errors.company_name.message}
            color="#111111"
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
        <Box my={5} bg="#ffffff">
          <TextField
            required
            type="password"
            placeholder="Password"
            name="password"
            ref={register}
            error={errors.password && errors.password.message}
            color="#111111"
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
        <Box my={5} bg="#ffffff">
          <TextField
            required
            type="password"
            placeholder="Confirm Password"
            name="password_confirmation"
            ref={register}
            error={errors.password_confirmation && errors.password_confirmation.message}
            color="#111111"
            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5'}}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        {serverErrors && (
          <Text textAlign="center" color="accents.0" mb={4}>
            {serverErrors}
          </Text>
        )}
        <Button variant="secondary" color="white" type="submit">
          Register
        </Button>
      </Box>
    </form>
        <Button variant="secondary" color="white" onClick={handleSubmit(onSubmit)}>
          Register
        </Button>
        </>
  );
}

export default inject("authStore")(observer(RegisterForm));
