import React from "react";
import styled from "styled-components";
import Box from "../../box/Box";
import Text from "../../text/Text";

export { default as FieldBorder } from "./FieldBorder";

const themeFieldColor = p => {
  return p.currentstate && p.theme ? p.theme.component.formfield[p.currentstate].helperTextColor : "grays.1";
};

const StyledText = styled(Text)`
  color: ${themeFieldColor};
  user-select: none;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export function HelperText({ fieldState, children, ...rest }) {
  return (
    <Box {...rest}>
      <StyledText fontSize={1} currentstate={fieldState.currentState}>
        {children}
      </StyledText>
    </Box>
  );
}

HelperText.defaultProps = {
  pl: 3,
  pt: 1
};
