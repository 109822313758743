export function createTheme() {
  const breakpoints = ["640px", "960px", "1040px", "1280px", "1440px", "1600px"];

  const mediaQueries = {
    xs: `(min-width: ${breakpoints[0]})`,
    sm: `(min-width: ${breakpoints[1]})`,
    md: `(min-width: ${breakpoints[2]})`,
    lg: `(min-width: ${breakpoints[3]})`,
    xl: `(min-width: ${breakpoints[4]})`
  };

  const space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768];

  const fonts = {
    filson: "'FilsonSoft', sans-serif",
    montserrat: "'Montserrat', sans-serif"
  };

  fonts.primary = fonts.filson;
  fonts.secondary = fonts.montserrat;

  const fontSizes = [11, 12, 13, 14, 15, 16, 17, 18, 20, 24, 28, 32, 36, 42, 48, 54, 60, 68, 76, 84, 92];

  const fontWeights = {
    regular: 400,
    bold: 700
  };

  const lineHeights = [0.875, 0.925, 1, 1.125, 1.2, 1.35, 1.5];

  const letterSpacings = ["-0.001em", "-0.01em", "-0.03em", "0.25em", "0.5em"];

  const colors = {
    black: "#161617",
    white: "#ffffff",
    grays: ["#f4f5f7", "#cfcfcf", "#6b6b6b"],
    primarys: ["#2e1e52", "#1a0f33"],
    secondarys: ["#574b72", "#674e9f"],
    accents: ["#ed343e", "#f3ef5d", "#8dcd64", "#f9a11b"],
    ratings: ["#ed343e", "#f9a11b", "#674e9f", "#8dcd64", "#f3ef5d"]
  };

  colors.text = colors.primarys[0];

  // reactIconBase name is specific to react-icons lib.  don't change.
  const reactIconBase = {
    color: colors.grays[2],
    size: 18
  };

  const shadows = [
    "0 2px 4px 0 hsla(0, 0%, 0%, .25)",
    "-24px 25px 19px -22px rgba(0, 0, 0, 0.21)",
    "0 2px 4px 0 rgba(0, 0, 0, 0.5)"
  ];

  const textStyles = {
    label: {
      color: "white",
      fontSize: "9px",
      my: 2,
      color: "white",
      fontFamily: "montserrat",
      textTransform: "uppercase",
      fontWeight: "bold"
    }
  };

  const component = {
    button: {
      bg: colors.black,
      color: colors.white
    },
    label: {
      color: colors.secondarys[0]
    },
    formfield: {
      initial: {
        borderColor: colors.secondarys[0],
        helperTextColor: colors.secondarys[0]
      },
      active: {
        borderColor: colors.black,
        helperTextColor: colors.grays[2]
      },
      error: {
        borderColor: colors.accents[0],
        helperTextColor: colors.accents[0]
      },
      valid: {
        borderColor: colors.earthGreen,
        helperTextColor: colors.earthGreen
      },
      disabled: {
        borderColor: colors.grays[2],
        helperTextColor: colors.grays[2]
      },
      readOnly: {
        borderColor: colors.grays[2],
        helperTextColor: colors.grays[2]
      }
    },
    toggle: {
      initial: {
        borderColor: colors.grays[1]
      },
      active: {
        borderColor: colors.primarys[0]
      }
    }
  };

  return {
    breakpoints,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    space,
    reactIconBase,
    mediaQueries,
    shadows,
    textStyles,
    component
  };
}
