import React from "react";
import Terms from "./Terms";
import SubscriberAgreement from "./SubscriberAgreement";

function PrivacyPolice() {
  return (
    <>
      <Terms />
      <SubscriberAgreement />
    </>
  );
}

export default PrivacyPolice;
