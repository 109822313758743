import React from "react";
import Text from "../../uikit/text/Text";
import Box from "../../uikit/box/Box";
import RatingDot from "./RatingDot";

export default ({ rating, showRatingText = true, label, ...rest }) => (
  <Box display="flex" alignItems="center" {...rest}>
    <RatingDot rating={rating} />
    {showRatingText && (
      <Text ml={2} fontSize={1}>
        {rating}
      </Text>
    )}
    {label && (
      <Text ml={2} fontSize={1}>
        {label}
      </Text>
    )}
  </Box>
);
