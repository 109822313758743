import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { observer, inject } from 'mobx-react';
import { format } from 'date-fns';

import Box from '../../uikit/box/Box';
import Text from '../../uikit/text/Text';
import { SlimTable } from '../../uikit/table';
import Button from '../../uikit/button/Button';
import CheckBox from '../../uikit/form/toggle/CheckBox';
import TextField from '../../uikit/form/textfield/TextField';

import { getMeetingById, submitReview } from '../MeetingService';

function ReviewForm({ meetingId, userId, authStore }) {
  const [attendees, setAttendees] = useState([]);
  const [meeting, setMeeting] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formMessage, setFormMessage] = useState();
  const { register, handleSubmit, errors, watch, getValues } = useForm();

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  useEffect(() => {
    getMeetingById(meetingId)
      .then((res) => {
        setMeeting({
          name: res.name,
          date: format(new Date(res.start_date), 'iiii MMMM d. yyyy'),
          startTime: format(new Date(res.start_date), 'hh:mma'),
          endTime: format(new Date(res.end_date), 'hh:mma'),
        });

        const attendees = res.attendees
          .filter((currentValue, currentIndex, array) => {
            console.log('currentValue', currentValue);
            if (authStore.user) {
              return authStore.user.id !== currentValue.id;
            }
            return true;
          })
          .map((attendee) => {
            return {
              id: attendee.id,
              name: `${attendee.email_address}`,
              charge: attendee.job_title ? attendee.job_title : '',
              ratings: {
                rating0: false,
                rating1: false,
                rating2: false,
                rating3: false,
                rating4: false,
                rating5: false,
              },
              showFeedback: false,
            };
          });

        setAttendees(attendees);
      })
      .catch((err) => {
        setFormMessage(err.message);
      });
  }, [meetingId]);

  const onSubmit = (data) => {
    setFormErrors(null);
    setFormMessage(null);
    let attendeeId = [];

    delete data.showFeedback;
    for (let key of Object.keys(data)) {
      for (const prop in data[key]) {
        let object;
        if (key === 'feedback') {
          object = { feedback: data.feedback[prop] };
        }
        if (key === 'rating') {
          object = { rating: data.rating[prop] };
        }
        const a = attendeeId[prop] || {};
        attendeeId[prop] = Object.assign(a, object);
      }
    }

    let user_id;
    if (authStore.user) {
      user_id = authStore.user.id;
    }
    if (!user_id && authStore.public_user) {
      user_id = authStore.public_user.id;
    }
    console.log('authStore.public_user', authStore);
    const arrayToSend = [];
    for (let item in attendeeId) {
      arrayToSend.push({
        reviewed_by: user_id || '',
        reviewed_for: item || '',
        rating: +attendeeId[item].rating || '',
        feedback: attendeeId[item].feedback || '',
      });
    }

    submitReview(meetingId, arrayToSend)
      .then((res) => {
        if (res) {
          setFormMessage('Your response has been sent.');
        }
      })
      .catch((error) => {
        setFormErrors({
          message: 'We could not save your review. Please try again.',
        });
      });
  };

  const handleOptionChange = (changeEvent, column, i) => {
    const newArray = [...attendees];
    for (const prop in newArray[i].ratings) {
      newArray[i].ratings[prop] = false;
    }
    newArray[i].ratings[column] = changeEvent.target.checked;
    setAttendees(newArray);
  };

  const handleFeedbackChange = (i) => {
    const newArray = [...attendees];
    newArray[i].showFeedback = !newArray[i].showFeedback;
    setAttendees(newArray);
  };

  return (
    <>
      {formMessage ? (
        <Text
          py={5}
          color="white"
          fontSize={6}
          textAlign="center"
          fontWeight="bold"
        >
          {formMessage}
        </Text>
      ) : (
        <Box bg="white" p={5}>
          <Box bg="grays.0" py={5} mb={4}>
            <Text
              fontFamily="montserrat"
              textAlign="center"
              mb={2}
              color="primarys.0"
            >
              MEETING DETAILS
            </Text>
            <Text
              fontFamily="montserrat"
              textAlign="center"
              mb={3}
              color="primarys.1"
              fontSize={6}
              fontWeight="bold"
            >
              {meeting.name}
            </Text>
            <Text fontFamily="montserrat" textAlign="center" mb={1}>
              {meeting.date}
            </Text>
            <Text fontFamily="montserrat" textAlign="center">
              {meeting.startTime} - {meeting.endTime}
            </Text>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Text
              fontFamily="montserrat"
              mb={2}
              color="primarys.1"
              fontSize={2}
              fontWeight="bold"
            >
              Overall experience:
            </Text>
            <SlimTable>
              <thead>
                <tr>
                  <th>
                    <Text fontFamily="montserrat">Attendees</Text>
                  </th>
                  <th>
                    <Text fontFamily="montserrat" textAlign="center">
                      Superior
                    </Text>
                  </th>
                  <th>
                    <Text fontFamily="montserrat" textAlign="center">
                      Excellent
                    </Text>
                  </th>
                  <th>
                    <Text fontFamily="montserrat" textAlign="center">
                      Good
                    </Text>
                  </th>
                  <th>
                    <Text fontFamily="montserrat" textAlign="center">
                      Fair *
                    </Text>
                  </th>
                  <th>
                    <Text fontFamily="montserrat" textAlign="center">
                      Poor *
                    </Text>
                  </th>
                  <th className="feedback">
                    <Text fontFamily="montserrat" textAlign="center">
                      Feedback *
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendees.map((attendee, i) => {
                  const rating = `rating[${attendee.id}]`;
                  const feedback = `feedback[${attendee.id}]`;
                  const fieldRating = attendee.ratings;
                  return (
                    <Fragment key={rating}>
                      <tr>
                        <td>
                          <Text
                            mb={1}
                            fontSize={5}
                            fontWeight="bold"
                            color="primarys.1"
                          >
                            {attendee.name}
                          </Text>
                          <Text>{attendee.charge}</Text>
                        </td>
                        <td>
                          <CheckBox
                            labelPosition="none"
                            type="radio"
                            color="ratings.4"
                            name={rating}
                            value="5"
                            ref={register}
                            checked={fieldRating.rating0}
                            onChange={(e) =>
                              handleOptionChange(e, 'rating0', i)
                            }
                          />
                        </td>
                        <td>
                          <CheckBox
                            labelPosition="none"
                            type="radio"
                            color="ratings.3"
                            name={rating}
                            value="4"
                            ref={register}
                            checked={fieldRating.rating1}
                            onChange={(e) =>
                              handleOptionChange(e, 'rating1', i)
                            }
                          />
                        </td>
                        <td>
                          <CheckBox
                            labelPosition="none"
                            type="radio"
                            color="primarys.0"
                            name={rating}
                            value="3"
                            ref={register}
                            checked={fieldRating.rating2}
                            onChange={(e) =>
                              handleOptionChange(e, 'rating2', i)
                            }
                          />
                        </td>
                        <td>
                          <CheckBox
                            labelPosition="none"
                            type="radio"
                            color="ratings.1"
                            name={rating}
                            value="2"
                            ref={register}
                            checked={fieldRating.rating3}
                            onChange={(e) =>
                              handleOptionChange(e, 'rating3', i)
                            }
                          />
                        </td>
                        <td>
                          <CheckBox
                            labelPosition="none"
                            type="radio"
                            color="ratings.0"
                            name={rating}
                            value="1"
                            ref={register}
                            checked={fieldRating.rating4}
                            onChange={(e) =>
                              handleOptionChange(e, 'rating4', i)
                            }
                          />
                        </td>
                        <td className="feedback">
                          <CheckBox
                            labelPosition="none"
                            type="checkbox"
                            isFeedback={true}
                            color="primarys.0"
                            name="showFeedback"
                            ref={register}
                            checked={attendee.showFeedback}
                            onChange={() => handleFeedbackChange(i)}
                          />
                        </td>
                      </tr>
                      {attendee.showFeedback && (
                        <tr className="feedback-row">
                          <td colSpan={7} className="feedback">
                            <Box mb={5}>
                              <Text
                                fontFamily="montserrat"
                                mb={2}
                                color="primarys.1"
                                fontSize={1}
                                fontWeight="bold"
                              >
                                Feedback
                              </Text>
                              <TextField
                                bg="white"
                                color="secondarys.0"
                                multiline={true}
                                rows={4}
                                name={feedback}
                                ref={register}
                              />
                            </Box>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </SlimTable>
            {formErrors.message ? (
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Text textAlign="center" color="accents.0">
                  {formErrors.message}
                </Text>
              </Box>
            ) : formErrors.error_message ? (
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Text textAlign="center" color="accents.0">
                  {formErrors.error_message}
                </Text>
              </Box>
            ) : (
              ''
            )}
            <Box
              mt={5}
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
            >
              <Button px={8} py={3} type="submit">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
}

export default inject('authStore')(observer(ReviewForm));
