import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import { compose, border, boxShadow, color, flexbox, grid, layout, position, space, typography } from "styled-system";
import CleanElement from "../shared/CleanElement";

const BoxPropTypes = {
  ...propTypes.border,
  ...propTypes.shadow,
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.grid,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.space,
  ...propTypes.typography
};

export const boxStyleProps = compose(
  border,
  boxShadow,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography
);

const Box = styled(CleanElement(BoxPropTypes))(
  {
    boxSizing: "border-box"
  },
  boxStyleProps
);

Box.propTypes = BoxPropTypes;
Box.defaultProps = {
  el: "div"
};

export default Box;
