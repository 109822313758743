import React from "react";
import Box from "../uikit/box/Box";
import Text from "../uikit/text/Text";

export default ({ title, children, alignChildren, ...rest }) => (
  <Box display="flex" bg="grays.0" p={4} pb={3} {...rest}>
    <Text fontWeight="bold" fontSize={5} mr={5}>
      {title}
    </Text>
    {children}
  </Box>
);
