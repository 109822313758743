import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import { useForm } from "react-hook-form";
import TextField from "../../uikit/form/textfield/TextField";
import { observer, inject } from "mobx-react";

import { string, object, ref } from "yup";
import { mapServerErrors } from "../../../lib/utils";

import './Auth.css';
import { observable } from "mobx";
import Modal from "../../uikit/modal/Modal";
import PrivacyPolice from "./privacy/PrivacyPolice";

const Schema = object().shape({
    email_address: string()
        .email()
        .required("Email is required"),
});


function TryForFree(props) {
    const { register, errors } = useForm({
        validationSchema: Schema
    });


    const [serverErrors, setServerErrors] = useState(null);
    const [email, setEmail] = useState(null);
    const [modalOpenState, setModalOpenState] = useState(false);

    const handleEmailChange = value => {
        props.authStore.setEmail(value);
    }

    return (
        <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="75%" margin="auto" marginTop="3vh">
            <Text color="white" fontSize={12} textAlign="center" mb={4}>
                Try Agency Minder for free
            </Text>
            <Text color="white" fontSize={8} textAlign="center" mb={4} style={{ lineHeight: '1.7' }}>
                Free 14 days, cancel anytime. Please sign up with your work email<br />
                address so you can connect with your work team.
            </Text>

            <Box my={5} maxWidth="330px" margin='auto' bg="#ffffff" style={{ marginTop: '50px' }} >
                <TextField
                    required
                    name="email_address"
                    ref={register}
                    error={errors.email_address && errors.email_address.message}
                    placeholder="email"
                    color="#111111"
                    style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                    value={email}
                    onChange={event => handleEmailChange(event.target.value)}
                />
            </Box>
            <Link style={{ textDecoration: 'none' }} to={'/register'}>
                <Box my={5} p={2} maxWidth="220px" bg="#ff0000" margin='auto' style={{ marginTop: '3vh' }}>
                    <Text color="white" fontSize={8} textAlign="center" mb={4} lineHeight={1} style={{ marginTop: '12px' }}>
                        Try for Free
                    </Text>
                </Box>
            </Link>
            <Text color="white" fontSize={8} textAlign="center" mb={4} marginTop="10vh">
                By signing up, I agree to the Agency Minder <div onClick={() => setModalOpenState(true)} style={{ display: 'contents', cursor: 'pointer', textDecoration: 'underline' }}>
                    Privacy Policy
                </div>
            </Text>
            <Modal
                backgroundTitle={"#ffffff"}
                maxWidth={"80vw"}
                isOpen={modalOpenState}
                iconSize={40}
                onRequestClose={() => {
                    setModalOpenState(false)
            }}>
                <PrivacyPolice />
            </Modal>
        </Box>
    )
}

export default inject("authStore")(observer(TryForFree));