import config from "../config/config";

const reviver = (key, value) => {
  if (value === "undefined" || value === "" || value === "null") {
    return null;
  }
  return value;
};

/**
 * Add API Base URL to window.fetch
 */
export function APIFetch(url, options = {}) {
  if (url.charAt(0) == "/") {
    url = url.slice(1);
  }
  let endpoint = `${config.API_URL}/${url}`;

  return window.fetch(endpoint, options).then(handleResponse);
}

/**
 * Helper to convert objects to JSON to send in a fetch post request.
 * @param  {[type]} body [description]
 * @return {[type]}      [description]
 */
export function toBlob(body) {
  return new Blob([JSON.stringify(body)], { type: "application/json" });
}

/**
 * Generic respose handler.
 * @param  {Response} response object
 * @return {Promise}  resolved or rejected Promise with data or error Object.
 */
export function handleResponse(response) {
  const contentType = response.headers.get("content-type");
  // const parseMethod = contentType.includes("application/json") ? "json" : "text";

  return response
    .text()
    .then(text => JSON.parse(text, reviver))
    .then(function(data) {
      if (response.ok) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(data);
      }
    });
  //if you .catch() here it will swallow errors
  //not making them available to the caller of fetch()
}

/**
 * @param  {Response} resp
 * @return {Promise}
 */
export function status(resp) {
  return resp.ok ? Promise.resolve(resp) : Promise.reject(new Error(resp.statusText));
}

/**
 * @param  {Response} resp
 * @return {Promise}
 */
export function json(resp) {
  return resp.json();
}

/**
 * Parse error response to get form valiation errors
 * @param {*} response
 * @param {*} cb
 */
export const parseErrorResponse = (response, cb) => {
  if (!response) return;

  if (typeof response === "string" || typeof response.response === "string") {
    //Error was text so probably server error.
    console.error("There were errors on the server.  Check the response in the network tab.");
  } else if (response.hasOwnProperty("response")) {
    cb(response.response);
  } else {
    //Handle generic throw new Error() case.
    console.error(response);
  }
};
