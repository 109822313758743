import React, { useState } from "react";
import Media from "react-media";
import { NavLink } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

import Box from "../uikit/box/Box";
import Text from "../uikit/text/Text";
import Icon from "../uikit/icon/Icon";

import Rating from "../shared/rating/Rating";
import ActionMenu from "../shared/ActionMenu";
import { AsideHeading } from "../shared/Aside";
import MainLayout from "../shared/layout/MainLayout";
import MeetingList from "../meeting/list/MeetingList";

function ContractItem({ contract }) {
  let projects = [];

  if (contract.projects) {
    projects = contract.projects.filter((p) => !p.is_archived);
  }

  return (
    <Box p={5} display="flex">
      <Box mr={6}>
        <Rating rating={contract.average_rating} />
      </Box>
      <Box flex="1" py={2} overflow="hidden">
        <Text
          el={NavLink}
          to={`/contract/${contract.id}`}
          textDecoration="underline"
          fontWeight="bold"
          fontSize={5}
          color="white"
          display="block"
          mb={5}
        >
          {contract.name}
        </Text>
        <Text textStyle="label">Projects</Text>
        {projects.length > 0 ? (
          projects.map((p, i) => (
            <Text
              el={NavLink}
              to={`/project/${p.id}`}
              py={1}
              my={1}
              key={i}
              color="white"
              fontFamily="montserrat"
              fontSize={2}
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {p.name}
            </Text>
          ))
        ) : (
          <Text mt={3} color="grays.1">
            No projects
          </Text>
        )}
      </Box>
    </Box>
  );
}

function Dashboard({ contractStore, history }) {
  const [open, setOpen] = useState(true);
  const contracts = contractStore.unarchivedContracts;
  const recentMeetings = contractStore.meetingStore.recentMeetings;

  return (
    <MainLayout
      aside={
        <Box>
          <AsideHeading
            title="Dashboard"
            mt={5}
            icon={
              <Media
                query="(max-width: 959px)"
                render={() => (
                  <Icon
                    size={24}
                    icon={open ? FiChevronUp : FiChevronDown}
                    color="white"
                    onClick={(e) => {
                      setOpen((s) => !s);
                    }}
                  />
                )}
              />
            }
          />
          {open &&
            contracts.length > 0 &&
            contracts.map((c) => <ContractItem key={c.id} contract={c} />)}
        </Box>
      }
      main={
        <Box p={5}>
          <ActionMenu />
          <MeetingList
            overflow="auto"
            meetings={recentMeetings}
            onRowClick={(meeting) => {
              history.push(
                `project/${meeting.project_id}/meeting/${meeting.meeting_id}`
              );
            }}
            onPrevClick={() => {
              if (contractStore.meetingStore.offset > 0) {
                contractStore.meetingStore.offset--;
                contractStore.meetingStore.fetchRecentMeetings();
              }
            }}
            onNextClick={() => {
              if (
                contractStore.meetingStore.pages.length >
                contractStore.meetingStore.offset + 1
              ) {
                contractStore.meetingStore.offset++;
                contractStore.meetingStore.fetchRecentMeetings();
              }
            }}
            isDashboard="true"
            pages={contractStore.meetingStore.pages}
            currentPage={contractStore.meetingStore.offset}
          />
        </Box>
      }
    />
  );
}

export default inject("contractStore")(observer(Dashboard));
