import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import { useForm } from "react-hook-form";
import TextField from "../../uikit/form/textfield/TextField";
import { observer, inject } from "mobx-react";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { string, object, ref } from "yup";
import { toJS } from 'mobx';

import ProfileSubscriptionForm from './ProfileSubscriptionForm';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';


import './Auth.css';

const style = {
    base: {
        backgroundColor: '#ffffff',
        fontSize: '16px',
        lineHeight: '25px',
        color: '#2e1e52',
        '::placeholder': {
            color: '#2e1e52',
        },
    },
    invalid: {
        color: '#9e2146',
    },
};
const optionsCardNumber = {
    style: style,
    placeholder: 'Credit card number'
}

const optionsExpiration = {
    style,
    placeholder: 'Expiration (MM/YY)'
}

const optionsCVV = {
    style,
    placeholder: 'CVV (XXX)'
}


const Schema = object().shape({
    address_line_1: string().required(),
    address_line_2: string().required(),
    city: string().required(),
    state: string().required(),
    zip_code: string().required(),
    country: string().required()
});


function StartFreeTrial(props) {
    const user = "some";
    const stripe = useStripe();
    const elements = useElements();

    const [plans, setPlans] = useState([]);
    const [planSelected, setPlanSelected] = useState(null);
    const [customerId, setCustomerId] = useState(null);

    const [firtsName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [addressLine1, setAddressLine1] = useState(null);
    const [addressLine2, setAddressLine2] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [country, setCountry] = useState(null);

    useEffect(() => {
        if (props.authStore.subscriptionPlans !== null && props.authStore.subscriptionPlans.length > 0) {
            formatPlansArray(toJS(props.authStore.subscriptionPlans))
        }
    }, [props.authStore.subscriptionPlans])

    const formatPlansArray = plansData => {
        let plans = [];
        let plan;
        plansData.map(planData => {
            plan = {
                value: planData.plan_code,
                label: planData.name + ' - $' + planData.price,
                id: planData.id
            }
            plans.push(plan)
        })
        setPlans(plans)
    }

    const { billing, handleSubmit, errors } = useForm({
        validationSchema: Schema
    });

    const onSubmit = data => {
        props.authStore.setBillingAddress(data);
    }

    const handleStartFreeTrial = async () => {
        let registerData = toJS(props.authStore.registerData);
        const customerId = await getCustomerId();
        registerData.stripe_customer_id = customerId;
        registerData.is_account_owner = 1;
        registerData.billing_address_1 = addressLine1;
        registerData.billing_address_2 = addressLine2;
        registerData.billing_city = city;
        registerData.billing_state = state.value;
        registerData.billing_zip = zipCode;
        try {
            setCustomerId(customerId);
            const paymentMethod = await handleSubmitStripeElements();
            const resSubscription = await createSubscription(customerId, planSelected.value, paymentMethod.id);
            if (resSubscription) {
                props.authStore.register(registerData);
            }
        } catch (e) {
            console.log(e);
        }


    }

    const createSubscription = async (customerId, priceId, paymentMethodId) => {

        const subscriptionData = {
            customer_id: customerId,
            payment_method_id: paymentMethodId,
            price_id: priceId
        }

        const res = await props.authStore.createSubscription(subscriptionData);
        console.log(res)
        return res;
    }

    const getCustomerId = async () => {
        const customerData = makeCustomerData();
        const response = await props.authStore.fetchCustomerId(customerData);
        return response.customer.customer_id;

    }

    const makeCustomerData = () => {
        const customerData = {
            email_address: props.authStore.email,
            name: firtsName + ' ' + lastName,
            address_line_1: addressLine1,
            address_line2: addressLine2,
            city: city,
            state: state,
            zip_code: zipCode,
            country: country
        }
        return customerData;
    }

    const handleSubmitStripeElements = async () => {

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
      
          if (error) {
            console.log('[error]', error);
          } else {
            return paymentMethod;
          }
    }


    return (
        <>
            <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="75%" margin="auto" marginTop="3vh">
                <Text color="white" fontSize={12} textAlign="center" mb={4}>
                    Your trial plan
                </Text>
                {
                    !planSelected &&
                    <>
                        <Text color="white" fontSize={8} textAlign="center" mb={4} style={{ lineHeight: '1.7' }}>
                            Please select the plan you're interested in after the trial period.
                        </Text>
                        <Box p={[1, 1]} maxWidth="500px" margin="auto">
                        </Box>
                        <Box maxWidth="50%" margin="auto" marginTop="25px">
                            <Dropdown
                                className="dropdown-box"
                                options={plans}
                                onChange={data => setPlanSelected(data)}
                                placeholder="Select a Plan"
                            />
                        </Box>
                    </>
                }
                {
                    planSelected &&
                    <>
                        <Text color="white" fontSize={9} textAlign="center" mb={4}>
                            {planSelected.label}
                        </Text>
                        <Text color="white" fontSize={7} textAlign="center" mb={4} onClick={() => setPlanSelected(null)}>
                            change plan
                        </Text>
                    </>
                }
                <Box my={5} maxWidth="330px" margin='auto' bg="#ffffff" style={{ marginTop: '50px' }} >
                </Box>
            </Box>
            {
                planSelected &&
                <>
                    <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="75%" margin="auto" marginTop="3vh">
                        <Text color="white" fontSize={12} textAlign="center" mb={4}>
                            Payment details
                    </Text>
                        <Text color="white" fontSize={8} textAlign="center" mb={4} style={{ lineHeight: '1.7' }}>
                            Cancel anytime before your trial ends and you won't be charged.
                    </Text>

                        <Box maxWidth="50%" margin="auto" marginTop={"25px"}>
                            <Text color="white" fontSize={8} textAlign="left" mb={4} style={{ lineHeight: '1.7' }}>
                                Card Information
                        </Text>
                            <div className="card-data-line">
                                <CardNumberElement options={optionsCardNumber} placeholder="Credit card number" />
                            </div>
                            <div className="row">
                                <div className="card-data-line" style={{ width: '40%' }}>
                                    <CardExpiryElement options={optionsExpiration} />
                                </div>
                                <div className="card-data-line" style={{ width: '20%', marginLeft: '15px' }}>
                                    <CardCvcElement options={optionsCVV} />
                                </div>
                            </div>
                        </Box>

                        <Box maxWidth="50%" margin="auto" marginTop={"25px"}>
                            <Text color="white" fontSize={8} textAlign="left" mb={4} style={{ lineHeight: '1.7' }}>
                                Billing Address
                        </Text>

                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Box mb={5}>
                                    <Box my={5} bg="#ffffff">
                                        <TextField
                                            required
                                            placeholder="Address Line 1"
                                            name="address_line_1"
                                            ref={billing}
                                            error={errors.address_line_1 && errors.address_line_1.message}
                                            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                                            onChange={event => setAddressLine1(event.target.value)}
                                            color='#2e1e52'
                                        />
                                    </Box>
                                    <Box my={5} bg="#ffffff">
                                        <TextField
                                            required
                                            placeholder="Address Line 2"
                                            name="address_line_2"
                                            ref={billing}
                                            error={errors.address_line_2 && errors.address_line_2.message}
                                            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                                            onChange={event => setAddressLine2(event.target.value)}
                                            color='#2e1e52'
                                        />
                                    </Box>
                                    <Box my={5} bg="#ffffff">
                                        <TextField
                                            required
                                            placeholder="City"
                                            name="city"
                                            ref={billing}
                                            error={errors.city && errors.city.message}
                                            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                                            onChange={event => setCity(event.target.value)}
                                            color='#2e1e52'
                                        />
                                    </Box>
                                    <div className="row" style={{ justifyContent: 'space-between', marginTop: '0px', height: '47px'}}>
                                        <div style={{ width: '60%', height: '42px', background: '#ffffff' }}>
                                            <Box my={5} bg="#ffffff"  style={{ marginTop: '0px' }} >
                                                <Dropdown 
                                                    options={states}
                                                    placeholder="State"
                                                    controlClassName="state-dropdown"
                                                    placeholderClassName="state-placeholder"
                                                    onChange={value => setState(value)}
                                                    color='#2e1e52'
                                                />
                                            </Box>
                                        </div>
                                        <div style={{ width: '35%', marginTop: '-8px' }}>
                                            <Box my={5} bg="#ffffff" style={{ marginTop: '0px'}} >
                                                <TextField
                                                    required
                                                    placeholder="ZIP Code"
                                                    name="zip_code"
                                                    ref={billing}
                                                    error={errors.zip_code && errors.zip_code.message}
                                                    style={{ fontSize: "16px", paddingLeft: '20px' }}
                                                    onChange={event => setZipCode(event.target.value)}
                                                    color='#2e1e52'
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                    
                                    <Box my={5} bg="#ffffff">
                                        <TextField
                                            required
                                            placeholder="Country"
                                            name="country"
                                            ref={billing}
                                            error={errors.country && errors.city.contry}
                                            style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                                            onChange={value => setCountry(value)}
                                            color='#2e1e52'
                                        />
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                    <Box p={[6, 7]} mb={5}>
                        <Text color="white" fontSize={12} textAlign="center" mb={4}>
                            Due today: $0.00
                    </Text>
                        <Box my={5} p={2} maxWidth="220px" bg="#ff0000" margin='auto' style={{ marginTop: '3vh', cursor: 'pointer' }} onClick={handleStartFreeTrial}>
                            <Text color="white" fontSize={8} textAlign="center" mb={4} lineHeight={1} style={{ marginTop: '12px' }}>
                                Start Free Trial
                        </Text>
                        </Box>
                        <Text color="#aaaaaa" fontSize={5} textAlign="center" mb={4} style={{ lineHeight: '1.7' }} marginTop={"50px"}>
                            By clicking "Start  Free Trial", you agree to our terms. You will be automatically charged for your subscription<br />
                        at the end of the trial period, then your subscription will automatically renew until you cancel.
                    </Text>
                    </Box>
                </>
            }
        </>
    )
}

export default inject("authStore")(observer(StartFreeTrial));

const states = [
    "Alaska",
    "Alabama",
    "Arkansas",
    "American Samoa",
    "Arizona",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Iowa",
    "Idaho",
    "Illinois",
    "Indiana",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Massachusetts",
    "Maryland",
    "Maine",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Mississippi",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "Nevada",
    "New York",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Virginia",
    "Virgin Islands",
    "Vermont",
    "Washington",
    "Wisconsin",
    "West Virginia",
    "Wyoming"
]