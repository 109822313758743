import React from "react";
import styled from "styled-components";
import Box from "../../uikit/box/Box";
import { theme } from "styled-tools";

const Grid = styled.div`
  @media (min-width: ${theme("breakpoints.1")}) {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;

    aside,
    main {
      height: 100vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
`;

export default ({ aside, main }) => {
  return (
    <Grid>
      <Box el="aside">{aside}</Box>
      <Box el="main" bg="white">
        {main}
      </Box>
    </Grid>
  );
};
