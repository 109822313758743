import React from 'react';
import Text from '../../uikit/text/Text';

import './Auth.css';

function FAQ() {
  return (
    <div style={{ width: '70vw' }}>
      <div className="FAQ-title">
        <Text fontSize={11}>Frequently Asked Questions</Text>
      </div>
      {FAQArray.map((paragraph) => {
        return (
          <div className="FAQ-paragraph">
            <Text fontSize={7} style={{ fontWeight: 'bold', lineHeight: '2' }}>
              {paragraph.question}
            </Text>
            <Text
              fontSize={7}
              style={{ lineHeight: '2' }}
              dangerouslySetInnerHTML={{ __html: paragraph.answer }}
            >
              {/* {paragraph.answer} */}
            </Text>
          </div>
        );
      })}
    </div>
  );
}

export default FAQ;

const FAQArray = [
  {
    question: 'How long will the free trial last?',
    answer: 'You will have 14 days from the time you start your trial.',
  },
  {
    question: 'When will I be billed after my trial?',
    answer:
      'Once your 14-day trial ends, you account will be billed for the next month of your subscription if you selected a monthly plan. After your trial is completed, you will be billed for the plan that you selected when you signed up for your trial.',
  },
  {
    question: 'Can I cancel my trial?',
    answer:
      'You may cancel your trial at any time before the end of your trial period. If you cancel on or before your trial expiration date, your account will not be charged for the cost of the plan you selected when you signed up for your trial. You can cancel you plan directly on agencyminder.com or you can contact our support team for help at admin@agencyminder.com',
  },
  {
    question: 'How does pricing work?',
    answer:
      'We currently offer tiered plans based on the number of projects you want to track. We have three tiers: Project - 1 project; Departmental - 2 to 5 projects: Enterprise - 6 or more projects.',
  },
  {
    question: 'What is the difference between a contract and a project?',
    answer: `Most companies have a contract in place prior to beginning work, something like a Master Services Agreement (MSA) or perhaps simply a Non-Disclosure Agreement (NDA). We call that a contract.\n\nOnce that contract is in place you will typically agree on an amount of work, often with a Statement Of Work (SOW), for a specific quantity of work or a project. We call that a project.`,
  },
  {
    question: 'How should I set up my contracts and projects?',
    answer: `Let's say you have a contract with a digital agency, ABC123 Digital. Let’s further assume that you have three separate SOWs with ABC123 Digital, one for website redesign, one for SEO, and another for social media monitoring. To collect feedback on all those projects you would simply create the ABC123 Digital contract, and you need only to add those three projects under the same contract.\n\nAgency <var>Minder</var><sup>®</sup> will allow you to compare individual and total project performance within each contract. If you add additional contracts you will also be able to compare performance between contracts, and within project across contracts.`,
  },
  {
    question: 'What forms of payment do you accept?',
    answer:
      'You can purchase Agency <var>Minder</var><sup>®</sup> with any major credit card. Please contact us at admin@agencyminder.com to arrange an invoice purchase payable by bank transfer.',
  },
  {
    question: 'Will I be charged sales tax?',
    answer:
      'Yes. We will apply state sales tax based on the current sales tax rate in Texas where we are headquartered.',
  },
  {
    question: 'What is your cancellation policy?',
    answer:
      'If you cancel your plan before the next renewal cycle, you will retain access to paid features until the end of your subscription period. When your subscription expires, you will lose access to paid features and all data associated with those features.',
  },
];
