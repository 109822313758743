import { observable, runInAction, action } from "mobx";
import { getUserById, getSubscriptionPlans, getUserSubscriptionStatusById, update, resendVerificationEmail } from "./ProfileService";

class ProfileStore {

  userId = null;
  @observable user = null;
  @observable subscriptionPlan = null;
  @observable subscriptionPlans = [];
  @observable billingAddress = null;
  @observable paymentInformation = null;

  @observable isLoadingProfile = true;
  @observable isLoadingSubscription = true;
  @observable isLoadingBilling = true;
  @observable isLoadingPayment = true;

  @observable isEditingProfile = false;
  @observable isEditingSubscription = false;
  @observable isEditingBilling = false;
  @observable isEditingPayment = false;

  constructor(userId) {
    this.userId = userId;
    this.getUser(userId);
    this.fetchSubscriptionPlans();
  }

  getUser = userId => {
    getUserById(userId)
      .then(user => {
        runInAction(() => {
          this.user = user;
        });
        return getUserSubscriptionStatusById(userId);
      })
      .then((subscriptionStatus) => {
        this.user.subscriptionStatus = subscriptionStatus;
      })
      .catch(console.log)
      .finally(() => {
        this.isLoadingProfile = false;
        this.isLoadingSubscription = false;
        this.isLoadingBilling = false;
        this.isLoadingPayment = false;
      });
  };

  @action
  fetchSubscriptionPlans() {
    return getSubscriptionPlans().then(data => {
      this.setSubscriptionPlans(data.subscription_plans);
      // this.subscriptionPlans = data.subscription_plans;
    });
  }
  
  @action
  setSubscriptionPlans(subscriptionPlans) {
    const environment = process.env.BUILD_ENV;
    const enviromentCode = (environment === 'development' || environment === 'staging')? 0 : 1;
    this.subscriptionPlans = subscriptionPlans.filter(subscriptionPlan => 
        subscriptionPlan.is_live === enviromentCode
      );
  }

  @action
  updateUserInfo = formData => {
    this.isLoadingProfile = true;
    return update(this.userId, formData)
      .then(user => {
        this.user = user;
      })
      .finally(() => {
        this.isLoadingProfile = false;
        this.isEditingProfile = false;
      });
  };

  @action
  updateUserSubscription = formData => {
    console.log('formData: ', formData)
    this.isLoadingSubscription = true;
    return update(this.userId, formData)
      .then(data => {
        console.log('data: ', data)
        this.subscriptionPlan = data;
      })
      .finally(() => {
        this.isLoadingSubscription = false;
        this.isEditingSubscription = false;
      });
  };

  @action
  updateUserBilling = formData => {
    this.isLoadingBilling = true;
    return update(this.userId, formData)
      .then(data => {
        this.billingAddress = data;
      })
      .finally(() => {
        this.isLoadingBilling = false;
        this.isEditingBilling = false;
        this.getUser(this.userId);
      });
  };

  @action
  updateUserPayment = formData => {
    this.isLoadingPayment = true;
    return update(this.userId, formData)
      .then(data => {
        this.paymentInformation = data;
      })
      .finally(() => {
        this.isLoadingPayment = false;
        this.isEditingPayment = false;
      });
  };

  @action
  resendVerificationEmail = () => {
    return resendVerificationEmail(this.user.email_address);
  }
}

export default ProfileStore;
