import React from "react";
import styled, { keyframes } from "styled-components";
import Box from "../box/Box";
import { theme } from "styled-tools";

const opacityAnimation = keyframes`
    0% {
     opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Container = styled(Box)`
  circle {
    animation: ${opacityAnimation} 1s linear infinite;
    fill: ${p => theme(`colors.${p.color}`)(p)};
  }
  .c2 {
    animation-delay: 100ms;
  }
  .c3 {
    animation-delay: 200ms;
  }
`;

const DotsProgress = ({ size, ...props }) => {
  return (
    <Container {...props}>
      <svg preserveAspectRatio="xMidYMid meet" height="24" width={size} viewBox="0 0 80 20">
        <circle className="c1" cx="10" cy="10" r="10" />
        <circle className="c2" cx="40" cy="10" r="10" />
        <circle className="c3" cx="70" cy="10" r="10" />
      </svg>
    </Container>
  );
};

DotsProgress.defaultProps = {
  size: 36,
  color: "grays.1"
};

export default DotsProgress;
