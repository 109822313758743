import { APIFetch, toBlob } from "../../lib/HttpClient";

export function getSubscriptionPlans() {
  return APIFetch(`/subscription/get`).then(resp => {
    return Promise.resolve(resp.data);
  });
}

export function getUserSubscriptionStatusById(userId) {
  return APIFetch(`/subscription/get/status/${userId}`).then(resp => {
    return Promise.resolve(resp.data);
  });
}

export function getUserById(userId) {
  return APIFetch(`/user/${userId}`).then(resp => {
    return Promise.resolve(resp.data.user);
  });
}

export function update(userId, payload) {
  return APIFetch(`/user/${userId}`, { method: "PUT", body: toBlob(payload) }).then(resp => {
    return Promise.resolve(resp.data.user);
  });
}

export function updatePassword(userId, password) {
  return APIFetch(`/user/updatePassword/${userId}`, { method: "PUT", body: toBlob({ password }) });
}

export function uploadProfileImage(file) {
  let formData = new FormData();
  formData.append("image", file);

  return APIFetch(`/user/uploadProfileImage`, { method: "POST", body: formData }).then(resp => {
    return Promise.resolve(resp.data.user.profile_image);
  });
}

export function resendVerificationEmail(userId) {
  return APIFetch(`/user/resend/emailVerification/${userId}`).then(resp => {
    return Promise.resolve(resp.data);
  });
}