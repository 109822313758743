import React from "react";
import { FieldBorder, HelperText } from ".";
import FloatLabel from "./FloatLabel";
import Box from "../../box/Box";

function Field({ fieldState, children, helperText, label, error, icon, boxProps }) {
  const { bg, ...otherBoxProps } = boxProps;

  //because a React.forwardRef component can't have default props.
  const defaultBoxProps = {
    my: 2
  };

  return (
    <Box {...{ ...defaultBoxProps, ...otherBoxProps }}>
      {label && <FloatLabel>{label}</FloatLabel>}
      <FieldBorder fieldState={fieldState} bg={bg}>
        {children}
        {icon && (
          <Box
            width="50px"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 2
            }}
          >
            {icon}
          </Box>
        )}
      </FieldBorder>
      {error || helperText ? <HelperText fieldState={fieldState}>{error ? error : helperText}</HelperText> : null}
    </Box>
  );
}

export default Field;
