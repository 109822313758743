import { makeObservable, observable, computed, action, configure } from "mobx";
import * as AuthService from "./AuthService";
import Cookies from "js-cookie";
import { FaAcquisitionsIncorporated } from "react-icons/fa";

configure({
  useProxies: "never"
})
//needed for secure param when setting cookies on staging or production?
const isSecure = process.env.NODE_ENV !== "development";

const API_AUTH_TOKEN = "AGMD_API_AUTH_TOKEN";
const API_PUBLIC_TOKEN = "AGMD_API_PUBLIC_TOKEN"

export const AUTH_STATE = Object.freeze({
  INITIAL: "INITIAL",
  DENIED: "DENIED",
  GRANTED: "GRANTED"
});

class AuthStore {

  @observable authState = AUTH_STATE.INITIAL;
  @observable user = null;
  @observable public_user = null;
  intendedPath = "/";
  
  @observable subscriptionPlan = null;
  @observable subscriptionPlans = null;
  @observable billingAddress = null;
  @observable paymentInformation = null;

  @observable registerData = null;

  @observable billingAddress = null;

  @observable tempToken = null;

  @observable email = null;

  constructor(window, history) {
    this.window = window;
    this.history = history;
    this.storage = window.localStorage;
    this.init();
    this.deletePublicToken();
    this.getPublicToken();
    this.fetchSubscriptionPlans()
  }

  @action
  init = async () => {
    //if a user types in a url but is not logged in,
    //save it to redirect after login.
    this.intendedPath = this.history.location.pathname;
    if (this.token) {
      try {
        const isValid = await this.verifyToken(this.token);
        this.authState = isValid ? AUTH_STATE.GRANTED : AUTH_STATE.DENIED;
      } catch (e) {
        this.authState = AUTH_STATE.DENIED;
        this.deleteToken();
        this.user = null;
      }
    } else {
      this.authState = AUTH_STATE.DENIED;
    }
  };

  getPublicToken = async () => {
    if (!this.public_token) {
      const formData = {
        email_address: process.env.CREDENTIAL_EMAIL,
        password: process.env.CREDENTIAL_PASSWORD
      };
      return AuthService.login(formData).then(({ token, user }) => {
        this.setTempToken(token);
        this.setPublicToken(token);
        this.public_user = user;
        return token;
      });
    } else {
      try {
        const isValid = await this.verifyPublicToken(this.public_token);
        if (!isValid) {
          this.deletePublicToken();
          this.getPublicToken();
        } else {
          this.setTempToken(this.public_token);
        }
      } catch (e) {
        this.deletePublicToken();
        this.getPublicToken();
      }
    }
  };

  login = formData => {
    return AuthService.login(formData).then(({ token, user }) => {
      this.setToken(token);
      this.user = user;
    });
  };

  register = formData => {
    return AuthService.register(formData).then(({ token, user }) => {
      this.setToken(token);
      this.user = user;
    });
  };

  @action
  setToken(token) {
    if (token !== null && typeof token !== "undefined") {
      Cookies.set(API_AUTH_TOKEN, token, {
        secure: isSecure
      });
      this.authState = AUTH_STATE.GRANTED;
    } else {
      this.deleteToken();
    }
  }

  @action
  setPublicToken(token) {
    if (token !== null && typeof token !== "undefined") {
      Cookies.set(API_PUBLIC_TOKEN, token, {
        secure: isSecure
      });
    } else {
      this.deletePublicToken();
    }
  }

  @action
  logout() {
    AuthService.logout(this.token);
    this.deleteToken();
    this.deletePublicToken();
    this.authState = AUTH_STATE.DENIED;
    this.intendedPath = "/";
    this.user = null;
    this.history.push(this.intendedPath);
  }

  @action
  verifyToken = async token => {
    try {
      const response = await AuthService.verifyToken(token);
      this.user = response.data.user;
      return true;
    } catch (e) {
      return false;
    }
  };

  @action
  verifyPublicToken = async token => {
    try {
      const response = await AuthService.verifyToken(token);
      this.public_user = response.data.user;
      return true;
    } catch (e) {
      return false;
    }
  };

  @action
  setRegisterData = registerData => {
    this.registerData = registerData
  }

  deleteToken() {
    Cookies.remove(API_AUTH_TOKEN);
  }

  deletePublicToken() {
    Cookies.remove(API_PUBLIC_TOKEN);
  }

  resetPassword = formData => {
    return AuthService.resetPassword(formData);
  };

  get token() {
    return Cookies.get(API_AUTH_TOKEN);
  }

  get public_token() {
    return Cookies.get(API_PUBLIC_TOKEN);
  }

  @computed
  get isLoggedIn() {
    return this.authState === AUTH_STATE.GRANTED && this.user;
  }
  
  @action
  fetchSubscriptionPlans() {
    return AuthService.getSubscriptionPlans().then(data => {
      this.setSubscriptionPlans(data.subscription_plans);
    });
  }

  @action
  setSubscriptionPlans(subscriptionPlans) {
    const environment = process.env.BUILD_ENV;
    const enviromentCode = (environment === 'development' || environment === 'staging')? 0 : 1;
    this.subscriptionPlans = subscriptionPlans.filter(subscriptionPlan => 
        subscriptionPlan.is_live === enviromentCode
      );
  }

  @action
  setSubscriptionPlan(subscriptionPlan) {
    this.subscriptionPlan = subscriptionPlan;
  }

  @action
  setBillingAddress(billingAddress) {
    this.billingAddress = billingAddress;
  }

  @action
  setTempToken(token) {
    this.tempToken = token;
  }

  @action
  fetchCustomerId(customerInfo) {
    return AuthService.createCustomer(customerInfo).then(data => {
      return data;
    })
  }

  @action
  setEmail(email) {
    this.email = email;
  }

  @action
  createSubscription(subscriptionData) {
    return AuthService.createSubscription(subscriptionData).then(data => {
      return data
    })
  }

  // @computed
  // get subscriptionPlans() {
  //   return [...this.subscriptionPlans];
  // }
}

export default AuthStore;
