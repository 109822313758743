import React from "react";
import Box from "../uikit/box/Box";
import Text from "../uikit/text/Text";
import { Link } from "react-router-dom";

export const AsideHeading = ({ title, subtitle, link, icon, ...rest }) => (
  <Box bg="primarys.0" py={3} pl={6} pr={3} display="flex" alignItems="center" {...rest}>
    <Box flex="1">
      <Text color="white" fontWeight="bold" fontSize={6} flex="2">
        {title}
      </Text>
      {subtitle && link? (
        <Text el={Link} to={link} color="white" mt={1}>
          {subtitle}
        </Text>
      ) : subtitle ? (
        <Text color="white" mt={1}>
          {subtitle}
        </Text>
      ) : ''}

    </Box>
    {icon && icon}
  </Box>
);

export const AsideSubHeading = ({ children }) => {
  return (
    <Text textStyle="label" py={3} pl={6} pr={3} bg="primarys.0">
      {children}
    </Text>
  );
};
