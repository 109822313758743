export const errorMap = new Map([[1062, "Email address already exists"]]);

export function mapServerErrors(error_number) {
  return errorMap.has(error_number) ? errorMap.get(error_number) : "An error occurred";
}

export const getRatingLabel = (rating) => {
  switch(rating) {
    case 1:
      return 'Poor';
    case 2:
      return 'Fair';
    case 3:
      return 'Good';
    case 4:
      return 'Excellent';
    case 5:
      return 'Superior';
  }
}
