import React, { useState, useEffect } from "react";
import Box from "../../uikit/box/Box";
import TextField from "../../uikit/form/textfield/TextField";
import Button from "../../uikit/button/Button";
import { format, addYears } from "date-fns";
import { useForm } from "react-hook-form";
import { string, object } from "yup";
import { convertDates } from "../../shared/form/utils";

const Schema = object().shape({
  name: string().required("Name is required"),
  description: string(),
  category: string(),
  start_date: string().required("Start date is required"),
  end_date: string().required("End date is required"),
});

function ContractForm({ defaultValues, onSubmit, onCancel, onArchive, onUnarchive }) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema,
    defaultValues: convertDates(defaultValues),
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const onFormSubmit = (data) => {
    onSubmit &&
      onSubmit(data).catch((serverErrors) => {
        console.log("server errors", serverErrors);
        // setFormErrors(serverErrors);
      });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <Box px={4}>
        <Box my={5}>
          <TextField
            label="Name"
            name="name"
            ref={register}
            disabled={defaultValues && defaultValues.is_archived}
            error={formErrors.name && formErrors.name.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            label="Category"
            name="category"
            ref={register}
            disabled={defaultValues && defaultValues.is_archived}
            error={formErrors.category && formErrors.category.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            label="Start Date"
            type="date"
            name="start_date"
            defaultValue={format(new Date(), "yyyy-MM-dd")}
            ref={register}
            disabled={defaultValues && defaultValues.is_archived}
            error={formErrors.start_date && formErrors.start_date.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            label="End Date"
            type="date"
            name="end_date"
            defaultValue={format(addYears(new Date(), 1), "yyyy-MM-dd")}
            ref={register}
            disabled={defaultValues && defaultValues.is_archived}
            error={formErrors.end_date && formErrors.end_date.message}
          />
        </Box>
        <Box my={5}>
          <TextField
            multiline={true}
            rows={4}
            label="Description"
            name="description"
            ref={register}
            disabled={defaultValues && defaultValues.is_archived}
            error={formErrors.description && formErrors.description.message}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        borderTop="1px solid"
        borderColor="grays.1"
        pt={5}
      >
        <Button
          variant="tertiary"
          color="black"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Cancel
        </Button>
        <Box>
          {defaultValues && defaultValues.id && defaultValues.is_archived === 1 && onUnarchive && (
            <Button
              variant="secondary"
              mr={3}
              color="black"
              onClick={() => onUnarchive(defaultValues.id)}
            >
              Unarchive
            </Button>
          )}
          {defaultValues && defaultValues.id && !defaultValues.is_archived && onArchive && (
            <Button
              variant="secondary"
              mr={3}
              color="black"
              onClick={() => onArchive(defaultValues.id)}
            >
              Archive
            </Button>
          )}
          <Button type="submit" disabled={defaultValues && defaultValues.is_archived}>Save</Button>
        </Box>
      </Box>
    </form>
  );
}

export default ContractForm;
