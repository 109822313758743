import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import RegisterForm from "./RegisterForm";
import { useParams } from "react-router";
import Button from "../../uikit/button/Button";
import TextField from "../../uikit/form/textfield/TextField";
import { useForm } from "react-hook-form";
import { string, object, ref } from "yup";
import { mapServerErrors } from "../../../lib/utils";
import { observer, inject } from "mobx-react";

import { toJS } from 'mobx';
import { useHistory } from 'react-router-dom';

const Schema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  email_address: string()
    .email()
    .required("Email is required"),
  phone: string(),
  job_title: string(),
  company_name: string().required(),
  password: string().required("Password is required"),
  password_confirmation: string().oneOf([ref("password"), null], "Passwords must match")
});


function Register(props) {

  const history = useHistory();
  
  // useEffect(() => {
  //   props.authStore.registerData && 
  //   console.log('props.authStore.registerData: ', {...props.authStore.registerData});
  //   props.authStore.subscriptionPlans && 
  //   console.log('props.authStore.subscriptionPlans from toJS: ', toJS(props.authStore.subscriptionPlans))
  // })

  const email = props.authStore.email;

  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema
  });

  const [serverErrors, setServerErrors] = useState(null);

  const onSubmit = data => {
    props.authStore.setRegisterData(data);
    history.push("/startfreetrial");
  }

  return (
    <>
      <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="75%" margin="auto" marginTop="3vh">
        <Text color="white" fontSize={12} textAlign="center" mb={4}>
          Set up your profile
        </Text>
        <Text color="white" fontSize={9} textAlign="center" mb={4}>
          You're joining with the email address {email}
        </Text>
        <Box p={[1, 1]} maxWidth="600px" margin="auto">

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box mb={5}>
              <Box my={5} bg="#ffffff">
                <TextField
                  required
                  placeholder="First Name"
                  name="first_name"
                  ref={register}
                  error={errors.first_name && errors.first_name.message}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
              <Box my={5} bg="#ffffff">
                <TextField
                  required
                  placeholder="Last Name"
                  name="last_name"
                  ref={register}
                  error={errors.last_name && errors.last_name.message}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
              <Box my={5} bg="#ffffff">
                <TextField
                  required
                  placeholder="Email"
                  name="email_address"
                  ref={register}
                  error={errors.email_address && errors.email_address.message}
                  value={email}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
              <Box my={5} bg="#ffffff">
                <TextField
                  placeholder="Phone"
                  name="phone"
                  ref={register}
                  error={errors.phone && errors.phone.message}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
              <Box my={5} bg="#ffffff">
                <TextField
                  required
                  placeholder="Job Title"
                  name="job_title"
                  ref={register}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
              <Box my={5} bg="#ffffff">
                <TextField
                  required
                  placeholder="Company Name"
                  name="company_name"
                  ref={register}
                  error={errors.company_name && errors.company_name.message}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
              <Box my={5} bg="#ffffff">
                <TextField
                  required
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref={register}
                  error={errors.password && errors.password.message}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
              <Box my={5} bg="#ffffff">
                <TextField
                  required
                  type="password"
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  ref={register}
                  error={errors.password_confirmation && errors.password_confirmation.message}
                  color="#111111"
                  style={{ fontSize: "16px", paddingLeft: '20px', lineHeight: '1.5' }}
                />
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center">
              {serverErrors && (
                <Text textAlign="center" color="accents.0" mb={4}>
                  {serverErrors}
                </Text>
              )}
            </Box>
          </form>
        </Box>

      </Box>
      <Box my={5} p={2} maxWidth="220px" bg="#ff0000" margin='auto' style={{ marginTop: '4vh', cursor: 'pointer' }} onClick={handleSubmit(onSubmit)} >
        <Text color="white" fontSize={8} textAlign="center" mb={4} lineHeight={1} style={{ marginTop: '12px' }}>
          Continue
        </Text>
      </Box>
    </>
  );
}

export default inject("authStore")(observer(Register));