import React from "react";
import { NavLink } from "react-router-dom";
import Box from "../uikit/box/Box";
import Button from "../uikit/button/Button";
import { observer, inject } from "mobx-react";

function ActionMenu({ uiStore, contractStore, disabled }) {
  return (
    <Box display="flex" justifyContent="flex-end">
      {contractStore.contracts.length > 0 && (
        <Button
          m={2}
          width="140px"
          disabled={disabled}
          onClick={() => {
            uiStore.projectModalOpen = true;
          }}
        >
          + New Project
        </Button>
      )}
      <Button
        m={2}
        width="140px"
        variant="secondary"
        color="black"
        disabled={disabled}
        onClick={() => {
          uiStore.contractModalOpen = true;
        }}
      >
        + New Contract
      </Button>
    </Box>
  );
}

export default inject("uiStore", "contractStore")(observer(ActionMenu));
