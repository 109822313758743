import React from "react";
import { observer, inject } from "mobx-react";
import { format } from "date-fns";
import { Link, NavLink } from "react-router-dom";

import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import { Table } from "../../uikit/table";
import Button from "../../uikit/button/Button";

import { AsideHeading } from "../../shared/Aside";
import MainLayout from "../../shared/layout/MainLayout";

function ContractArchivePage({ contractStore, history }) {

  const contracts = contractStore.archivedContracts || [];

  const onUnarchive = (id) => {
    contractStore.unarchiveContract(id).then(() => {
      history.push('/dashboard');
    });
  }

  return (
    <>
      <MainLayout
        aside={
          <Box>
            <AsideHeading title="Archived" mt={5} />
          </Box>
        }
        main={
          <Box p={5}>
            <Text mt={5} fontSize={4} fontWeight="bold" mb={3}>Archived Contracts</Text>
            {
              !contracts.length ? <Text fontSize={1} mt={1}>No archived contracts</Text> :
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Text fontFamily="montserrat">Name</Text>
                      </th>
                      <th>
                        <Text fontFamily="montserrat">Category</Text>
                      </th>
                      <th>
                        <Text fontFamily="montserrat">Start Date</Text>
                      </th>
                      <th>
                        <Text fontFamily="montserrat">End Date</Text>
                      </th>
                      <th>
                        <Text fontFamily="montserrat">Options</Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    contracts.map(contract => (
                      <tr key={contract.id}>
                        <td>
                          <Text el={NavLink} to={`/contract/${contract.id}`} fontSize={1} fontWeight="bold">{contract.name}</Text>
                        </td>
                        <td>
                          <Text fontSize={1}>{contract.category}</Text>
                        </td>
                        <td className="date">
                          <Text fontSize={1}>
                            {format(new Date(contract.start_date), "MM.dd.yy")}
                          </Text>
                        </td>
                        <td className="date">
                          <Text fontSize={1}>
                            {format(new Date(contract.end_date), "MM.dd.yy")}
                          </Text>
                        </td>
                        <td>
                          <Text fontSize={1}>
                            <Button
                              variant="secondary"
                              mr={3}
                              color="black"
                              onClick={() => onUnarchive(contract.id)}
                            >
                              Unarchive
                            </Button>
                          </Text>
                        </td>
                      </tr>
                    ))
                  }
                  </tbody>
                </Table>
            }
          </Box>
        }
      />
    </>
  );
}

export default inject("contractStore")(observer(ContractArchivePage));
