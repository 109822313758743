/**
 * const REG = /^([mpfw][trblxy]?|outline|small|fullwidth|width|color|bg)$/;
   removeProps(REG, props)
 * @param {RegExp} REG
 * @param {object} props
 */
export const removeProps = (REG, props) => {
  const next = {};

  for (let key in props) {
    if (REG.test(key)) {
      continue;
    }
    next[key] = props[key];
  }

  return next;
};

export const removeBlacklistProps = (blacklist, props) => {
  const next = {};

  for (let key in props) {
    if (blacklist.includes(key)) {
      continue;
    }
    next[key] = props[key];
  }

  return next;
};
