import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import Media from 'react-media';
import { observer, inject, Provider } from 'mobx-react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { FiInfo, FiChevronUp, FiChevronDown } from 'react-icons/fi';

import Box from '../../uikit/box/Box';
import Text from '../../uikit/text/Text';
import Icon from '../../uikit/icon/Icon';
import Modal from '../../uikit/modal/Modal';
import Button from '../../uikit/button/Button';

import ActionMenu from '../../shared/ActionMenu';
import { AsideHeading } from '../../shared/Aside';
import MainLayout from '../../shared/layout/MainLayout';
import RatingLabel from '../../shared/rating/RatingLabel';
import StatDisplay from '../../shared/rating/StatDisplay';

import Members from './members/Members';
import ProjectStore from '../ProjectStore';
import ProjectDetail from './ProjectDetail';
import ProjectForm from '../form/ProjectForm';
import MeetingDetail from '../../meeting/detail/MeetingDetail';

function ProjectDetailPage({ projectStore, match, history, contractStore }) {
  const [currentContract, setCurrentContract] = useState(null);
  const [project, setProject] = useState(null);
  const [stats, setStats] = useState({
    totalReviewCompletedPercentage: 0,
    totalMeetings: 0,
    totalContributers: 0,
    totalReviews: 0,
  });
  const [open, setOpen] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!project) {
      projectStore.initProject(match.params.projectId).then((res) => {
        setProject(projectStore.project);
        setStats(projectStore.stats);
      });
      return;
    }
  }, [match.params.projectId]);

  useEffect(() => {
    if (project) {
      contractStore.fetchContract(project.contract_id).then((res) => {
        setCurrentContract(contractStore.currentContract);
      });
    }
  }, [project]);

  if (!projectStore || !projectStore.project || !project || !currentContract) {
    return null;
  }

  return (
    <>
      <MainLayout
        aside={
          <Box display="flex" height="100%" flexDirection="column">
            <AsideHeading
              title={project.name}
              subtitle={currentContract.name}
              link={`/contract/${project.contract_id}`}
              mt={5}
              icon={
                <Media
                  query="(max-width: 959px)"
                  render={() => (
                    <Icon
                      size={24}
                      icon={open ? FiChevronUp : FiChevronDown}
                      color="white"
                      onClick={(e) => {
                        setOpen((s) => !s);
                      }}
                    />
                  )}
                />
              }
            />
            {open && (
              <Box display="flex" flexDirection="column" height="100%" pt={5}>
                <Box flex="1" overflow="auto">
                  {/* <RatingLabel rating={contract.rating} label="Overall Agency Rating" mb={4} /> */}

                  <Box
                    borderTop="1px solid"
                    borderBottom="1px solid"
                    borderColor="secondarys.0"
                    pl={4}
                    py={5}
                    mx={4}
                  >
                    <Box display="flex" alignItems="center" mb={2}>
                      <Text mb={0} mr={2} color="white" fontWeight="bold">
                        Bot Address
                      </Text>
                    </Box>
                    <Text
                      mb={5}
                      color="white"
                      fontFamily="montserrat"
                      lineHeight={5}
                      style={{ wordBreak: 'break-all' }}
                    >
                      {!project.is_approved
                        ? '*******@agencyminder.com'
                        : project.bot_address}
                    </Text>
                    <Text mb={2} color="white" fontWeight="bold">
                      Description
                    </Text>
                    <Text color="white" fontFamily="montserrat" lineHeight={5}>
                      {project.description}
                    </Text>
                  </Box>

                  <Box
                    borderBottom="1px solid"
                    borderColor="secondarys.0"
                    pl={4}
                    py={4}
                    mx={4}
                  >
                    <StatDisplay
                      title="Total Reviews Completed"
                      isPercentage={true}
                      value={stats.totalReviewCompletedPercentage}
                    />
                  </Box>
                  <Box
                    borderBottom="1px solid"
                    borderColor="secondarys.0"
                    pl={4}
                    py={5}
                    mx={4}
                  >
                    <StatDisplay
                      title="Total Contributors"
                      value={stats.totalContributers}
                      mb={3}
                    />
                    <StatDisplay
                      title="Total Reviews"
                      value={stats.totalReviews}
                      mb={3}
                    />
                    <StatDisplay
                      title="Total Meetings"
                      value={stats.totalMeetings}
                      mb={3}
                    />
                  </Box>
                </Box>
                <Box
                  pl={4}
                  py={5}
                  mx={4}
                  borderTop="1px solid"
                  borderColor="secondarys.0"
                >
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      setModalOpen(true);
                    }}
                    disabled={!project.is_approved}
                  >
                    Edit Project
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        }
        main={
          <Provider projectStore={projectStore}>
            <Box
              p={5}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <ActionMenu disabled={project.is_archived} />
              <Route
                path={`${match.path}/meeting/:meetingId`}
                render={(props) => <MeetingDetail {...props} />}
              />
              <Route
                exact
                path={`${match.path}/members`}
                render={(props) => <Members {...props} />}
              />
              <Route
                exact
                path={`${match.path}/`}
                render={(props) => <ProjectDetail {...props} />}
              />
            </Box>
          </Provider>
        }
      />

      <Modal
        title="Edit Project"
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      >
        <ProjectForm
          contractList={toJS(contractStore.contracts)}
          defaultValues={toJS(project)}
          onSubmit={(data) => {
            return projectStore.updateProject(data).then(() => {
              contractStore.fetchContracts();
              setModalOpen(false);
            });
          }}
          onCancel={() => {
            setModalOpen(false);
          }}
          onArchive={(id) => {
            projectStore.archiveProject(id).then((project) => {
              contractStore.fetchContracts().then((res) => {
                setModalOpen(false);
                history.push(`/contract/${project.contract_id}`);
              });
            });
          }}
          onUnarchive={(id) => {
            projectStore.unarchiveProject(id).then((project) => {
              if (project) {
                contractStore.fetchContracts().then((res) => {
                  setModalOpen(false);
                  history.push(`/contract/${project.contract_id}`);
                });
              }
            });
          }}
        />
      </Modal>
    </>
  );
}

export default inject(
  'contractStore',
  'projectStore'
)(observer(ProjectDetailPage));
