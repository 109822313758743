import React from "react";
import styled, { css } from "styled-components";

const themeFieldColor = p => {
  return p.currentState && p.theme ? p.theme.component.formfield[p.currentState].borderColor : "grays.1";
};

const StyledBorder = styled.div`
  box-sizing: border-box;
  position: relative;
  // overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: ${themeFieldColor};

  ${p => {
    if (p.disabled || p.readOnly) {
      return css`
        background-color: ${themeFieldColor};
        pointer-events: none;
      `;
    } else {
      return "";
    }
  }};
`;

function FieldBorder({ fieldState, children, ...rest }) {
  return (
    <StyledBorder {...fieldState} {...rest}>
      {children}
    </StyledBorder>
  );
}

export default FieldBorder;
