import React from "react";
import "../Auth.css";
import Text from "../../../uikit/text/Text";

function SubscriberAgreement() {
  return (
    <div style={{ marginTop: "5rem" }}>
      <div className="privacy-main-title">
        <Text fontSize={11}>Subscriber Agreement</Text>
        <Text style={{
          marginTop:'22px',
          fontSize:'20px'
        }}>LAST UPDATED: JUNE 22, 2020</Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        This Subscriber Agreement (the “Agreement”) is entered into by and between Agency Minder, Inc. (“Agency Minder”) and the organization agreeing to the terms of this Agreement (“Customer”). This Agreement shall be effective on the earliest of (a) the date Customer clicks a button indicating its agreement with the terms of this Agreement; (b) Customer entering into an Order Form or similar form referencing or otherwise incorporating this Agreement; or (c) Customer’s use of the Service (the “Effective Date”). If you are entering into this Agreement on behalf of your organization, that organization is deemed to be the Customer and you represent that you have the power and authority to bind that organization to this Agreement.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        1 The Service.
        <br />
        <br />
        1.1 Provision of the Service. Agency Minder shall make the Service purchased under an Order Form available to Customer and its End Users pursuant to this Agreement during the applicable Subscription Term. The Service includes the features and functionality applicable to the version of the Service ordered by Customer. Agency Minder may update the content, functionality, and user interface of the Service from time to time in its sole discretion.
        <br />
        <br />
        1.2 Access Rights. Customer has a non-exclusive, non-sublicensable,
        non-transferable (except as specifically permitted in this Agreement)
        right to access and use the Service pursuant to this Agreement during
        the applicable Subscription Term, solely for Customer’s internal
        business purposes subject to the limitations set forth in the Order
        Form.
        <br />
        <br />
        1.3 Usage Restrictions. Customer shall not (a) make the Service
        available to, or use any Service for the benefit of anyone other than
        Customer and its Affiliates; (b) rent, sublicense, re-sell, assign,
        transfer, distribute, time share, or similarly exploit the Service; (c)
        reverse engineer, copy, modify, adapt, hack the Service, or otherwise
        attempt to gain unauthorized access to the Service or its related
        systems or networks; (d) access the Service, the Documentation, or
        Agency Minder’s Confidential Information to build a competitive product
        or service; (e) alter or remove, or permit any third party to alter or
        remove, any proprietary trademark or copyright markings incorporated in,
        marked on, or affixed to the Service; (f) allow End User Subscriptions
        to be shared or used by more than one individual End User (except that
        End User Subscriptions may be reassigned to new End Users replacing
        individuals who no longer use the Service for any purpose, whether by
        termination of employment or other change in job status or function); or
        (g) access or use the Service: (i) to send or store infringing, obscene,
        threatening, or otherwise unlawful material, including material
        violative of third-party privacy rights; (ii) in violation of applicable
        laws; (iii) to send or store material knowingly or intentionally
        containing software viruses, worms, Trojan horses or other harmful
        computer code, files, or scripts; or (iv) in a manner that interferes
        with or disrupts the integrity or performance of the Service (or the
        data contained therein).
        <br />
        <br />
        1.4 Protection of Customer Data. Agency Minder shall implement and
        maintain administrative, organizational, and technical safeguards
        designed for the protection, confidentiality, and integrity of Customer
        Data.
        <br />
        <br />
        1.5 Administration of Customer’s Account. Customer acknowledges that it
        retains administrative control over to whom it grants access to Customer
        Data hosted in the Service. Customer may specify an End User to be the
        billing owner and, depending on the Subscription, one or more End Users
        to be administrators (each an “Administrator”) to manage its account,
        and Agency Minder is entitled to rely on communications from an
        Administrator when servicing Customer’s account. Depending on the
        version purchased by Customer, Customer’s Administrator(s) may have the
        ability to access, monitor, use, and/or export Customer Data. Customer
        is responsible for maintaining the security of End User accounts and
        passwords.
        <br />
        <br />
        1.6 Compliance. Customer is responsible for use of the Service by its
        End Users and for their compliance with this Agreement. Customer is
        solely responsible for the accuracy, quality, legality, reliability, and
        appropriateness of all Customer Data. Customer shall ensure that it is
        entitled to transfer the relevant Customer Data to Agency Minder so that
        Agency Minder and its service providers may lawfully use, process, and
        transfer the Customer Data in accordance with this Agreement on
        Customer’s behalf. Customer shall promptly notify Agency Minder if it
        becomes aware of any unauthorized use of or access to Customer’s account
        or the Service.
        <br />
        <br />
        1.7 Suspension. Agency Minder may request that Customer suspend the
        account of any End User who (a) violates this Agreement or Agency
        Minder’s User Terms of Service; or (b) is using the Service in a manner
        that Agency Minder reasonably believes may cause a security risk, a
        disruption to others’ use of the Service, or liability for Agency
        Minder. If Customer fails to promptly suspend or terminate such End
        User’s account, Agency Minder reserves the right to do so.
        <br />
        <br />
        1.8 Customer’s Use of Third Party Services. Customer may install or
        enable third party services for use with the Service, such as online
        applications, offline software products, or services that utilize the
        Agency Minder API in connection with Customer’s use of the Service
        (“Third Party Services”). Any acquisition and use by Customer or its End
        Users of such Third Party Services is solely the responsibility of
        Customer and the applicable third party provider. Customer acknowledges
        that providers of such Third Party Services may have access to Customer
        Data in connection with the interoperation and support of such Third
        Party Services with the Service. To the extent Customer authorizes the
        access or transmission of Customer Data through a Third Party Service,
        Agency Minder shall not be responsible for any use, disclosure,
        modification, or deletion of such Customer Data or for any act or
        omission on the part of the third party provider or its service.
        <br />
        <br />
        1.9 Trial Subscriptions. Customer may access a version of the Service on
        a trial basis (a “Trial”) subject to the terms of this Agreement;
        provided, however, the following additional terms shall apply to its
        Trial notwithstanding anything to the contrary herein: (a) Agency Minder
        shall have the right to terminate a Trial at any time and for any
        reason; (b) Agency Minder is providing the Service “as is” and makes no
        warranties (express or implied) of any kind with respect to the Service
        during the Trial; and (c) Agency Minder shall have no obligation to
        indemnify Customer. CUSTOMER ACKNOWLEDGES THAT ITS TRIAL WILL
        AUTOMATICALLY CONVERT TO A SUBSCRIPTION AT THE END OF THE TRIAL AND THAT
        Agency Minder MAY CHARGE CUSTOMER FOR THE APPLICABLE SUBSCRIPTION FEES
        UNLESS CUSTOMER HAS NOTIFIED Agency Minder IN WRITING OF ITS DECISION TO
        OPT OUT DURING THE TRIAL.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        2 Warranties.
        <br />
        <br />
        2.1 By Agency Minder. Agency Minder warrants that during the applicable
        Subscription Term (a) the Service shall perform materially in accordance
        with the applicable Documentation; and (b) Agency Minder shall not
        materially decrease the functionality of the Service.
        <br />
        <br />
        2.2 By Customer. Customer warrants that (a) this Agreement is legally
        binding upon it and enforceable in accordance with its terms; (b) it has
        obtained all legally required consents and permissions from End Users
        for the submission and processing of personal data through the Service;
        and (c) the transfer and processing of Customer Data under the Agreement
        is lawful.
        <br />
        <br />
        2.3 Disclaimer. EXCEPT AS EXPRESSLY PROVIDED FOR IN THIS SECTION, TO THE
        FULLEST EXTENT PERMITTED BY LAW, THE PROFESSIONAL SERVICES, SERVICE, AND
        ALL RELATED COMPONENTS AND INFORMATION ARE PROVIDED ON AN “AS IS” AND
        “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND Agency
        Minder AND ITS AFFILIATES EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES,
        WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
        AND NON-INFRINGEMENT. CUSTOMER ACKNOWLEDGES THAT Agency Minder DOES NOT
        WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR-FREE. Agency Minder IS NOT RESPONSIBLE FOR AND DISCLAIMS ALL
        LIABILITY RELATED TO DELAYS, DELIVERY FAILURES, INTERCEPTION,
        ALTERATION, OR OTHER DAMAGE RESULTING FROM MATTERS OUTSIDE OF ITS
        CONTROL, INCLUDING PROBLEMS INHERENT IN THE USE OF THE INTERNET, MOBILE
        AND PERSONAL COMPUTING DEVICES, TRANSMISSION OF ELECTRONIC
        COMMUNICATIONS OVER THE INTERNET OR OTHER NETWORKS, AND THIRD PARTY
        HOSTING SERVICE PROVIDERS.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        3 Fees and Payment.
        <br />
        <br />
        3.1 Subscription Fees. Customer’s Subscription fees are set forth in the
        applicable Order Form and are based on the number of projects purchased.
        Customer shall pay all fees when due and is responsible for providing
        complete and accurate billing information to Agency Minder. If such fees
        are being paid via credit card or other electronic means, Customer
        authorizes Agency Minder to charge such fees using Customer’s selected
        payment method. Payment obligations are non-cancelable and fees paid are
        non-refundable unless otherwise provided herein. If Customer requires
        the use of a purchase order or purchase order number, Customer shall
        provide the purchase order number at the time of purchase. Where
        Customer designates use of a third-party payment processor network (such
        as a payment agent, for example), Customer shall be responsible for
        payment of all fees and charges associated with use of such network.
        Agency Minder reserves the right to suspend Customer’s account, in
        addition to all of its other available rights and remedies, in the event
        that Customer’s account becomes overdue. Suspension shall not relieve
        Customer’s obligation to pay amounts due.
        <br />
        <br />
        3.2 Auto-renewal. Customer agrees that its Subscription will
        automatically renew on an annual or monthly basis depending on
        Customer’s Subscription (the “Renewal Date”). Customer authorizes Agency
        Minder to automatically charge Customer for the applicable fees on or
        after the Renewal Date unless the Subscription has been terminated or
        cancelled in accordance with this Agreement. If Customer wishes to
        reduce the number of Projects in its Subscription, it must do so prior
        to the Renewal Date. Customer must cancel its Subscription prior to the
        Renewal Date in order to avoid billing of the next period’s Subscription
        fees. Customer can cancel its Subscription anytime online by going into
        its account settings and following the instructions provided. If
        Customer chooses to cancel its Subscription during the Subscription
        Term, Customer may use the Service until the end of Customer’s
        then-current Subscription Term or renewal period, but will not be issued
        a refund for the most recently (or any previously) charged fees.
        <br />
        <br />
        3.3 Calculation. Subscription fees are based on annual or monthly
        periods (or pro rata portions thereof, calculated on a daily basis) that
        begin on the Subscription start date and each annual or monthly
        anniversary thereof. Subscriptions to the Service are sold on a tiered
        basis based on the number of Project. Customer shall purchase a
        Subscription to the Service for each Project reflected in the applicable
        Order Form. Customer may add Projects to its Subscription at any time.
        Agency Minder reserves the right to revise fee rates and/or the billable
        amount structure for the Service at any time and will provide Customer
        with notice pursuant to Section 11.4 below) of any such changes at least
        twenty (20) days prior. Agency Minder may charge Customer the
        then-current pricing for the applicable Subscription if the number of
        Projects is modified and/or if Customer changes its Subscription plan.
        <br />
        <br />
        3.4 Taxes. Any fees charged to Customer are exclusive of taxes. Except
        for those taxes based on Agency Minder’s net income, Customer shall be
        responsible for all applicable taxes in connection with this Agreement
        including, but not limited to, sales, use, excise, value-added, goods
        and services, consumption, and other similar taxes or duties. Should any
        payment for the Service be subject to withholding tax by any government,
        Customer shall reimburse Agency Minder for such withholding tax.
        <br />
        <br />
        3.5 Future Features and Functionality. Customer agrees that any
        purchases under this Agreement are not contingent on the delivery of any
        future feature or functionality or dependent on any oral or written
        public or private comments made by Agency Minder regarding future
        features or functionality. Agency Minder may release Improvements and
        other features and functionality in its discretion. Some features and
        functionality may be available only with certain versions of the
        Service.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        4 Term and Termination. This Agreement commences on the Effective Date
        and shall remain in effect until all Subscriptions to the Service
        granted in accordance with this Agreement have expired or been
        terminated. Either party may terminate this Agreement if the other
        party: (a) is in material breach of this Agreement and fails to cure
        such breach within twenty (20) days following receipt of written notice
        from the non-breaching party, except that termination will take effect
        on notice in the event of a breach of Section 1.3 (“Usage
        Restrictions”); or (b) ceases its business operations or becomes subject
        to insolvency proceedings and the proceedings are not dismissed within
        sixty (60) days. Upon expiration or termination of this Agreement for
        any reason, all Subscriptions and any other rights granted to Customer
        under this Agreement shall immediately terminate, and Agency Minder may
        immediately deactivate Customer’s account(s) associated with the
        Agreement. In no event will any termination relieve Customer of the
        obligation to pay any fees accrued or payable to Agency Minder. The
        following sections shall survive expiration or termination of this
        Agreement: Sections 1.3 (“Usage Restrictions”), 2 (“Warranties”), 3.1
        (“Subscription Fees”), 3.4 (“Taxes”), 4 (“Term and Termination”), 5
        (“Confidentiality”), 6 (“Intellectual Property Rights”), 7
        (“Indemnification”), 8 (“Liability”), 9 (“Export Compliance”), 10 (“Use
        Outside the United States of America”), 11 (“Miscellaneous”), and 12
        (“Definitions”).
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        5 Confidentiality.
        <br />
        <br />
        5.1 Definition of Confidential Information. During the course of
        performance under this Agreement, each party may make available to the
        other party information that is not generally known to the public and at
        the time of disclosure is either identified as, or should reasonably be
        understood by the receiving party to be, proprietary or confidential
        (the “Confidential Information”). Confidential Information specifically
        includes, but is not limited to, the Service, any Order Form(s) entered
        into by the parties, Customer Data, Results, business plans, product
        plans and roadmaps, strategies, forecasts, projects and analyses,
        financial information and fee structures, business processes, methods
        and models, and technical documentation. Confidential Information does
        not include information that (a) is or becomes publicly available
        without breach of this Agreement by the receiving party; (b) was known
        to the receiving party prior to its disclosure by the disclosing party;
        (c) is or was independently developed by the receiving party without the
        use of any Confidential Information of the disclosing party; or (d) is
        or was lawfully received by the receiving party from a third party under
        no obligation of confidentiality.
        <br />
        <br />
        5.2 Protection of Confidential Information. Except as otherwise
        expressly permitted under this Agreement, with the express prior written
        consent of the disclosing party, or as required by law, the receiving
        party will not disclose, transmit, or otherwise disseminate to a third
        party any Confidential Information of the disclosing party. The
        receiving party will use the same care and discretion with respect to
        the Confidential Information received from the disclosing party as it
        uses with its own similar information, but in no event less than a
        reasonable degree of care. The receiving party may disclose the
        disclosing party’s Confidential Information to its employees,
        Affiliates, consultants, subcontractors, agents, or advisors
        (“Representatives”) who have a strict need to access the Confidential
        Information for the purpose of performing under this Agreement and only
        to those who are obligated to maintain the confidentiality of such
        Confidential Information upon terms at least as protective as those
        contained in this Agreement. Either party may disclose the terms of this
        Agreement to potential parties to a bona fide fundraising, acquisition,
        or similar transaction solely for purposes of the proposed transaction,
        provided that any such potential party is subject to written
        non-disclosure obligations and limitations on use no less protective
        than those set forth herein.
        <br />
        <br />
        5.3 Equitable Relief. The receiving party acknowledges that the remedy
        at law for breach of this Section 5 may be inadequate and that, in
        addition to any other remedy the disclosing party may have, it shall be
        entitled to seek equitable relief, including, without limitation, an
        injunction or injunctions (without the requirement of posting a bond,
        other security or any similar requirement or proving any actual
        damages), to prevent breaches or threatened breaches of this Section 5
        by the receiving party or any of its Representatives and to enforce the
        terms and provisions of this Section 5 in addition to any other remedy
        to which the disclosing party is entitled at law or in equity.
        <br />
        <br />
        5.4 Compelled Disclosure. The receiving party may access and disclose
        Confidential Information of the disclosing party if legally required to
        do so in connection with any legal or regulatory proceeding; provided,
        however, that in such event the receiving party will, if lawfully
        permitted to do so, notify the disclosing party within a reasonable time
        prior to such access or disclosure so as to allow the disclosing party
        an opportunity to seek appropriate protective measures. If the receiving
        party is compelled by law to access or disclose the disclosing party’s
        Confidential Information as part of a civil proceeding to which the
        disclosing party is a party, the disclosing party will reimburse the
        receiving party for the reasonable costs of compiling and providing
        secure access to such Confidential Information. Receiving party will
        furnish only that portion of the Confidential Information that is
        legally required to be disclosed, and any Confidential Information so
        disclosed shall maintain its confidentiality protection for all purposes
        other than such legally compelled disclosure.
        <br />
        <br />
        5.5 Sensitive/Personal Information. Customer agrees that it shall not
        use the Service to send or store personal information subject to special
        regulatory or contractual handling requirements (e.g., Payment Card
        Industry Data Security Standards, the Gramm-Leach-Bliley Act, the Health
        Insurance Portability and Accountability Act, and any similar data
        protection laws) including without limitation: credit card information,
        credit card numbers and magnetic stripe information, social security
        numbers, driver’s license numbers, passport numbers, government issued
        identification numbers, health-related information, biometric data,
        financial account information, personally identifiable information
        collected from children under the age of 16 or from online services
        directed toward children, and real time geo-location data which can
        identify an individual, or information deemed “sensitive” under
        applicable law (such as racial or ethnic origin, political opinions, or
        religious or philosophical beliefs).
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        6 Intellectual Property Rights.
        <br />
        <br />
        6.1 By Customer. Customer owns all right, title, and interest in and to
        Customer Confidential Information and Customer Data, including all
        related Intellectual Property Rights. Customer grants Agency Minder and
        its authorized third party service providers a worldwide, non-exclusive
        license to host, copy, access, process, transmit, and display Customer
        Data: (a) to maintain, provide, and improve the Service and perform
        under this Agreement; (b) to prevent or address technical or security
        issues and resolve support requests; (c) to investigate in good faith an
        allegation that an End User is in violation of this Agreement or the
        Agency Minder{" "}
        <a href="https://asana.com/es/terms#terms-of-service" target="_blank">
          User Terms of Service
        </a>
        ; or (d) at Customer’s direction or request or as permitted in writing
        by Customer.
        <br />
        <br />
        6.2 By Agency Minder. Agency Minder owns and will continue to own all
        right, title, and interest, including all related Intellectual Property
        Rights, in and to its Confidential Information, Results, and the
        Service, including any enhancements, customizations, or modifications
        thereto. Where Customer purchases Professional Services hereunder,
        Agency Minder grants to Customer a non-sublicensable, non-exclusive
        license to use any reports and other materials developed by Agency
        Minder as a result of the Professional Services (“Results”) solely in
        conjunction with Customer’s authorized use of the Service and in
        accordance with this Agreement.
        <br />
        <br />
        6.3 Suggestions. Agency Minder welcomes feedback from its customers
        about the Service and Professional Services. If Customer (including any
        End User) provides Agency Minder with any feedback or suggestions
        regarding the Service or Professional Services (“Feedback”), Agency
        Minder may use, disclose, reproduce, sublicense, or otherwise distribute
        and exploit the Feedback without restriction or any obligation to
        Customer or any End User provided that Agency Minder shall not identify
        Customer or any End User as the source of such Feedback.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        7 Indemnification.
        <br />
        <br />
        7.1 By Customer. Customer shall defend Agency Minder, its Affiliates,
        and their employees, officers, and directors (together, the “Agency
        Minder Indemnified Parties”) from and against third party claims,
        actions, and demands arising from allegations that Customer Data,
        unauthorized use of the Service by Customer or its End Users, or Agency
        Minder’s processing of data pursuant to Customer’s instructions
        infringes a third party’s Intellectual Property Right or privacy right
        (each, a “Claim Against Agency Minder”), and Customer shall indemnify
        and hold the Agency Minder Indemnified Parties harmless against any
        damages, reasonable attorneys’ fees, and costs finally awarded against
        Agency Minder Indemnified Parties as a result of, or for any amounts
        paid by the Agency Minder Indemnified Parties under a Customer-approved
        settlement of, a Claim Against Agency Minder.
        <br />
        <br />
        7.2 By Agency Minder. Agency Minder shall defend Customer, its
        Affiliates, and their employees, officers, and directors (together the
        “Customer Indemnified Parties”) from and against third party claims,
        actions, and demands alleging that Customer’s authorized use of the
        Service infringes or misappropriates any copyright, trade secret, U.S.
        patent, or trademark right of that third party (each, a “Claim Against
        Customer”), and Agency Minder shall indemnify and hold the Customer
        Indemnified Parties harmless against any damages, reasonable attorneys’
        fees, and costs finally awarded against Customer Indemnified Parties as
        a result of, or for any amounts paid by the Customer Indemnified Parties
        under an Agency Minder-approved settlement of, a Claim Against Customer;
        provided, however, in no event will Agency Minder have any obligations
        or liability under this Section 7.2 to the extent a Claim Against
        Customer arises from: (a) Customer or any End User’s use of the Service
        other than as permitted under this Agreement; or (b) use of the Service
        in a modified form or in combination with products, services, content,
        or data not furnished to Customer by Agency Minder.
        <br />
        <br />
        7.3 Potential Infringement. If the Service becomes, or in Agency
        Minder’s reasonable judgment is likely to become, the subject of a claim
        of infringement, then Agency Minder may in its sole discretion: (a)
        obtain the right, at Agency Minder’s expense, for Customer to continue
        using the Service; (b) provide a non-infringing functionally equivalent
        replacement; or (c) modify the Service so that it is no longer
        infringing. If Agency Minder, in its sole and reasonable judgment,
        determines that none of the above options are commercially reasonable,
        then Agency Minder may suspend or terminate Customer’s use of the
        Service, in which case Agency Minder’s sole liability (in addition to
        its obligations under Section 7.2) shall be to provide Customer with a
        prorated refund of any prepaid, unused fees applicable to the remaining
        portion of the Subscription Term. Sections 7.2 and 7.3 state Agency
        Minder’s sole liability and the Customer Indemnified Parties’ exclusive
        remedy for infringement claims.
        <br />
        <br />
        7.4 Indemnification Process. The party seeking indemnification shall
        provide prompt notice to the indemnifying party concerning the existence
        of an indemnifiable claim and shall promptly provide the indemnifying
        party with all information and assistance reasonably requested and
        otherwise cooperate fully with the indemnifying party in defending the
        claim. Failure to give prompt notice shall not constitute a waiver of a
        party’s right to indemnification and shall affect the indemnifying
        party’s obligations under this Agreement only to the extent that the
        indemnifying party’s rights are materially prejudiced by such failure or
        delay. The indemnifying party shall have full control and authority over
        the defense of any claim; provided, however, that any settlement
        requiring the party seeking indemnification to admit liability or make
        any financial payment shall require such party’s prior written consent,
        not to be unreasonably withheld or delayed.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        8 Liability.
        <br />
        <br />
        8.1 Limitation of Liability. EXCEPT FOR A PARTY’S INDEMNIFICATION
        OBLIGATIONS UNDER SECTION 7 (“INDEMNIFICATION”), IN NO EVENT SHALL
        EITHER PARTY’S OR ITS AFFILIATES’ AGGREGATE LIABILITY ARISING OUT OF OR
        RELATED TO THIS AGREEMENT (WHETHER IN CONTRACT, TORT, NEGLIGENCE OR
        UNDER ANY OTHER THEORY OF LIABILITY) EXCEED THE TOTAL AMOUNT PAID OR
        PAYABLE BY CUSTOMER HEREUNDER IN THE 12 MONTHS IMMEDIATELY PRECEDING THE
        FIRST EVENT GIVING RISE TO LIABILITY.
        <br />
        <br />
        8.2 Exclusion of Consequential and Related Damages. IN NO EVENT SHALL
        EITHER PARTY OR ITS AFFILIATES HAVE ANY LIABILITY TO THE OTHER PARTY FOR
        ANY LOST PROFITS, REVENUES, OR LOSS OF USE, OR FOR ANY INDIRECT,
        SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES
        HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF
        LIABILITY AND WHETHER OR NOT A PARTY HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        THE FOREGOING DISCLAIMER SHALL NOT APPLY TO THE EXTENT PROHIBITED BY
        APPLICABLE LAW.
        <br />
        <br />
        8.3 The provisions of this Section 8 allocate the risks under this
        Agreement between the parties, and the parties have relied on these
        limitations in determining whether to enter into this Agreement.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        9 Export Compliance. The Service may be subject to export laws and
        regulations of the United States and other jurisdictions. Customer
        represents that neither it nor any of its End Users are named on any
        U.S. government denied-party list. Customer shall not permit any End
        User to access or use any Service in a U.S.-embargoed country or region
        or in violation of any U.S. export law or regulation. Customer and its
        End Users shall not use the Service to export, re-export, transfer, or
        make available, whether directly or indirectly, any regulated item or
        information to anyone outside the U.S. in connection with this Agreement
        without first complying with all export control laws and regulations
        that may be imposed by the U.S. Government and any country or
        organization of nations within whose jurisdiction Customer operates or
        does business.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        10 Use Outside the United States of America. The Service is controlled
        and operated by Agency Minder from its offices in the United States of
        America. Except as explicitly set forth herein, Agency Minder makes no
        representations that the Services are appropriate for use in other
        jurisdictions. Those who access or use the Service from other
        jurisdictions do so at their own risk and are responsible for compliance
        with local laws. Agency Minder may offer services in other jurisdictions
        that are subject to different terms and conditions. In such instances,
        the terms and conditions governing those non-U.S. services shall take
        precedence over any conflicting provisions in this Agreement.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        11 Miscellaneous.
        <br />
        <br />
        11.1 Governing Law; Venue. This Agreement and any disputes arising under
        it will be governed by the laws of the State of Texas without regard to
        its conflict of laws provisions, and each party consents to the personal
        jurisdiction and venue of the state or federal courts located in Austin,
        Texas. The application of the United Nations Convention on Contracts for
        the International Sale of Goods is expressly excluded.
        <br />
        <br />
        11.2 Informal Dispute Resolution and Arbitration. The parties agree that
        most disputes can be resolved without resort to litigation. The parties
        agree to use their best efforts to settle any dispute directly through
        consultation with each other before initiating a lawsuit or arbitration.
        If, after good faith negotiations the parties are unable to resolve the
        dispute, the parties agree that any and all disputes arising out of or
        in any way relating to this Agreement, including without limitation its
        existence, validity or termination, shall be resolved according to Texas
        law and exclusively by binding arbitration before a single arbitrator
        with the Judicial Arbitration and Mediation Service (JAMS) and pursuant
        to the then existing arbitration rules at JAMS. If the parties cannot
        agree upon selection of an arbitrator, then JAMS shall appoint an
        arbitrator experienced in the enterprise software industry. The place of
        the arbitration will be Austin, Texas unless otherwise agreed upon by
        the parties. The arbitration will be conducted in English. The
        arbitrator shall provide detailed written findings of fact and
        conclusions of law in support of any award. Judgment upon any such award
        may be enforced in any court of competent jurisdiction. The parties
        further agree that the arbitration shall be conducted in their
        individual capacities only and not as a class action or other
        representative action, and the parties expressly waive their right to
        file a class action or seek relief on a class basis. If any court or
        arbitrator determines that the class action waiver set forth herein is
        void or unenforceable for any reason or that an arbitration can proceed
        on a class basis, then the portions of Section 11.3 mandating
        arbitration shall be deemed null and void in its entirety and the
        parties shall be deemed to have not agreed to arbitrate disputes.
        Customer may opt out and not be bound by the arbitration and class
        action waiver provisions by sending written notice to Agency Minder. The
        notice must be sent within thirty (30) days of the Effective Date of
        this Agreement between Customer and Agency Minder. If Customer opts out
        of arbitration, Agency Minder also will not be bound to arbitrate.
        Notwithstanding the foregoing, either party shall be entitled to seek
        injunctive relief as set forth in Section 5.3 (“Equitable Relief”) above
        and to stop unauthorized use of the Service or infringement of
        Intellectual Property Rights. Disputes, claims, or controversies
        concerning either party’s Intellectual Property Rights or claims of
        piracy or unauthorized use of the Service shall not be subject to
        arbitration. The parties further agree that the prevailing party in any
        action or proceeding to enforce any right or provisions under this
        Agreement, including any arbitration or court proceedings, will be
        entitled to recover its reasonable costs and attorneys’ fees.
        <br />
        <br />
        11.3 Notice. Agency Minder may give general notices related to the
        Service that are applicable to all customers by email, text, in-app
        notifications, or by posting them on the Agency Minder website or
        through the Service and such electronic notices shall be deemed to
        satisfy any legal requirement that such notices be made in writing.
        Other notices must be sent via email, first class, airmail, or overnight
        courier to the addresses of the parties provided herein or via an Order
        Form and are deemed given when received. Notices to Agency Minder must
        be sent to Agency Minder Legal at admin@agencyminder.com with a copy to
        Agency Minder, LLC, 2018 Three Forks, San Antonio, TX 78258, Attn: Legal
        Department.
        <br />
        <br />
        11.4 Publicity. Agency Minder may include Customer’s name and logo in
        Agency Minder’s online customer list and in print and electronic
        marketing materials.
        <br />
        <br />
        11.5 Beta Access. Customer may be invited to participate in review and
        testing of pre-release versions of new and beneficial tools and Service
        enhancements which may be identified to Customer as “alpha,” “beta,”
        “preview,” “pre-release,” “early access,” or “evaluation” product or
        services (collectively, the “Beta Tests” and such pre-release
        functionality, the “Beta Product”). Customer acknowledges and
        understands that its participation in Beta Tests is not required and is
        at Customer’s own risk, and that Beta Products are made available on an
        “as is” basis without warranties (express or implied) of any kind, and
        may be discontinued or modified at any time. Beta Products are for
        evaluation and testing purposes, not for production use, not supported,
        not subject to availability or security obligations, and may be subject
        to additional terms. Agency Minder shall have no liability for any harm
        or damage arising out of or in connection with Beta Products. The Beta
        Products, including without limitation Customer’s assessment of any Beta
        Product, are Confidential Information of Agency Minder.
        <br />
        <br />
        11.6 Relationship of the Parties. The parties are and shall be
        independent contractors with respect to all services provided under this
        Agreement. This Agreement does not create a partnership, franchise,
        joint venture, agency, fiduciary, or employment relationship between the
        parties. There are no third-party beneficiaries to this Agreement.
        Without limiting this Section, a Customer’s End Users are not
        third-party beneficiaries to Customer’s rights under this Agreement.
        <br />
        <br />
        11.7 Force Majeure. Agency Minder shall not be liable for delayed or
        inadequate performance of its obligations hereunder to the extent caused
        by a condition that is beyond Agency Minder’s reasonable control,
        including but not limited to natural disaster, civil disturbance, acts
        of terrorism or war, labor conditions, governmental actions,
        interruption or failure of the Internet or any utility service, failures
        in third-party hosting services, and denial of service attacks (each a
        “Force Majeure Event”). Agency Minder shall be relieved from its
        obligations (or part thereof) as long as the Force Majeure Event lasts
        and hinders the performance of said obligations (or part thereof).
        Agency Minder shall promptly notify Customer and make reasonable efforts
        to mitigate the effects of the Force Majeure Event.
        <br />
        <br />
        11.8 Severability; No Waiver. In the event that any provision of this
        Agreement is found to be invalid or unenforceable pursuant to any
        judicial decree or decision, such provision shall be limited or
        eliminated to the minimum extent necessary so that this Agreement shall
        otherwise remain in full force and effect and remain enforceable between
        the parties. No waiver of any term of this Agreement shall be deemed a
        further or continuing waiver of such term or any other term, and a
        party’s failure to assert any right or provision under this Agreement
        shall not constitute a waiver of such right or provision.
        <br />
        <br />
        11.9 Assignment. Neither this Agreement nor any of the rights and
        licenses granted under this Agreement may be transferred or assigned by
        either party without the other party’s express written consent (not to
        be unreasonably withheld or delayed); provided, however, that either
        party may assign this Agreement and all Order Forms under this Agreement
        upon written notice without the other party’s consent to an Affiliate or
        to its successor in interest in connection with a merger, acquisition,
        corporate reorganization, or sale of all or substantially all of its
        assets not involving a direct competitor of the non-assigning party. Any
        other attempt to transfer or assign this Agreement will be null and
        void. Subject to the foregoing, this Agreement shall bind and inure to
        the benefit of the parties, their respective successors, and permitted
        assigns.
        <br />
        <br />
        11.10 Government Agencies. If Customer is a Government agency utilizing
        Agency Minder’s Service in an official capacity, Customer’s use of the
        Service shall be subject to this Subscriber Agreement and the{" "}
        <a href="https://asana.com/es/terms#amendment" target="_blank">
          Amendment to Agency Minder Subscriber Agreement Applicable to U.S.
          Government Customers.
        </a>
        <br />
        <br />
        11.11 Modifications. Agency Minder may revise this Agreement from time
        to time by posting the modified version on its website. If, in Agency
        Minder’s sole discretion, the modifications proposed are material,
        Agency Minder shall provide Customer with notice in accordance with
        Section 11.4 at least twenty (20) days prior to the effective date of
        the modifications being made. By continuing to access or use the Service
        after the posted effective date of modifications to this Agreement,
        Customer agrees to be bound by the revised version of the Agreement.
        <br />
        <br />
        11.12 Entire Agreement. This Agreement, including all attachments,
        exhibits, addendums, and any Order Form(s) hereunder, constitutes the
        entire agreement between the parties concerning the subject matter
        hereof and supersedes and replaces any prior or contemporaneous
        representations, understandings and agreements, whether written or oral,
        with respect to its subject matter. The parties are not relying and have
        not relied on any representations or warranties whatsoever regarding the
        subject matter of this agreement, express or implied, except for the
        representations and warranties set forth in this Agreement. To the
        extent of any conflict or inconsistency between the provisions of the
        Agreement and any Order Form, the Agreement shall prevail.
        Notwithstanding any language to the contrary therein, no terms or
        conditions stated in a Customer purchase order, vendor onboarding
        process, web portal, or any other Customer order documentation shall be
        incorporated into or form any part of this Agreement, and all such terms
        or conditions shall be null and void.
        <br />
        <br />
        11.13 Applicability. This Agreement applies to you if: (a) you are a new
        Customer or have become a new Customer on or after July 1, 2020; (b) you
        enter into a Trial of Agency Minder that is subject to this Agreement;
        or (c) you click a button indicating your agreement with the terms of
        this Agreement or enter into an Order Form or similar form referencing
        or otherwise incorporating this Agreement.
      </Text>
      <Text fontSize={7} style={{ lineHeight: "1.5", marginTop: "3rem" }}>
        12 Definitions.
        <br />
        <br />
        12.1 “Affiliate” means any entity that directly or indirectly controls,
        is controlled by, or is under common control with the subject entity.
        “Control,” for purposes of this definition, means either: (a) ownership
        or control of more than 50% of the voting interests of the subject
        entity; or (b) the power to direct or cause the direction of the
        management and policies of an entity, whether through ownership, by
        contract, or otherwise.
        <br />
        <br />
        12.2 “Customer Data” means information submitted by an End User through
        the Service, including all associated messages, attachments, files,
        tasks, project names, team names, channels, conversations, ratings,
        feedback, and other similar content.
        <br />
        <br />
        12.3 “Documentation” means Agency Minder’s then-current online user
        guides, as updated from time to time, and made accessible from within
        the “Help” feature of the Service.
        <br />
        <br />
        12.4 “End User” means an individual who is authorized by Customer to use
        the Service under Customer’s account. End Users may include, without
        limitation, Customer’s or its Affiliates’ employees, consultants,
        contractors and agents.
        <br />
        <br />
        12.5 “Intellectual Property Rights” means unpatented inventions, patent
        applications, patents, design rights, copyrights, trademarks, service
        marks, trade names, domain name rights, mask work rights, know-how and
        other trade secret rights, and all other intellectual property rights,
        derivatives thereof, and forms of protection of a similar nature
        anywhere in the world.
        <br />
        <br />
        12.6 “Improvements” means new features, functionality, enhancements,
        upgrades, error corrections and bug fixes to the Service made generally
        available by Agency Minder at no additional charge.
        <br />
        <br />
        12.7 “Order Form” means an ordering document or an online order set
        forth in the Service interface entered into between Customer and Agency
        Minder (or Affiliates of either party) specifying the Service or
        Professional Services (if any) to be provided under this Agreement.
        <br />
        <br />
        12.8 “Professional Services” means the customer success services
        provided by Agency Minder, as specified in the applicable Order Form.
        <br />
        <br />
        12.9 “Service” means Agency Minder’s software for secure collection,
        tracking, and reporting of individual and aggregate employee,
        contractor, and third-party vendor performance data used to evaluate the
        success level of contract performance and fulfillment, including any
        Improvements, as described in the applicable Order Form.
        <br />
        <br />
        12.10 “Subscription” means the access to the Service purchased by
        Customer on a per End User basis.
        <br />
        <br />
        12.11 “Subscription Term” means the period identified in the Order Form
        during which Customer’s End Users are permitted to use or access the
        Service pursuant to the terms set forth in this Agreement.
      </Text>
    </div>
  );
}

export default SubscriberAgreement;
