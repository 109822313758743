import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { isPast, format, addHours, roundToNearestMinutes } from 'date-fns';
import config from '../../../config/config';
import Text, { H3 } from '../../uikit/text/Text';
import TitleBlock from '../../shared/TitleBlock';
import Box from '../../uikit/box/Box';
import { Table } from '../../uikit/table';
import RatingDotLabel from '../../shared/rating/RatingDotLabel';
import Icon from '../../uikit/icon/Icon';
import {
  FiChevronUp,
  FiChevronDown,
  FiChevronLeft,
  FiPlus,
  FiEdit,
  FiMinus,
} from 'react-icons/fi';
import _groupBy from 'lodash/fp/groupBy';
import { getRatingLabel } from '../../../lib/utils';
import AverageLabel from '../../shared/average/AverageLabel';
const Reviewer = ({ review }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box borderBottom="1px solid" borderColor="grays.1" py={2}>
      <Box display="flex" alignItems="center">
        <Text fontFamily="montserrat" mt="2px" flex="0 1 50%" p={2}>
          {review.first_name} {review.last_name}
        </Text>
        <RatingDotLabel
          rating={review.rating}
          showRatingText={false}
          label={getRatingLabel(review.rating)}
          p={2}
          flex="1 0 50%"
        />
        <Icon
          p={2}
          color="primarys.0"
          icon={open ? FiMinus : FiPlus}
          onClick={() => {
            setOpen((s) => !s);
          }}
        />
      </Box>

      {open && (
        <Box border="1px solid" borderColor="grays.1" p={3} m={2}>
          <Text fontWeight="bold" mb={3}>
            Feedback:
          </Text>
          <Text fontFamily="montserrat">{review.feedback}</Text>
        </Box>
      )}
    </Box>
  );
};

const Attendee = ({ attendee }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      boxShadow={2}
      my={5}
      p={3}
      minWidth="280px"
      minHeight="90px"
      position={'relative'}
      flex={1}
    >
      <Box display="flex" alignItems="center" height={'100%'}>
        <Box flex="0 1 50%" p={2}>
          <H3
            mb={0}
            mt="2px"
            className="name"
            style={{
              maxHeight: '4rem',
              overflow: 'hidden',
              display: '-webkit-box',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {attendee.first_name ? attendee.first_name : ''} {attendee.last_name ? attendee.last_name : attendee.email_address}
          </H3>
          <Text fontFamily="montserrat" fontSize={1}>
            {attendee.job_title ? attendee.job_title : ''}
          </Text>
        </Box>
        <RatingDotLabel
          rating={attendee.average_rating}
          label="Average Rating"
          p={2}
          flex="1 0 50%"
        />
        <Icon
          p={2}
          color="primarys.0"
          icon={open ? FiChevronUp : FiChevronDown}
          onClick={() => {
            setOpen((s) => !s);
          }}
        />
      </Box>
      {open ? (
        <Box p={2}>
          {attendee.reviews.length > 0 ? (
            <Fragment>
              <Text
                p={2}
                fontFamily="montserrat"
                fontSize="9px"
                textTransform="uppercase"
                fontWeight="bold"
                color="secondarys.0"
                borderBottom="1px solid"
                borderColor="grays.1"
              >
                Reviewed By
              </Text>
              {attendee.reviews.map((a, i) => (
                <Reviewer key={`${a.id}_${i}`} review={a} />
              ))}
            </Fragment>
          ) : (
            <Text color="accents.0" p={2}>
              No Reviews
            </Text>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

const MeetingDetail = ({ projectStore, match }) => {
  const [meeting, setMeeting] = useState(null);
  const [attendees, setAttendees] = useState(null);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    fetch(`${config.API_URL}/meeting/${match.params.meetingId}`)
      .then((resp) => resp.json())
      .then((resp) => {
        setMeeting(resp.data.meeting);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (meeting) {
      const attendees = meeting.attendees.filter(
        (currentValue, currentIndex, array) => {
          return (
            array.findIndex(
              (a) => JSON.stringify(a) === JSON.stringify(currentValue)
            ) === currentIndex
          );
        }
      );
      setAttendees(_groupBy((x) => x.company_name, attendees));
      setContract(projectStore.meetingStore.meetings[0].contract_name);
    }
  }, [meeting]);
  console.log(attendees);
  if (!meeting) {
    return null;
  }

  return (
    <Box
      py={5}
      style={{
        minWidth: '500px',
      }}
    >
      <Box mb={5}>
        <Link
          to={`/project/${projectStore.project.id}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
          }}
        >
          <Icon
            icon={FiChevronLeft}
            style={{ transform: 'translateY(-1px)' }}
          />
          <Text>Back</Text>
        </Link>
      </Box>
      <H3 mb={6}>Meeting - {meeting.name}</H3>
      <Table>
        <thead>
          <tr>
            <th>
              <Text fontFamily="montserrat">Date</Text>
            </th>
            <th>
              <Text fontFamily="montserrat">Contract</Text>
            </th>
            <th>
              <Text fontFamily="montserrat">Project</Text>
            </th>
            <th>
              <Text fontFamily="montserrat">Meeting</Text>
            </th>
            <th>
              <Text fontFamily="montserrat">Progress</Text>
            </th>
            <th>
              <Text fontFamily="montserrat">Average Rating</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="date">
              <Text fontSize={1}>
                {format(new Date(meeting.start_date), 'MM.dd.yy')}
              </Text>
            </td>
            <td className="contract">
              <Text fontSize={1}>{contract}</Text>
            </td>
            <td className="project">
              <Text fontSize={1}>{projectStore.project.name}</Text>
            </td>
            <td className="meeting">
              <Text fontSize={1} fontWeight="bold">
                {meeting.name}
              </Text>
            </td>
            <td className="progress">
              <AverageLabel average={meeting.progress} />
            </td>
            <td>
              <RatingDotLabel rating={meeting.average_rating} />
            </td>
            {/*
            <td style={{ padding: 0 }} width="40px">
              {isPast(new Date(meeting.meeting_end_date)) ? (
                <Icon
                  color="primarys.0"
                  icon={FiChevronRight}
                  onClick={() => {
                    onRowClick && onRowClick(meeting);
                  }}
                />
              ) : (
                <Icon
                  color="primarys.0"
                  icon={FiEdit}
                  onClick={() => {
                    projectStore.meetingStore.setCurrentMeeting(meeting);
                    setStatus(STATUS.UPDATE);
                  }}
                />
              )}
            </td>
            */}
          </tr>
        </tbody>
      </Table>
      <Box flex={1} /*style={{ overflow: 'auto', padding: '0px 4px' }}*/>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ gap: '8px' }}
        >
          {attendees &&
            Object.keys(attendees).map((key, i) => {
              return (
                <Box
                  flex="0 1 48%"
                  key={i}
                  // display="flex"
                  // flexDirection="column"
                >
                  <TitleBlock>{key} Attendee Reviews</TitleBlock>
                  {attendees[key].length > 0 &&
                    attendees[key].map((attendee) => (
                      <Attendee key={attendee.id} attendee={attendee} />
                    ))}
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default inject('projectStore')(observer(MeetingDetail));
