import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import { submitNonAttendance, getMeetingById } from "../MeetingService";

function NonAttendance({ authStore, match, history, meetingId }) {

  const [state, setState] = useState();

  useEffect(() => {
    if(!authStore.user && !authStore.public_user && !match.params.skip) {
      history.push({ pathname:`/login/${match.params.meetingId}`, redirectTo: 'meeting/did-not-attend' });
      return;
    }
    const id = meetingId || match.params.meetingId;
    if (id) {

      getMeetingById(id).then(res => {
        let user_id;
        if (authStore.public_user) {
          user_id = authStore.public_user.id;
        }
        const data = { user_id };
        submitNonAttendance(id, data)
          .then(r => {
            setState("Your response has been sent.");
          })
          .catch(e => {
            setState("We couldn't save your response.");
          });
      })
      .catch((e) => {
        setState(e.message);
      });
    } else {
      return history.push("/");
    }
  }, []);

  return (
    <>
      <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="416px" margin="auto">
        { state && (
          <Text color="white" fontSize={6} textAlign="center" fontWeight="bold">
            { state }
          </Text>
        )}
      </Box>
    </>
  );
}

export default inject("authStore")(observer(NonAttendance));
