import React from "react";
import { observer, inject } from "mobx-react";
import styled, { css } from "styled-components";
import { theme, ifProp } from "styled-tools";
import { NavLink, Link } from "react-router-dom";
import {
  FiHome,
  FiFileText,
  FiUser,
  FiChevronLeft,
  FiChevronRight,
  FiLogOut,
  FiArchive
} from "react-icons/fi";

import Box from "../../uikit/box/Box";
import Logo from "../../uikit/logo/Logo";
import Text from "../../uikit/text/Text";
import Icon from "../../uikit/icon/Icon";

import useLocalStorage from "../../../hooks/useLocalStorage";

const openMenuWidth = "228px";
const closedMenuWidth = "76px";

const DesktopContainer = styled.section`
  background: ${theme("colors.white")};
  height: 100%;
  position: relative;
  transition: width 300ms cubic-bezier(0, 0, 0, 1);
  will-change: width;
`;

const StyledNavItem = styled(Box)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: ${theme("space.3")}px 30px;
  span {
    user-select: none;
  }
  span,
  path,
  polyline,
  circle,
  line {
    color: ${(p) =>
      p.color ? theme(`colors.${p.color}`) : theme("colors.primarys.0")};
  }

  ${(p) =>
    (p.to || p.onClick) &&
    css`
      cursor: pointer;
      &.active {
        background: ${theme("colors.primarys.0")};
        span,
        path,
        polyline,
        circle,
        line {
          color: ${theme("colors.white")};
        }
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    `};
`;

const ToggleButton = styled.div`
  width: 24px;
  height: 24px;
  background: ${theme("colors.accents.1")};
  border-radius: 50%;
  box-shadow: ${theme("shadows.2")};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`;

const MenuInner = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  padding-top: 11px;

  #agencyminder,
  .subNavItem,
  a span,
  div span {
    transition: opacity 300ms cubic-bezier(0, 0, 0, 1);
    opacity: ${ifProp({ isOpen: true }, 1, 0)};
  }
`;

function NavItem({ icon, image, text, to, ...rest }) {
  let props = to
    ? {
        el: NavLink,
        to: to,
        exact: true,
      }
    : {
        el: "div",
      };
  return (
    <StyledNavItem {...props} {...rest}>
      <Box width="18px" mr="18px">
        {image ? image : icon && <Icon icon={icon} />}
      </Box>
      <Text mt={1} flex="1">
        {text}
      </Text>
    </StyledNavItem>
  );
}

function Desktop({ authStore, contractStore }) {
  const [open, setOpen] = useLocalStorage("_agm_desktop_menu", true);
  const contracts = contractStore.unarchivedContracts;
  const contractsArchived = contractStore.archivedContracts;

  return (
    <DesktopContainer style={{ width: open ? openMenuWidth : closedMenuWidth }}>
      <ToggleButton
        onClick={() => setOpen((s) => !s)}
        style={{ right: -12, top: 70 }}
      >
        {open ? (
          <Icon icon={FiChevronLeft} color="primarys.0" ml="-2px" />
        ) : (
          <Icon icon={FiChevronRight} color="primarys.0" ml="2px" />
        )}
      </ToggleButton>

      <MenuInner isOpen={open}>
        <Box px={5} width={openMenuWidth}>
          <Logo width="180px" />
        </Box>

        <Box el="nav" flex="1" overflow="auto" width={openMenuWidth}>
          <NavItem icon={FiHome} text="Dashboard" to="/" />
          <NavItem icon={FiFileText} text="Contracts" to="/contract" />
          {contracts.length > 0 ? (
            contracts.map((c) => (
              <NavItem
                className="subNavItem"
                key={c.id}
                text={c.name}
                to={`/contract/${c.id}`}
              />
            ))
          ) : (
            <NavItem
              className="subNavItem"
              color="grays.1"
              text="No Contracts"
            />
          )}
          {contractsArchived.length > 0 ? (
            <NavItem
              className="subNavItem"
              text="Archived"
              icon={FiArchive}
              to="/contract/archive"
            />
          ) : (
            <NavItem
              className="subNavItem"
              color="grays.1"
              text="Archived"
              icon={FiArchive}
            />
          )}
        </Box>

        <Box py={5} width={openMenuWidth}>
          {authStore.user && authStore.user.profile_image ? (
            <NavItem
              image={
                <Box
                  width={30}
                  height={30}
                  borderRadius="50%"
                  style={{
                    backgroundImage: `url(${authStore.user.profile_image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    overflow: "hidden",
                    transform: "translateX(-7px)",
                  }}
                />
              }
              text={authStore.user.first_name}
              to="/profile"
            />
          ) : (
            <NavItem
              icon={FiUser}
              text={authStore.user.first_name}
              to="/profile"
            />
          )}
          <NavItem
            icon={FiLogOut}
            text="Logout"
            onClick={(e) => authStore.logout()}
          />
        </Box>
      </MenuInner>
    </DesktopContainer>
  );
}

export default inject("authStore", "contractStore")(observer(Desktop));
