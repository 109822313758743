import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toJS } from 'mobx';
import { theme } from 'styled-tools';
import { Provider, observer, inject } from 'mobx-react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import Modal from '../uikit/modal/Modal';

import Menu from './menu/Menu';
import Dashboard from '../dashboard/Dashboard';
import ProfilePage from '../profile/ProfilePage';
import ProjectStore from '../project/ProjectStore';
import ProjectForm from '../project/form/ProjectForm';
import ContractStore from '../contract/ContractStore';
import ContractForm from '../contract/form/ContractForm';
import ProjectDetailPage from '../project/detail/ProjectDetailPage';
import ArchivedProjectsPage from '../project/archive/ArchivedProjectsPage';
import ContractDetailPage from '../contract/detail/ContractDetailPage';
import ContractArchivePage from '../contract/archive/ContractArchivePage';

const GlobalStyle = createGlobalStyle`
    html, body{
        height: 100%;
        overflow:hidden;
    }
    body{
        background: ${theme('colors.primarys.1')};
    }
    @media (min-width: ${theme('breakpoints.1')}) {
      #root{
          height: 100%;
          overflow:hidden;
      }
    }
`;

const Grid = styled.div`
  @media (min-width: ${theme('breakpoints.1')}) {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
  }
`;

function App({ uiStore, authStore, history }) {
  const [contractStore] = useState(() => new ContractStore());
  const [projectStore] = useState(() => new ProjectStore());
  const currentPath = useLocation();
  let currentContract = contractStore.currentContract;
  if (currentPath.pathname === '/') {
    currentContract = null;
  }

  return (
    <Provider contractStore={contractStore} projectStore={projectStore}>
      <Grid>
        <GlobalStyle />
        <Menu />
        <Switch>
          <Route path="/project/:projectId" component={ProjectDetailPage} />
          <Route
            exact
            path="/contract/archive"
            component={ContractArchivePage}
          />
          <Route
            exact
            path="/contract/:contractId"
            component={ContractDetailPage}
          />
          <Route
            exact
            path="/contract/:contractId/archivedProjects"
            component={ArchivedProjectsPage}
          />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/profile/:callback" component={ProfilePage} />
          <Route exact path="/" component={Dashboard} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>

        <Modal
          title="New Contract"
          isOpen={uiStore.contractModalOpen}
          onRequestClose={() => {
            uiStore.contractModalOpen = false;
          }}
        >
          <ContractForm
            onSubmit={(data) => {
              return contractStore.createContract(data).then((contract) => {
                uiStore.contractModalOpen = false;
                history.push(`/contract/${contract.id}`);
              });
            }}
            onCancel={() => {
              uiStore.contractModalOpen = false;
            }}
          />
        </Modal>

        <Modal
          title="New Project"
          isOpen={uiStore.projectModalOpen}
          onRequestClose={() => {
            uiStore.projectModalOpen = false;
          }}
        >
          <ProjectForm
            contractList={toJS(contractStore.contracts)}
            contractName={toJS(currentContract)}
            onSubmit={(data) => {
              return projectStore.createProject(data).then((project) => {
                contractStore.fetchContracts();
                uiStore.projectModalOpen = false;
                history.push(`/project/${project.id}`);
              });
            }}
            onCancel={() => {
              uiStore.projectModalOpen = false;
            }}
          />
        </Modal>
      </Grid>
    </Provider>
  );
}

export default withRouter(inject('uiStore', 'authStore')(observer(App)));
