import React from "react";
import { render } from "react-dom";
import AppContainer from "./components/app/AppContainer";
import { Provider } from "mobx-react";
import { ThemeProvider } from "styled-components";
import theme from "./components/uikit/themes/defaultTheme";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import UIStore from "./stores/UIStore";
import AuthStore, { AUTH_STATE } from "./stores/AuthStore";
import { createApiInterceptors } from "config/interceptors";
import { when } from "mobx";
import 'mobx-react-lite/batchingForReactDom';

const history = createBrowserHistory();
const uiStore = new UIStore();
const authStore = new AuthStore(window, history);
createApiInterceptors(authStore);

when(
  () => authStore.authState !== AUTH_STATE.INITIAL,
  () => {
    render(
      <ThemeProvider theme={theme}>
        <Provider uiStore={uiStore} authStore={authStore}>
          <Router history={history}>
            <AppContainer />
          </Router>
        </Provider>
      </ThemeProvider>,
      document.getElementById("root")
    );
  }
);
