import React from "react";
import { hasValue } from "./utils";

let FieldContext = React.createContext({});
const { Provider, Consumer } = FieldContext;

class FieldProvider extends React.Component {
  state = {
    focused: false
  };

  handleFocus = evt => {
    if (this.props.readOnly || this.props.disabled) return;
    this.setState({ focused: true });
    this.props.onFocus && this.props.onFocus(evt);
  };

  handleBlur = evt => {
    this.setState({ focused: false });
    this.props.onBlur && this.props.onBlur(evt);
  };

  render() {
    const { focused } = this.state;
    const { value, checked, error, isValid, disabled, readOnly } = this.props;
    const fieldState = {
      checked,
      focused,
      isValid,
      disabled,
      readOnly,
      hasError: Boolean(error),
      hasValue: hasValue(value)
    };

    fieldState.currentState = stateReducer(fieldState);

    return (
      <Provider
        value={{
          fieldState,
          onFocus: this.handleFocus,
          onBlur: this.handleBlur
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export { FieldProvider, Consumer as FieldConsumer, FieldContext };

export function stateReducer(fieldState) {
  if (fieldState.hasError) {
    return "error";
  }
  if (!fieldState.hasError && fieldState.isValid) {
    return "valid";
  }
  if (fieldState.focused || fieldState.checked) {
    return "active";
  }
  if (fieldState.readOnly) {
    return "readOnly";
  }
  if (fieldState.disabled) {
    return "disabled";
  }
  return "initial";
}
