import { useRef, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";

function Portal(props) {
  const container = useRef(document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(container.current);
    return () => {
      if (container.current && container.current.parentNode) {
        container.current.parentNode.removeChild(container.current);
      }
    };
  }, []);

  return ReactDOM.createPortal(props.children, container.current);
}

export default Portal;
