import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Logo from "../../uikit/logo/Logo";
import agmTheme from "../../uikit/themes/darkTheme";
import { ThemeProvider } from "styled-components";
import NonAttendance from "./NonAttendance";

import { createGlobalStyle } from "styled-components";
import { theme } from "styled-tools";

const GlobalStyle = createGlobalStyle`  
    html, body{
      height: 100vh;      
      overflow:hidden;
    }
    #root{
      height: 100vh;      
      overflow: auto;
    }
    body{
      background: ${theme("colors.primarys.1")};
    }      
`;

//using dark theme here to have white TextFields

function NonAttendanceContainer({ authStore, match }) {

  const [user, setUser] = useState(null);

  useEffect(() => {
    if(authStore.user) {
      setUser(authStore.user);
    }
  },[]);

  return (
    <ThemeProvider theme={agmTheme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <GlobalStyle />
        <Box width={1} maxWidth="724px" m={[6, "auto"]}>
          <Box my={[5, 8]} maxWidth={["260px", "300px"]} mx="auto">
            <Logo color="white" style={{ width: "100%" }} />
            <Text
              color="white"
              fontFamily="montserrat"
              fontSize={[4, 7]}
              ml="20%"
            >
              Mind Your Business
            </Text>
          </Box>
          <NonAttendance meetingId={match.params.meetingId} />
          {user && (
            <Box display="flex" flexDirection="column" alignItems="center" maxWidth="416px" margin="auto" p={5}>
                <Text el={Link} to="/" fontFamily="montserrat" fontSize={0} color="white">
                Go to dashboard
                </Text>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default inject("authStore")(observer(NonAttendanceContainer));
