import config from "../config/config";
import { handleResponse, toBlob, APIFetch } from "../lib/HttpClient";

const headers = new Headers({
  "Content-Type": "application/x-www-form-urlencoded"
});

const makeParams = params =>
  Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

/**
 * Login
 *
 * @param {object} values
 *  {
 *   email_address : string,
 *   password : string
 *  }
 *
 * @return Promise({token, user})
 */
export function login(values) {
  return fetch(`${config.API_URL}/auth/login`, {
    headers: headers,
    method: "POST",
    body: makeParams(values)
  })
    .then(handleResponse)
    .then(resp => {
      return Promise.resolve(resp.data);
    });
}

export function updatePassword(value, user_id) {
  return fetch(`${config.API_URL}/user/password/update/${user_id}`, {
      headers: headers,
      method: "PUT",
      body: makeParams(value)
    })
    .then(handleResponse)
    .then(resp => {
      return Promise.resolve(resp.data);
    });
}

/**
 * Logout
 *
 * @param {object} values
 *  {
 *   email_address : string,
 *   password : string
 *  }
 */
export function logout(token) {
  const hs = new Headers(headers);
  hs.set("x-access-token", token);
  return fetch(`${config.API_URL}/auth/logout`, {
    headers: hs
  }).then(handleResponse);
}

/**
 * Register
 *
 * @param {object} values
 *  {
 *   first_name : string,
 *   last_name : string,
 *   email_address : string,
 *   password: string,
 *   job_title : string,
 *   phone: string
 *  }
 */
export function register(values) {
  return fetch(`${config.API_URL}/auth/register`, {
    headers: headers,
    method: "POST",
    body: makeParams(values)
  })
    .then(handleResponse)
    .then(resp => Promise.resolve(resp.data));
}

/**
 * Verify
 *
 * @param {string} token
 */
export function verifyToken(token) {
  const hs = new Headers(headers);
  hs.set("x-access-token", token);
  return fetch(`${config.API_URL}/auth/me`, {
    headers: hs
  }).then(handleResponse);
}

/**
 * Verify Email address via code
 *
 * @param {string} code
 */
export function verifyCode(code) {
  return fetch(`${config.API_URL}/user/verifyCode/${code}`).then(handleResponse);
}

/**
 * Verify Reset Password via code
 *
 * @param {string} code
 */
export function verifyResetPasswordCode(code) {
  return fetch(`${config.API_URL}/user/password/validate/${code}`).then(handleResponse);
}


/**
 * Reset Password
 *
 * @param {object} values
 *  {
 *   email_address : string
 *  }
 *
 * @return Promise({user})
 */
export function resetPassword(values) {
  return fetch(`${config.API_URL}/user/password/forgot`, {
      headers: headers,
      method: "POST",
      body: makeParams(values)
    })
    .then(handleResponse)
    .then(resp => {
      if (resp.success) {
        return Promise.resolve(resp.success);
      }
      return Promise.resolve(null);
    })
    .catch(e => {
      return Promise.resolve(null);
    });
}

export function getSubscriptionPlans() {
  return fetch(`${config.API_URL}/subscription/get`, {
    method: "GET"
  }).then(handleResponse)
    .then(resp => {
      return Promise.resolve(resp.data);
  });
}

export function orderCreate() {
  return fetch(`${config.API_URL}/order/create`, {
    method: "POST"
  }).then(resp => {
    console.log(resp.data);
    return Promise.resolve(resp.data);
  })
}


export function createCustomer(values) {
  return fetch(`${config.API_URL}/subscription/create/customer`, {
    headers: headers,
    method: "POST",
    body: makeParams(values)
  })
    .then(handleResponse)
    .then(resp => Promise.resolve(resp.data));
}

export function createSubscription(values) {
  return fetch(`${config.API_URL}/subscription/create/subscription`, {
    headers: headers,
    method: "POST",
    body: makeParams(values)
  })
    .then(handleResponse)
    .then(resp => Promise.resolve(resp.data));
}