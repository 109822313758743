import { APIFetch, toBlob } from "../../lib/HttpClient";

export function getByContractId(contractId) {
  return APIFetch(`/project/getProjects/${contractId}`).then(resp => {
    if (resp.data.projects) {
      return Promise.resolve(resp.data.projects);
    }
    return Promise.resolve(null);
  });
}

export function getProjectById(projectId) {
  return APIFetch(`/project/${projectId}`).then(resp => {
    if (resp.data.project) {
      return Promise.resolve(resp.data.project);
    }
    return Promise.resolve(null);
  });
}

export function getProjectStatsById(projectId) {
  return APIFetch(`/project/getStats/${projectId}`).then(resp => {
    if (resp.data.stats) {
      return Promise.resolve(resp.data.stats);
    }
    return Promise.resolve(null);
  });
}

export function create(formData) {
  return APIFetch(`/project/create`, {
    method: "POST",
    body: toBlob(formData)
  }).then(resp => {
    if (resp.data.project) {
      return Promise.resolve(resp.data.project);
    }
    return Promise.resolve(null);
  });
}

export function update(id, formData) {
  return APIFetch(`/project/${id}`, {
    method: "PUT",
    body: toBlob(formData)
  }).then(resp => {
    if (resp.data.project) {
      return Promise.resolve(resp.data.project);
    }
    return Promise.resolve(null);
  });
}

export function archive(id) {
  return APIFetch(`/project/archive/${id}`).then(resp => {
    if (resp.success) {
      return Promise.resolve(resp.data.project);
    }
    return Promise.resolve(null);
  });
}

export function unarchive(id) {
  return APIFetch(`/project/unarchive/${id}`).then(resp => {
    if (resp.success) {
      return Promise.resolve(resp.data.project);
    }
    return Promise.resolve(null);
  }).catch(e => Promise.resolve(null));
}

/**
 *  Project Users
 */

export function getMembers(projectId) {
  return APIFetch(`/project/users/get/${projectId}`).then(resp => {
    if (resp.data.users) {
      return Promise.resolve(resp.data.users);
    }
    return Promise.resolve(null);
  });
}

export function addUser(id, formData) {
  return APIFetch(`/project/users/add/${id}`, {
    method: "POST",
    body: toBlob(formData)
  }).then(resp => {
    if (resp.data.project) {
      return Promise.resolve(resp.data.project);
    }
    return Promise.resolve(null);
  });
}

export function updateUserPermissions(id, formData) {
  return APIFetch(`/project/users/change/${id}`, {
    method: "POST",
    body: toBlob(formData)
  }).then(resp => {
    if (resp.data) {
      return Promise.resolve(resp.data);
    }
    return Promise.resolve(null);
  });
}

export function deleteUser(projectId, formData) {
  return APIFetch(`/project/user/remove/${projectId}`, {
    method: "DELETE",
    body: toBlob(formData)
  }).then(resp => {
    if (resp.data) {
      return Promise.resolve(resp.data);
    }
    return Promise.resolve(null);
  }).catch(err => {
    return Promise.resolve(null);
  });
}

export function validateBotPrefix(value) {
  return APIFetch(`/project/search/${value}`).then(resp => {
    if (resp.data) {
      return Promise.resolve(resp.data);
    }
    return Promise.resolve(null);
  }).catch(err => {
    return Promise.resolve(null);
  });
}

