import React, { useEffect } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import Portal from "../portal/Portal";
import { theme } from "styled-tools";
import { MdClose } from "react-icons/md";
import Icon from "../icon/Icon";
import Box from "../box/Box";
import Text from "../text/Text";
// props:
// isOpen: boolean;
// title?: React.ReactNode;
// maxWidth?: string;
// onRequestClose?: Function;

const Panel = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: ${p => (p.maxWidth ? p.maxWidth : "initial")};
  margin: auto;
  padding: ${theme("space.5")}px ${theme("space.7")}px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.25);
  background: white;
  position: relative;
  z-index: 1;
  border-radius: 4px;
`;

const ease = "cubic-bezier(0,0,0,1)";

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: ${theme("space.7")}px ${theme("space.5")}px;
  z-index: 20000001;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &.slide-enter {
    opacity: 0;
  }

  &.slide-enter .oui-modal__panel {
    transform: translateY(120px);
  }

  &.slide-enter-active {
    opacity: 1;
    transition: opacity 350ms ${ease};
  }

  &.slide-enter-active .oui-modal__panel {
    transform: translateY(0);
    transition: transform 300ms ${ease};
  }

  &.slide-exit {
    opacity: 1;
  }

  &.slide-exit .oui-modal__panel {
    transform: translateY(0);
  }

  &.slide-exit-active {
    opacity: 0;
    transition: opacity 200ms ${ease};
  }

  &.slide-exit-active .oui-modal__panel {
    transform: translateY(120px);
    transition: transform 300ms ${ease};
  }
`;

function Modal(props) {
  const { isOpen, title, children, maxWidth = "600px", onRequestClose, backgroundTitle = "grays.0", iconSize = 18 } = props;

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  function handleOverlayClick(e) {
    if (e.target == e.currentTarget && onRequestClose) {
      onRequestClose();
    }
  }

  return (
    <CSSTransition in={isOpen} timeout={350} classNames="slide" mountOnEnter={true} unmountOnExit={true}>
      <Portal>
        <Container onClick={handleOverlayClick}>
          <Panel className="oui-modal__panel" maxWidth={maxWidth}>
            {onRequestClose && (
              <Icon
                size={iconSize}
                color="primarys.0"
                icon={MdClose}
                onClick={onRequestClose ? () => onRequestClose() : undefined}
                style={{ position: "absolute", right: 18, top: 39, cursor: "pointer" }}
              />
            )}

            <Box flex="1" bg={backgroundTitle} p={4} pb={3}>
              {title && (
                <Text fontWeight="bold" fontSize={5}>
                  {title}
                </Text>
              )}
            </Box>

            {children}
          </Panel>
        </Container>
      </Portal>
    </CSSTransition>
  );
}

export default Modal;
