import React from "react";
import styled from "styled-components";
import Text from "../../text/Text";
import { theme } from "styled-tools";

const StyledLabel = styled.div`
  box-sizing: border-box;
  width: 100%;
  transition: 0.2s;
  user-select: none;
  margin-bottom: 6px;
  span {
    color: ${theme("component.label.color")};
  }
`;

const FloatLabel = ({ children, ...rest }) => {
  return (
    <StyledLabel>
      <label {...rest}>
        <Text
          textTransform="uppercase"
          fontFamily="montserrat"
          fontSize={0}
          fontWeight="bold"
          style={{ transition: "0.2s" }}
        >
          {children}
        </Text>
        {/* {required && <Text color="accent.0.3">{"\u2009*"}</Text>} */}
      </label>
    </StyledLabel>
  );
};

export default FloatLabel;
