import React from "react";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Rating from "./Rating";
import { NavLink } from "react-router-dom";

export default ({ title, rating, label, to, ...rest }) => {
  const linkProps = to
    ? {
        to,
        el: NavLink
      }
    : {};

  return (
    <Box px={6} {...rest}>
      {title && (
        <Text color="white" fontWeight="bold" fontSize={4} py={4} {...linkProps}>
          {title}
        </Text>
      )}
      <Box display="flex">
        <Rating rating={rating} mr={4} />
        <Text fontSize={4} color="white" p={3} lineHeight={5} mt="-2px">
          {label}
        </Text>
      </Box>
    </Box>
  );
};
