import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { FiEdit, FiAlertCircle, FiCheckCircle, FiSend } from "react-icons/fi";

import Box from "../uikit/box/Box";
import Icon from "../uikit/icon/Icon";
import Button from "../uikit/button/Button";
import Text, { H3, H4 } from "../uikit/text/Text";
import DotsProgress from "../uikit/progress/DotsProgress";

import ProfileBillingForm from "./ProfileBillingForm";
import ProfileImageUpload from "./ProfileImageUpload";
import ProfileInformationForm from "./ProfileInformationForm";
import ProfileSubscriptionForm from "./ProfileSubscriptionForm";
// import ProfilePaymentForm from "./ProfilePaymentForm";

import ProfileStore from "./ProfileStore";
import { AsideHeading } from "../shared/Aside";
import MainLayout from "../shared/layout/MainLayout";
import { toJS } from 'mobx';

const stripeJs = async () => await import("@stripe/stripe-js");

function ProfilePage({ match, authStore }) {

  const [ profileStore] = useState(() => new ProfileStore(authStore.user.id));
  const [ status, setStatus] = useState(null);
  const { user, isEditingProfile, isEditingBilling, isEditingSubscription } = profileStore;
  const [ emailSent, setEmailSent] = useState(null);

  useEffect(() => {
    if (match.params.callback) {
      setStatus(match.params.callback);
    }
  }, [match.params.callback]);

  const onSendEmail = (e) => {
    e.preventDefault();
    profileStore.resendVerificationEmail().then(() => setEmailSent(true));
  };

  const handleCheckoutClick = async (event) => {
    setStatus(null);

    const { loadStripe } = await stripeJs();
    const publicKey = "pk_test_usDNXEwiog0HoMa7IKSTPalb00oznhJl8o";
    const stripe = await loadStripe(publicKey);
    const { error } = await stripe.redirectToCheckout({
      items: [
        {plan: 'plan_HGb3Vj8Hdd3P1x', quantity: 1}
      ],
      successUrl: window.location.origin + '/profile/success',
      cancelUrl: window.location.origin + '/profile/error',
    });
  };

  if (!user) {
    return null;
  } else {
    console.log('user: ', toJS(user))
  }

  const handleUpdateBillingAddress = billingData => {
    const payload = {
      first_name: user.first_name,
      last_name: user.last_name,
      email_address: user.email_address,
      company_name: user.company_name,
      billing_address_1: billingData.address,
      billing_city: billingData.city,
      billing_state: billingData.state,
      billing_zip: billingData.zip_code
    }
    profileStore.updateUserBilling(payload)
  }

  return (
    <MainLayout
      aside={
        <Box>
          <AsideHeading title="Profile" mt={5} />

          { user.email_verified === 0 && (
            <>
              <Box pl={6} py={5} pr={5} display="flex" alignItems="flex-start">
                <Icon
                  icon={FiAlertCircle}
                  color="accents.1"
                  size={160}
                  mr={3}
                />
                <Text fontSize={1} color="white" lineHeight={5}>
                  Please verify your email address by following the link in the
                  confirmation email address we sent you.
                </Text>
              </Box>
              <Box pl={6} pb={5} pr={5} display="flex" alignItems="flex-end" flexDirection="column">
                <a
                  href="#"
                  onClick={onSendEmail}
                  style={{
                    display: "flex",
                    margin: "auto",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                >
                  <Text color="white" mr={3}>
                    Resend email
                  </Text>
                  <Icon
                    icon={FiSend}
                    color="white"
                    style={{ transform: "translateY(-1px)" }}
                  />
                </a>
                {emailSent && (
                  <span
                    style={{
                      display: "flex",
                      margin: "auto",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                  >
                    <Text color="white" mr={3} mt={5}>
                      Email was successfully sent!
                      <Icon
                        icon={FiCheckCircle}
                        color="green"
                        ml={3}
                        style={{ transform: "translateY(-1px)" }}
                      />
                    </Text>
                  </span>
                )}
              </Box>
            </>
          )}
        </Box>
      }
      main={
        <Box p={5}>
          <Box display="flex" mt={6} mb={3}>
            <H3 mb={0} mr={3}>
              Profile Information
            </H3>

            {!isEditingProfile && (
              <Icon
                onClick={(e) => {
                  profileStore.isEditingProfile = true;
                }}
                icon={FiEdit}
                color="accents.3"
                style={{ transform: "translateY(-4px)" }}
              />
            )}
          </Box>
          <Box maxWidth={400}>
            <ProfileImageUpload defaultSrc={authStore.user.profile_image} />

            {profileStore.isLoadingProfile ? (
              <DotsProgress />
            ) : isEditingProfile ? (
              <ProfileInformationForm
                user={user}
                onSubmit={(formData) => profileStore.updateUserInfo(formData)}
                onClose={(e) => {
                  profileStore.isEditingProfile = false;
                }}
              />
            ) : (
              <>
                <Text mb={2}>{`${user.first_name} ${user.last_name}`}</Text>
                <Text mb={2}>{user.email_address}</Text>
                {user.phone && <Text mb={2}>{user.phone}</Text>}
                {user.company_name && <Text mb={2}>{user.company_name}</Text>}
                {user.job_title && <Text mb={2}>{user.job_title}</Text>}
              </>
            )}
          </Box>

          <Box display="flex" mt={6} mb={3}>
            <H3 mb={2} mr={3}>
              Account Information
            </H3>
          </Box>

          <Box display="flex" mt={3} mb={3}>
            <H4
              textTransform="uppercase"
              fontFamily="montserrat"
              fontSize={2}
              color="secondarys.0"
              fontWeight="bold"
              style={{ transition: "0.2s" }}
            >
              SUBSCRIPTION PLAN
            </H4>
            {!isEditingSubscription && (
              <Icon
                onClick={(e) => {
                  profileStore.isEditingSubscription = true;
                }}
                icon={FiEdit}
                color="accents.3"
                style={{ transform: "translateY(-4px)" }}
                ml={2}
              />
            )}
          </Box>
          <Box maxWidth={400}>

            {profileStore.isLoadingSubscription ? (
              <DotsProgress />
            ) : isEditingSubscription ? (
              <ProfileSubscriptionForm
                user={user}
                plans={profileStore.subscriptionPlans}
                onSubmit={(formData) => profileStore.updateUserSubscription(formData)}
                onClose={(e) => {
                  profileStore.isEditingSubscription = false;
                }}
              />
            ) : (
              <Text mb={2}>Level 1</Text>
            )}
          </Box>

          <Box display="flex" mt={6} mb={3}>
            <Text
              textTransform="uppercase"
              fontFamily="montserrat"
              fontSize={2}
              color="secondarys.0"
              fontWeight="bold"
              style={{ transition: "0.2s" }}
            >
              BILLING ADDRESS
            </Text>
            {!isEditingBilling && (
              <Icon
                onClick={(e) => {
                  profileStore.isEditingBilling = true;
                }}
                icon={FiEdit}
                color="accents.3"
                style={{ transform: "translateY(-4px)" }}
                ml={2}
              />
            )}
          </Box>
          <Box maxWidth={400}>

            { profileStore.isLoadingBilling ? (
              <DotsProgress />
            ) : isEditingBilling ? (
              <ProfileBillingForm
                user={user}
                onSubmit={(formData) => handleUpdateBillingAddress(formData)}
                onClose={(e) => {
                  profileStore.isEditingBilling = false;
                }}
              />
            ) : (
              <>
                <Text mb={2}>{user.billing_address_1 ? user.billing_address_1 : '1234, Street Name'}</Text>
                <Text mb={2}>{user.billing_city ? user.billing_city : 'City Name'}{', '}{user.billing_state ? user.billing_state : 'State'}</Text>
                <Text mb={2}>{user.billing_zip ? user.billing_zip : 'Zip Code'}</Text>
              </>
            )}
          </Box>

          <Box display="flex" mt={6} mb={3}>
            <Text
              textTransform="uppercase"
              fontFamily="montserrat"
              fontSize={2}
              color="secondarys.0"
              fontWeight="bold"
              style={{ transition: "0.2s" }}
            >
              PAYMENT INFORMATION
            </Text>
          </Box>
          <Box maxWidth={400}>
            { status === 'success' && <Text my={3} color="accents.2">Your payment has been successfully processed!</Text>}
            { status === 'error' && <Text my={3} color="accents.0">Your payment could not be processed. Please try again.</Text>}
            <Button role="link" onClick={handleCheckoutClick}>
              Proceed to Checkout
            </Button>
          </Box>

        </Box>
      }
    />
  );
}

export default inject("authStore")(observer(ProfilePage));
