import React, { useState, useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Button from "../../uikit/button/Button";
import TextField from "../../uikit/form/textfield/TextField";
import { useForm } from "react-hook-form";
import { verifyResetPasswordCode } from "../../../stores/AuthService";
import { updatePassword } from "../../../stores/AuthService"

const STATES = Object.freeze({
  INITIAL: "INITIAL",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  FINALLY: "FINALLY"
});

function ChangePassword(props) {
  const [state, setState] = useState(STATES.INITIAL);
  const [user_id, setUserId] = useState();
  const [code, setCode] = useState();

  useEffect(() => {
    if (props.match.params.code) {
      verifyResetPasswordCode(props.match.params.code)
        .then(resp => {
          setCode(resp.data.code);
          setUserId(resp.data.user_id);
          setState(STATES.SUCCESS);
        })
        .catch(e => {
          console.log("verify error", e);
          setState(STATES.ERROR);
        });
    } else {
      return history.push("/");
    }
  }, []);

  const { register, handleSubmit, errors, watch } = useForm({});
  const [formErrors, setFormErrors] = useState({});
  const [formMessage, setFormMessage] = useState({ status: false });
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const onSubmit = data => {
    const objToSend = { password: data.password, code: code }; 
    updatePassword(objToSend, user_id)
    .then(response => {
      if (response) {
        setFormMessage({ status: true, message: 'Your password has been successfully changed' });
        setState(STATES.FINALLY);
      } else {
        setFormErrors({ error: "Could not change password" });
      }
    })
    .catch(serverErrors => {
      console.error("server errors", serverErrors);
      setFormErrors({ error: "Could not change password" });
    });
  };

  return (
    <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="416px" margin="auto">
       {{
          [STATES.INITIAL]: () => null,
          [STATES.SUCCESS]: () => (
            <>
            <Text color="white" fontSize={6} textAlign="center" fontWeight="bold" mb={4}>
              Change Password
            </Text>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Box my={5}>
                <TextField
                  type="password"
                  required
                  label="Password"
                  name="password"
                  ref={register({
                    required: "You must specify a password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters"
                    }
                  }
                  )}
                  error={formErrors.password && formErrors.password.message }
                />
                <TextField
                  type="password"
                  required
                  label="Confirm Password"
                  name="confirm_password"
                  ref={register({
                    validate: (value) => value === watch('password') || "The passwords do not match"
                  })}
                  error={formErrors.confirm_password && formErrors.confirm_password.message }
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                { formErrors.error && (
                  <Text textAlign="center" fontFamily="montserrat" color="accents.0" mb={4}>
                    {formErrors.error}
                  </Text>
                )}
                <Button el={Link} to="/" variant="tertiary" color="white">
                  Cancel
                </Button>
                <Button variant="secondary" color="white" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
            </>
          ),
          [STATES.FINALLY]: () => (
            <>
            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                { formMessage.status && (
                  <Text textAlign="center" fontFamily="montserrat" color="accents.2" mb={4}>
                    {formMessage.message}
                  </Text>
                )}
                <Button el={Link} to="/" variant="secondary" color="white">
                  Go to login
                </Button>
              </Box>
            </>
          ),
          [STATES.ERROR]: () => (
            <>
              <Text textAlign="center" fontFamily="montserrat" color="accents.0">
                Expired password reset or invalid link
              </Text>
            </>
          )
        }[state]()}     
    </Box>
  );
}

export default inject("authStore")(observer(ChangePassword));
