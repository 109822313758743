import React from "react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import LoginForm from "./LoginForm";

function Login(props) {
  return (
    <>
      <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="416px" margin="auto">
        <Text color="white" fontSize={6} textAlign="center" fontWeight="bold" mb={4}>
          Log Into Your Account
        </Text>

        <LoginForm />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" maxWidth="416px" margin="auto" p={5}>
        <Text fontFamily="montserrat" fontSize={0} color="white" mb={2}>
          Don't have an account?
        </Text>

        <Text el={Link} to="/tryforfree" fontFamily="montserrat" fontSize={0} color="white">
          Sign Up
        </Text>
      </Box>
    </>
  );
}

export default Login;
