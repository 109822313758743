import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";

import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";

import ReviewForm from "./ReviewForm";
import { getReviewByMeetingId } from "../MeetingService";

function Review({ authStore, history, match, meetingId, userId }) {
    const [ user, setUser ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ canContinue, setCanContinue ] = useState(false);

    useEffect(() => {
        if (!match.params.skip) {
            getReviewByMeetingId(match.params.meetingId).then(res => {
                if (res) {
                    setCanContinue(true);
                } else {
                    setCanContinue(false);
                    setIsLoading(false);
                }
            }).catch((err) => {
                setCanContinue(true);
            });
        } else {
            setCanContinue(true);
        }
      }, [match.params.userId]);

    useEffect(() => {
        if (canContinue) {
            if(!authStore.user && !match.params.skip) {
                history.push({ pathname: `/login/${match.params.meetingId}/${match.params.userId}/`, redirectTo: 'review' });
                return;
            }
            setIsLoading(false);
            setUser(authStore.user);
        }
    }, [canContinue]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <Box p={5} bg="primarys.0" mb={5}>
                { canContinue ? (
                    <ReviewForm
                        meetingId={meetingId ? meetingId : match.params.meetingId}
                        userId={userId ? userId : match.params.userId}
                    />
                ) : (
                    <Text py={5} color="white" fontSize={6} textAlign="center" fontWeight="bold">
                        The user has already rated this meeting.
                    </Text>
                )}
            </Box>
            { user && (
                <Box display="flex" flexDirection="column" alignItems="center" maxWidth="416px" margin="auto" p={5}>
                    <Text el={Link} to="/" fontFamily="montserrat" fontSize={0} color="white">
                        Go to dashboard
                    </Text>
                </Box>
            )}
        </>
    );
}

export default inject("authStore")(observer(Review));
