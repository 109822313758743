import React, { useState, useEffect } from "react";
import Box from "../../../uikit/box/Box";
import TextField from "../../../uikit/form/textfield/TextField";
import CheckBox from "../../../uikit/form/toggle/CheckBox";
import Button from "../../../uikit/button/Button";
import { useForm } from "react-hook-form";
import { string, object, boolean } from "yup";
import { setEmptyValuesToNull } from "../../../shared/form/utils";

// prettier-ignore
const Schema = object().shape({
  first_name: string().required("First Name is required"),
  last_name: string().required("Last Name is required"),
  email_address: string().email().required("Email is required"),
  job_title: string(),
  company_name: string().required("Company Name is required"),
  is_admin: boolean()
});

function UserForm({ defaultValues, onSubmit, onCancel }) {
  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: Schema
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const onFormSubmit = data => {
    onSubmit &&
      onSubmit(setEmptyValuesToNull(data)).catch(serverErrors => {
        setFormErrors(serverErrors);
      });
  };

  const isAdmin = watch("is_admin", false);
  return (
    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <Box px={4}>
        <Box my={5}>
          <TextField
            label="First Name"
            name="first_name"
            ref={register}
            error={formErrors.first_name && formErrors.first_name.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            label="Last Name"
            name="last_name"
            ref={register}
            error={formErrors.last_name && formErrors.last_name.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            required
            type="email"
            label="Email"
            name="email_address"
            ref={register}
            error={formErrors.email_address && formErrors.email_address.message}
            helperText={formErrors.email_address && formErrors.email_address.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            required
            label="Company Name"
            name="company_name"
            ref={register}
            error={errors.company_name && errors.company_name.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            required
            label="Job Title"
            name="job_title"
            ref={register}
            error={errors.job_title && errors.job_title.message}
          />
        </Box>

        <Box my={5}>
          <CheckBox
            label="Is Admin?"
            name="is_admin"
            ref={register}
            checked={isAdmin}
            onChange={e => {
              setValue("is_admin", e.target.checked);
            }}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" borderTop="1px solid" borderColor="grays.1" pt={5}>
        <Button variant="tertiary" color="black" onClick={() => onCancel()}>
          Cancel
        </Button>

        <Button type="submit">Save</Button>
      </Box>
    </form>
  );
}

export default UserForm;
