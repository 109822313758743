import React from "react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Button from "../../uikit/button/Button";
import Text from "../../uikit/text/Text";
import LoginForm from "./LoginForm";

function LoginReview({ match, history }) {
  const module = history.location.redirectTo;
  return (
    <>
      <Box p={[6, 7]} display="flex" bg="primarys.0" boxShadow={1} maxWidth="416px" mx="auto" mb={5} alignItems="center" >
        <Button to={`/${module}/${match.params.meetingId}/${match.params.userId}/skip`} el={Link} variant="secondary" color="white" margin="auto" px={7}>
            Skip Login
        </Button>
      </Box>

      <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="416px" margin="auto">
        <Text color="white" fontSize={6} textAlign="center" fontWeight="bold" mb={4}>
          Log Into Your Account
        </Text>

        <LoginForm history={history} redirect={`/${module}/${match.params.meetingId}`} />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" maxWidth="416px" margin="auto" p={5}>
        <Text fontFamily="montserrat" fontSize={0} color="white" mb={2}>
          Don't have an account?
        </Text>

        <Text el={Link} to="/register" fontFamily="montserrat" fontSize={0} color="white">
          Sign Up
        </Text>
      </Box>
    </>
  );
}

export default LoginReview;
