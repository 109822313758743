import React, { useState, useEffect } from "react";
import { toJS } from "mobx";
import Media from "react-media";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Scrollbars } from "react-custom-scrollbars";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Icon from "../../uikit/icon/Icon";
import Modal from "../../uikit/modal/Modal";
import Button from "../../uikit/button/Button";

import ActionMenu from "../../shared/ActionMenu";
import MainLayout from "../../shared/layout/MainLayout";
import MeetingList from "../../meeting/list/MeetingList";
import RatingLabel from "../../shared/rating/RatingLabel";
import ContractForm from "../../contract/form/ContractForm";
import { AsideHeading, AsideSubHeading } from "../../shared/Aside";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function ContractDetailPage({ match, contractStore, history }) {
  const [open, setOpen] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [meetings, setMeetings] = useState();
  const contractId = match.params.contractId;
  const module = history.location;

  useEffect(() => {
    if (contractId) {
      contractStore.fetchContract(contractId);
      contractStore.meetingStore
        .fetchMeetingsByContract(contractId)
        .then((res) => {
          setMeetings(contractStore.meetingStore.meetings);
        });
    }
  }, [contractId]);

  if (!contractStore.currentContract) {
    return null;
  }

  const contract = contractStore.currentContract;
  return (
    <>
      <MainLayout
        aside={
          <Box
            display="flex"
            height="100%"
            flexDirection="column"
            overflow="hidden"
          >
            <CustomScrollbars
              autoHide
              autoHideTimeout={500}
              autoHideDuration={200}
              style={{ height: "100%" }}
            >
              <AsideHeading
                title={contract.name}
                mt={5}
                icon={
                  <Media
                    query="(max-width: 959px)"
                    render={() => (
                      <Icon
                        size={24}
                        icon={open ? FiChevronUp : FiChevronDown}
                        color="white"
                        onClick={(e) => {
                          setOpen((s) => !s);
                        }}
                      />
                    )}
                  />
                }
              />
              {open && (
                <Box display="flex" flexDirection="column" pt={5}>
                  <Box flex="1" overflow="auto">
                    <RatingLabel
                      rating={contract.average_rating}
                      label="Overall Agency Rating"
                      mb={3}
                    />
                    <AsideSubHeading>Projects</AsideSubHeading>
                    {contract.projects &&
                    contract.projects.filter((p) => !p.is_archived).length ? (
                      contract.projects
                        .filter((p) => !p.is_archived)
                        .map((p) => {
                          return (
                            <RatingLabel
                              key={p.id}
                              my={4}
                              title={p.name}
                              rating={p.average_rating}
                              label="Overall Project Rating"
                              to={`/project/${p.id}`}
                            />
                          );
                        })
                    ) : (
                      <Text color="grays.1" my={4} px={6}>
                        No Projects
                      </Text>
                    )}
                  </Box>
                  <Box
                    pl={4}
                    py={5}
                    mx={4}
                    borderTop="1px solid"
                    borderColor="secondarys.0"
                  >
                    <Button
                      variant="secondary"
                      disabled={
                        contract.projects &&
                        contract.projects.filter((p) => p.is_archived).length <
                          1
                      }
                      onClick={(e) => {
                        const redirect = `${module.pathname}/archivedProjects`;
                        history.push(redirect);
                      }}
                    >
                      Archived Projects (
                      {contract.projects &&
                        contract.projects.filter((p) => p.is_archived).length}
                      )
                    </Button>
                  </Box>
                  <Box pl={4} pb={5} mx={4}>
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        setModalOpen(true);
                      }}
                    >
                      Edit Contract
                    </Button>
                  </Box>
                </Box>
              )}
            </CustomScrollbars>
          </Box>
        }
        main={
          <Box p={5}>
            <ActionMenu disabled={contract.is_archived} />
            <MeetingList
              mt={5}
              overflow="auto"
              meetings={meetings}
              onRowClick={(meeting) => {
                const redirect = `/project/${meeting.project_id}/meeting/${meeting.meeting_id}`;
                history.push(redirect);
              }}
            />
          </Box>
        }
      />

      <Modal
        title="Edit Contract"
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      >
        <ContractForm
          defaultValues={toJS(contract)}
          onSubmit={(data) => {
            return contractStore.updateContract(data).then(() => {
              setModalOpen(false);
            });
          }}
          onCancel={() => {
            setModalOpen(false);
          }}
          onArchive={(id) => {
            contractStore.archiveContract(id).then(() => {
              setModalOpen(false);
              history.push(`/dashboard`);
            });
          }}
          onUnarchive={(id) => {
            contractStore.unarchiveContract(id).then(() => {
              setModalOpen(false);
              history.push(`/dashboard`);
            });
          }}
        />
      </Modal>
    </>
  );
}

export default inject("contractStore")(observer(ContractDetailPage));
