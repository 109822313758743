import { createTheme } from "./base";

const overideTheme = createTheme();

overideTheme.colors.text = "white";

overideTheme.component.formfield.initial = {
  borderColor: overideTheme.colors.white,
  helperTextColor: overideTheme.colors.white
};

overideTheme.component.formfield.active = {
  borderColor: overideTheme.colors.white,
  helperTextColor: overideTheme.colors.white
};

overideTheme.component.label.color = "white";

export default overideTheme;
