import { register } from "../lib/FetchIntercept";
import jwtDecode from "jwt-decode";
import config from "./config";

/**
 * usage: https://github.com/werk85/fetch-intercept

const refObject = register({
    request: function (url, config) {
        // Modify the url or config here
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});

//destroy
refObject.unregister()
 */

export function createApiInterceptors(authStore) {
  /**
   * Interceptor for adding Auth token to header.
   */
  const ignoreReq = [`/${config.API_VERSION}/auth/login`, `${config.API_URL}/subscription/get`];

  const apiRequests = register({
    request(url, config = {}) {
      let parsed = new URL(url);

      config.headers = config.headers || new Headers();

      // date token validate
      if (authStore.token) {
        const tokenDate = new Date(jwtDecode(authStore.token).exp * 1000);
        const newDate = new Date();
        if (newDate >= tokenDate) {
          authStore.logout();
        }
      }

      // add auth token headers to all routes except ignore list
      if(!ignoreReq.includes(parsed.pathname)) {
        config.headers.append("x-access-token", authStore.token || authStore.public_token);
      }

      return [url, config];
    }
  });

  /**
   * Interceptor for API error codes in responses
   */
  const ignoreResp = [`/${config.API_VERSION}/auth/login`];

  const apiResponseErrors = register({
    response(response, error) {
      let parsed = new URL(response.url);
      if (!ignoreResp.includes(parsed.pathname)) {
        switch (response.status) {
          case 401:
            authStore.logout();
            return;
          case 403:
            authStore.logout();
            return;
          default:
        }
      }

      return response;
    }
  });
}
