import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { string, object } from "yup";
import { formatISO } from "date-fns";
import { useForm, Controller } from "react-hook-form";

import Box from "../../uikit/box/Box";
import Button from "../../uikit/button/Button";
import DateTime from "../../uikit/form/date/DateTime";
import TextField from "../../uikit/form/textfield/TextField";

import { convertDates, setEmptyValuesToNull } from "../../shared/form/utils";

const Schema = object().shape({
  name: string().required("Meeting Name is required"),
  description: string(),
  attendees_email: string().required(),
  start_date: string().required("Start date is required"),
  end_date: string().required("End date is required")
});

function MeetingForm({ defaultValues, onSubmit, onCancel, authStore }) {
  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: Schema,
    defaultValues: defaultValues
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const onFormSubmit = data => {
    const payload = {
      name: data.name,
      description: data.description,
      start_date: formatISO(new Date(data.start_date)),
      end_date: formatISO(new Date(data.end_date)),
      organizer_email: authStore.user.email_address,
      attendees_email: data.attendees_email.concat(`,${authStore.user.email_address}`)
    };

    onSubmit &&
      onSubmit(setEmptyValuesToNull(payload))
        .catch(serverErrors => {
          setFormErrors(serverErrors);
        });
  };
  return (
    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <Box px={4}>
        <Box my={5}>
          <TextField
            label="Meeting Name"
            name="name"
            ref={register}
            error={formErrors.name && formErrors.name.message}
          />
        </Box>

        <Box my={5}>
          <Controller
            as={<DateTime />}
            control={control}
            label="Start Date"
            name="start_date"
            error={formErrors.start_date && formErrors.start_date.message}
          />
        </Box>

        <Box my={5}>
          <Controller
            as={<DateTime />}
            control={control}
            label="End Date"
            name="end_date"
            error={formErrors.end_date && formErrors.end_date.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            multiline={true}
            rows={4}
            label="Description"
            name="description"
            ref={register}
            error={formErrors.description && formErrors.description.message}
          />
        </Box>

        <Box my={5}>
          <TextField
            multiline={true}
            rows={4}
            label="Attendee Emails"
            name="attendees_email"
            helperText={formErrors.attendees_email? formErrors.attendees_email.message : "CSV list of email addresses"}
            error={formErrors.attendees_email && formErrors.attendees_email.message}
            ref={register}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" borderTop="1px solid" borderColor="grays.1" pt={5}>
        <Button variant="tertiary" color="black" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </Box>
    </form>
  );
}

export default inject("authStore")(observer(MeetingForm));
