import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Box from '../../uikit/box/Box';
import Text from '../../uikit/text/Text';
import Logo from '../../uikit/logo/Logo';
import Login from './Login';
import LoginReview from './LoginReview';
import Register from './Register';
import ResetPassword from './ResetPassword';
import ChangePassword from './ChangePassword';
import NonAttendance from '../../meeting/non-attendance/NonAttendance';
import Review from '../../meeting/review/Review';
import Verify from './Verify';
import { ThemeProvider } from 'styled-components';
import agmTheme from '../../uikit/themes/darkTheme';
import { Link } from 'react-router-dom';
import Modal from '../../uikit/modal/Modal';
import FAQ from './FAQ';
import Footer from './Footer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../config/config';

import { useLocation } from 'react-router-dom';

import TryForFree from './TryForFree';
import StartFreeTrial from './StartFreeTrial';

import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';

import './Auth.css';

const GlobalStyle = createGlobalStyle`  
    html, body{
      height: 100vh;      
      overflow:hidden;
    }
    #root{
      height: 100vh;      
      overflow: auto;
    }
    body{
      background: ${theme('colors.primarys.1')};
    }      
`;

//using dark theme here to have white TextFields
const stripeKey =
  process.env.BUILD_ENV === 'production'
    ? process.env.STRIPE_LIVE_KEY
    : process.env.STRIPE_TEST_KEY;
const stripePromise = loadStripe(stripeKey);

function Auth({ authStore }) {
  const location = useLocation();

  const [modalOpenState, setModalOpenState] = useState(false);

  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={agmTheme}>
        <Box minHeight="90vh">
          {location.pathname != '/' && (
            <div className="row space-between header">
              <div style={{ position: 'relative' }}>
                <Logo color="white" style={{ width: '135%' }} />
                <span
                  style={{
                    position: 'absolute',
                    top: '1px',
                    color: 'white',
                    right: '-108px',
                    fontSize: '30px',
                  }}
                >
                  ®
                </span>
              </div>
              <div className="row space-between" style={{ width: '320px' }}>
                <div
                  className="white-border-button"
                  onClick={() => setModalOpenState(true)}
                >
                  <Text
                    color="white"
                    fontFamily="montserrat"
                    fontSize={[5, 8]}
                    textAlign="center"
                    style={{ fontWeight: 'bold' }}
                  >
                    FAQs
                  </Text>
                </div>
                <Link
                  className="white-border-button"
                  style={{ minWidth: '120px', textDecoration: 'none' }}
                  to="/"
                >
                  <Text
                    color="white"
                    fontFamily="montserrat"
                    fontSize={[5, 8]}
                    textAlign="center"
                    style={{ fontWeight: 'bold' }}
                  >
                    Sign In
                  </Text>
                </Link>
              </div>
            </div>
          )}
          <Box
            display="flex"
            justifyContent=""
            alignItems=""
            minHeight={location.pathname === '/' && '100vh'}
            flexDirection="column"
          >
            <GlobalStyle />
            <Box width={1} maxWidth="1724px" m={[6, 'auto']}>
              {location.pathname === '/' && (
                <Box my={[5, 8]} maxWidth={['260px', '300px']} mx="auto">
                  <Box style={{ position: 'relative' }}>
                    <Logo color="white" style={{ width: '100%' }} />
                    <span
                      style={{
                        position: 'absolute',
                        top: '12px',
                        color: 'white',
                        right: '-15px',
                        fontSize: '18px',
                      }}
                    >
                      ®
                    </span>
                  </Box>
                  <Text
                    color="white"
                    fontFamily="montserrat"
                    fontSize={[4, 7]}
                    ml="20%"
                  >
                    Mind Your <var>Business</var>
                    <sup>®</sup>
                  </Text>
                </Box>
              )}

              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/tryforfree" component={TryForFree} />
                <Route
                  exact
                  path="/startfreetrial"
                  component={StartFreeTrial}
                />
                <Route exact path="/verify/:code" component={Verify} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route
                  exact
                  path="/review/:meetingId/:userId"
                  component={Review}
                />
                <Route
                  exact
                  path="/review/:meetingId/:userId/:skip"
                  component={Review}
                />
                <Route
                  exact
                  path="/login/:meetingId/:userId"
                  component={LoginReview}
                />
                {/* 
              <Route exact path="/review/:meetingId" component={Review} />
              <Route exact path="/review/:meetingId/:skip" component={Review} />
            */}
                <Route
                  exact
                  path="/reset-password/:code"
                  component={ChangePassword}
                />
                <Route
                  path="/meeting/did-not-attend/:meetingId/:userId"
                  component={NonAttendance}
                />
                {/*
              <Route exact path="/meeting/did-not-attend/:meetingId" component={NonAttendance} />
              <Route exact path="/meeting/did-not-attend/:meetingId/:skip" component={NonAttendance} />
            */}
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Box>
          </Box>
        </Box>
        <Footer location={location.pathname} />
        <Modal
          backgroundTitle={'#ffffff'}
          maxWidth={'80vw'}
          isOpen={modalOpenState}
          iconSize={40}
          onRequestClose={() => {
            setModalOpenState(false);
          }}
        >
          <FAQ />
        </Modal>
      </ThemeProvider>
    </Elements>
  );
}

export default inject('authStore')(observer(Auth));
