import React from "react";
import Text from "../uikit/text/Text";

export default ({ children, ...rest }) => {
  return (
    <Text
      p={3}
      bg="secondarys.0"
      fontFamily="montserrat"
      color="white"
      fontWeight="bold"
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </Text>
  );
};
