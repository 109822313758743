import React from 'react';
import Text from '../../uikit/text/Text';
import moment from 'moment';
import './Auth.css';

function Footer(location) {
  return (
    <>
      {location !== '/' && (
        <div
          style={{ width: '100vw', background: '#000000', marginTop: 'auto' }}
        >
          <div className="row footer">
            <Text color="white" fontSize={8} textAlign="left" mb={4}>
              {moment().year()}© <var>Agency Minder</var>
              <sup>®</sup>. All rights reserved.
            </Text>
            <div className="row">
              <Text
                color="white"
                fontSize={8}
                textAlign="left"
                mb={4}
                style={{ marginRight: '40px' }}
              >
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/agency-minder-llc/"
                  style={{ textDecoration: 'none' }}
                >
                  Linkedin
                </a>
              </Text>
              <Text color="white" fontSize={8} textAlign="left" mb={4}>
                {/* We still don't have the link to facebook */}
                <a
                  target="_blank"
                  href="https://www.facebook.com/Agency-Minder-1830913677008323/"
                  style={{ textDecoration: 'none' }}
                >
                  Facebook
                </a>
              </Text>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
