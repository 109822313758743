import React from "react";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { observer, inject } from "mobx-react";

import Box from "../../uikit/box/Box";
import Icon from "../../uikit/icon/Icon";
import Text, { H3 } from "../../uikit/text/Text";

import ProjectUsers from "./ProjectUsers";
import MeetingList from "../../meeting/list/MeetingList";

const ProjectDetail = ({ projectStore, location, history }) => {

  if (!projectStore.project || !projectStore.meetingStore) {
    return null;
  }

  const meetings = projectStore.meetingStore.meetings;

  return (
    <>
      <Box borderBottom="1px solid" borderColor="grays.1" mb={6} py={5}>
        <Box display="flex" alignItems="center" mb={4}>
          <H3 mb={0} mr={2}>
            Members
          </H3>
          <Link to={`${location.pathname}/members`}>
            <Icon icon={FiEdit} color="accents.3" style={{ transform: "translateY(-4px)" }} />
          </Link>
        </Box>

        <ProjectUsers />
      </Box>
      {projectStore.project.is_approved === 0 ? (
        <Box p={[6, 7]} bg="primarys.0" maxWidth="500px" margin="auto">
          <Text color="white" fontSize={5} textAlign="center">
          Your team at agencyminder is setting up the project. We will email you once the project is setup. Thank you for your patience.
          </Text>
        </Box>
      ) : (
        <MeetingList
          overflow="auto"
          meetings={meetings}
          isProjectPage={true}
          onRowClick={meeting => {
            history.push(`${location.pathname}/meeting/${meeting.meeting_id}`);
          }}
        />
      )}
    </>
  );
};

export default inject("projectStore")(observer(ProjectDetail));
