import { FiPlus } from "react-icons/fi";
import React, { useState } from "react";

import Box from "../uikit/box/Box";
import Icon from "../uikit/icon/Icon";
import Text from "../uikit/text/Text";

import { uploadProfileImage } from "./ProfileService";

function ProfileImageUpload({ defaultSrc }) {
  const [src, setSrc] = useState(defaultSrc || "");

  function handleUpload(file) {
    uploadProfileImage(file);
    // .then(resp => {
    //   console.log(resp);
    // })
    // .catch(e => {
    //   console.log(e);
    // });
  }

  const onFileChange = e => {
    let file = e.target.files[0];
    if (file) {
      handleUpload && handleUpload(file);

      //show preview in UI
      var reader = new FileReader();
      reader.onloadend = () => {
        setSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box position="relative" width={100} height={100} my={5} display="flex" justifyContent="center" alignItems="center">
      <label>
        <Icon
          icon={FiPlus}
          bg="accents.0"
          color="white"
          borderRadius="50%"
          style={{
            position: "absolute",
            top: "calc(50% - 8px)",
            right: -8,
            zIndex: 10
          }}
        />
        <input type="file" onChange={onFileChange} style={{ display: "none" }} />
      </label>
      <Box
        position="absolute"
        bg="grays.0"
        borderRadius="50%"
        width={100}
        height={100}
        style={{
          backgroundImage: `url(${src})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          overflow: "hidden"
        }}
      ></Box>
      {!src && (
        <Text
          lineHeight={4}
          fontFamily="secondary"
          fontSize={1}
          textAlign="center"
          color="grays.2"
          position="relative"
          zIndex="5"
        >
          Profile
          <br />
          Photo
        </Text>
      )}
    </Box>
  );
}

export default ProfileImageUpload;
