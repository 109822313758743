import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Button from "../../uikit/button/Button";
import TextField from "../../uikit/form/textfield/TextField";
import { useForm } from "react-hook-form";
import { string, object, ref } from "yup";

const Schema = object().shape({
  email_address: string()
    .email("Must be a valid email address")
    .required("Email is required")
});

function ResetPassword(props) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema
  });
  const [formErrors, setFormErrors] = useState({});
  const [formMessage, setFormMessage] = useState({ status: false });
  const [sentResetPassword, setSentResetPassword] = useState(null);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const onSubmit = data => {
    props.authStore.resetPassword(data)
      .then(response => {
        if (response) {
          setFormMessage({ status: true, message: 'Further instructions have been sent to your e-mail address.' });
          setSentResetPassword(true);
        } else {
          setFormErrors({ error: "We don't recognize that e-mail" });
        }
      })
      .catch(serverErrors => {
        console.log("server errors", serverErrors);
        setFormErrors(serverErrors);
      });
  };

  return (
    <Box p={[6, 7]} bg="primarys.0" boxShadow={1} maxWidth="416px" margin="auto">
      <Text color="white" fontSize={6} textAlign="center" fontWeight="bold" mb={4}>
        Reset Password
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {
          !sentResetPassword &&
          <Box my={5}>
            <TextField
              required
              label="Email"
              name="email_address"
              ref={register}
              error={formErrors.email_address && formErrors.email_address.message || formErrors.error }
            />
          </Box>
        }
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
          { formMessage.status && (
            <Text textAlign="center" color="accents.2" mb={4}>
              {formMessage.message}
            </Text>
          )}
          <Button el={Link} to="/" variant="tertiary" color="white">
            Cancel
          </Button>
          {
            !sentResetPassword &&
            <Button variant="secondary" color="white" type="submit">
              Submit
            </Button>
          }
        </Box>
      </form>
    </Box>
  );
}

export default inject("authStore")(observer(ResetPassword));
