import React from "react";
import "../Auth.css";
import Text from "../../../uikit/text/Text";

function Terms() {
  return (
    <div>
      <div className="privacy-main-title">
        <Text fontSize={11}>
          Terms & Policies
          <br />
          Agency Minder Privacy Policy
        </Text>
        <Text style={{
              fontSize: '36px',
              color: 'gray',
              marginTop: '50px'
          }}>
          EFFECTIVE DATE: JANUARY 1, 2020
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Agency Minder offers SAAS services featuring software for secure collection, 
        tracking, and reporting of individual and aggregate employee, contractor, and
        third-party vendor performance data used to evaluate the success level of contract
        performance and fulfillment (collectively, the “Service”), and websites, including
        but not limited to {" "}
        <a href="https://www.agencyminder.com/" target="_blank">
          www.agencyminder.com
        </a>
        (the “Websites”). As you use the Service and interact with the Websites,
        Agency Minder collects and processes information from and about you in
        order to provide you with access to the Service, enhance your experience
        while using the Service, and interact with you. This Privacy Policy
        (the “Policy”) describes how Agency Minder collects, uses, and discloses
        information collected through the Service and Websites, and what 
        choices you have with respect to such information. The first section 
        below explains which privacy terms are applicable to you depending on
        what type of user you are.
        <br />
        <br />
        References to “Agency Minder” throughout the Policy mean the Agency Minder,
        LLC entity that acts as the data controller or data processor of your information,
        as explained in more detail below. By accessing or using the Service and Websites, 
        you acknowledge and agree that you have read, understand, and agree to be bound by 
        this Policy. If you do not agree with this Policy, do not access or use the Service, 
        Websites, or any other part of Agency Minder’s business. 
        <br />
        <br />
        This policy does not apply to third parties that Agency Minder does not own or control. 
        Such third parties are not under Agency Minder’s control and Agency Minder is not 
        responsible for their privacy or security practices.

      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Agency Minder Site Privacy Policy
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        We collect, process, and retain information from you and any devices you
        may use when you use or interact with our Site and Services, and in
        other ways described below.
        <br />
        <br />
        We receive and store information you enter on our Site or give us in any 
        other way, including your name, mailing address, phone number, email address, 
        and payment information, as well as company name, and colleagues’ names and 
        email addresses. We collect information about your use of our Site, such as 
        the pages you view and other interactions you have. We receive and store certain 
        types of information whenever you interact with our Site. Our Site uses “cookies,” 
        tagging and other tracking technologies to help enable us to enhance or personalize 
        your online browsing experience. This information includes computer and connection 
        information such as statistics on your page views, traffic to and from our Site, 
        referral URL, ad data, your IP address, and device identifiers. This information also 
        may include your browsing history, transaction history, and your web log information.
        <br />
        <br />
        We use your information to help us personalize and continually improve your experience 
        on the Site, including fulfilling requests for information, analyzing and compiling trends 
        and statistics, and communicating with you. For information about how to manage your 
        information and the choices you have, see how to limit the use of your information below.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Cookies
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Cookies are alphanumeric identifiers transferred to your computer’s hard drive 
        through your Web browser to enable our systems to recognize your browser and tell 
        us how and when pages in our Site are visited and by how many people. Like most 
        internet sites, Agency Minder uses cookies to enhance our users’ experience on the 
        internet. These cookies do not collect personal information, and we do not combine 
        information collected through cookies with other personal information to tell us who 
        you are or what your name or email address is.
        <br />
        <br />
        Most browsers allow you to prevent the browser from accepting new cookies, 
        to be notified when you receive a new cookie, or to disable cookies. If you delete 
        your cookies or if you set your browser to decline cookies, some features of the 
        Site may not work or may not work as designed. Note that if you choose to remove 
        cookies, you may remove opt-out cookies that affect your advertising preferences.
        <br />
        <br />
        Your browser or device may include “Do Not Track” functionality. Our information 
        collection and disclosure practices, and the choices that we provide to visitors, 
        will continue to operate as described in this privacy notice, whether or not a Do 
        Not Track signal is received.
        <br />
        <br />
        We use Google Analytics, a web analytics service provided by Google, Inc., 
        on our Site. Google Analytics uses cookies or other tracking technologies to 
        help us analyze how users interact with and use the Site, compile reports on the 
        Site’s activity, and provide other services related to Site activity and usage. 
        The technologies used by Google may collect information such as your IP address, 
        time of visit, whether you are a return visitor, and any referring website. The Site 
        does not use Google Analytics to gather information that personally identifies you. 
        The information generated by Google Analytics will be transmitted to and stored by 
        Google and will be subject to Google’s privacy policies. 
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Collection, Use, and Disclosure of End Users’ Information
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        This section describes our policies for handling information about End Users provided 
        to us by our clients using our Services or other sources of their choosing. We provide 
        our clients with the ability to create profiles of their End Users in order to effectively 
        communicate with them via communications tools such as email and text messaging (SMS, MMS), 
        and the ability to export information, at our client’s direction, to other applications. 
        To do this, our clients integrate our technology into their applications, websites, 
        or other online services.
        <br />
        <br />
        Our clients’ collection, use, and disclosure of data is separately governed by their 
        privacy policies. Our clients may collect information about their users directly; in 
        that case, each of our clients is responsible for ensuring that their data collection 
        follows their privacy policies and terms of use.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Collection
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        In the course of providing Services to our clients, Agency Minder may collect personally 
        identifiable information (“PII”) about End Users of our clients. Agency Minder uses this 
        information to provide our Services to our clients. 
        <br />
        <br />
        PII is information that is linked or linkable to you. The PII Agency Minder collects may 
        include name, phone number, email address, company name, website.
        <br />
        <br />
        We may automatically collect information about your use, navigation of, and interactions 
        with our clients’ applications, websites, or other online services when our client has 
        accessed our technology from or installed our technology on its properties.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Use and Disclosure
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        We collect information about End Users at our clients’ direction and may append information to End Users’ profiles from third party sources. We do this to provide our Services, which enable our clients to optimize their communications with, and understanding of, their End Users. We consider our clients’ End Users’ information to be confidential to that client. Accordingly, we do not disclose End User information to unaffiliated third parties except in response to our clients’ explicit instructions, as provided for in an agreement between us and our client, or as otherwise described in this Privacy Policy (for example, as necessary to respond to legal requirements).
        <br />
        <br />
        Agency Minder may provide services or sell products jointly with affiliated businesses. We may share End User information collected from our clients that is related to such transactions with those affiliated businesses. We require our affiliates, via contract, to provide the same level of privacy protection as set forth in this policy and they do not have the right to share or use PII for any purpose other than for an authorized transaction.
        <br />
        <br />
        If we ever were to engage in any onward transfers of your data with third parties for a purpose other than which it was originally collected or subsequently authorized, we would provide you with an opt-out choice to limit the use and disclosure of your personal data.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Collection, Use, and Disclosure of Information from Our Clients
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        This section describes our policies for our clients’ use of the Agency
        Minder Site and Services.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Information Clients Provide to Us
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        We receive and store any information clients enter on the Site, through the Services, or provide to us in any other way. Clients can choose not to provide us with certain information, but then they may not be able to take advantage of some of our features. We use clients’ PII for such purposes as responding to their requests for Services, customizing the content they see, communicating with them about our products, and marketing our Services to them.
        <br />
        <br />
        In order for clients to use all of our features, they must register with Agency Minder. We require clients’ organizations, email addresses, names, and passwords during the registration process.
        <br />
        <br />
        Further information may be required if clients choose to purchase paid components of the Services, such as credit card and billing information. Agency Minder uses third party partners for credit card processing who may require and store your credit card and billing information.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Email Communication
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
      If a client purchases Agency Minder online tools to collect and analyze user and usage data for its websites, applications, or services, Agency Minder will communicate with it about the services it purchases by email. Clients consent to receiving such confirmations by email. If clients contact us via email, we may retain a record of their contact information and any information they provide us in their messages to respond to them. We may receive a confirmation when clients open an email from Agency Minder.
        <br />
        <br />
        We may also send emails with news or promotions. If clients do not want to receive news or promotional email from us, please contact us at admin@agencyminder.com (this will not affect emails related to certain important, legal, or service-related messages such as outage information).
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Information from Other Sources
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        To improve our Services, we may receive information about clients from other sources and add it to our account information. Like the information clients provide to us, we do not share this information with anyone except authorized third parties in connection with providing clients with our Services.
        <br />
        <br />
        We do not disclose clients’ information to unaffiliated third parties without clients’ consent, or as otherwise described in this Privacy Policy (for example, as necessary to respond to legal requirements).
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Other Important Information
        </Text>
      </div>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Your California Privacy Rights
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
      If you are a California resident, California law permits you to request certain information regarding the disclosure of your PII by us and our related companies to third parties for the third parties’ direct marketing purposes. To make such a request, please send your request, by mail or email, to the addresses listed at the end of this policy.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Agents
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
      We employ other companies and people to perform tasks on our behalf and need to share client and End User information with them to provide products and services. Examples include billing, processing payments, providing marketing assistance, and providing customer service. Our agents use the same level of privacy protection as we do. Unless we tell you differently, Agency Minder does not share, and Agency Minder’s agents do not have any right to use, PII collected from our Site and Services beyond what is necessary to assist us.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Business Transfers
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
      In some cases, we may change our ownership or corporate organization, or may choose to buy or sell assets. End User information may be transferred to another entity, its affiliates, or service providers in connection with the transaction. You acknowledge that such transfers may occur, and that Agency Minder or its successor organization may continue to use your information as set forth in this policy.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Protection of Agency Minder and Others
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        We may release PII when we in our sole discretion believe in good faith that release is necessary or appropriate to comply with the law, enforce or apply our conditions of use and other agreements, or protect the rights, property, or safety of Agency Minder, our employees, our clients, or others. We also may be required to disclose an individual’s PII in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Information Security
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Clients’ Agency Minder account information is protected by a password for your privacy and security as well as other reasonable security measures. We use reasonable safeguards to protect the security of clients’ information during transmission, including by encrypting all of the information clients input. Only employees or agents who need PII to perform a specific job are granted access to it. All of our employees are kept up to date on our privacy and security practices.
        <br />
        <br />
        While Agency Minder uses commercially reasonable means to secure information provided to us, we do not guarantee that such information will not be improperly accessed, disclosed, or destroyed by breach of any of our safeguards. Clients and End Users are responsible for the security of their information that is transmitted to us or that is viewed, downloaded, or otherwise accessed when using unencrypted, public or otherwise unsecured networks.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Accessing Information
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        You are able to access, add to, update, correct, amend, or delete certain information about you, including PII. When you update information, however, we often maintain a copy of the unrevised information in our records. A client may deactivate its Agency Minder account by following the deactivation process set forth on the Site. Archived and closed account information may remain in our records after account closure. Please contact us at admin@agencyminder.com if you have questions or concerns about accessing, correcting, or deleting your PII.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Conditions of Use
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Your use of the Site and Services and any possible dispute over privacy are subject to this Privacy Policy and our Terms of Use, including limitations on damages, dispute resolution, and application of Texas state law.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          International Users (Except for EU and Swiss Data Subjects)
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
      If you use our Websites outside of the United States, you understand and consent to the transfer of your personal information to, and the collection, processing, and storage of your personal information in, the United States and elsewhere. The laws in the U.S. and these countries regarding personal information may be different than the laws of your state or country.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Use of Agency Minder’s Site by Children
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Agency Minder’s Site is not intended for children under the age of 16. Accordingly, we will not knowingly collect or use any PII from children that we know to be under the age of 16. 
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Changes to this Privacy Policy
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Agency Minder may amend this Privacy Policy. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we make material changes to our privacy policy, we will notify you by sending you an email thirty (30) days before the changes take effect. 
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Definitions
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        For purposes of this section only, the following definitions shall apply:
        <br />
        <br />
        “Agent” means any third party that collects or uses personal information under the instructions of, and solely for, Agency Minder or to which Agency Minder discloses personal information for use on Agency Minder’s behalf.
        <br />
        <br />
        “Personal information” means any information or set of information that identifies or could be used by or on behalf of Agency Minder to identify (together with other information) a living individual. Personal information does not include information that is anonymized or aggregated. 
        <br />
        <br />
        Data Integrity: Agency Minder will use personal information only in ways that are compatible with the purposes for which it was collected or subsequently authorized by the individual. Agency Minder will take reasonable steps to ensure that personal information is relevant to its intended use, accurate, complete and current.
        <br />
        <br />
        Transfers to Agents: Agency Minder contracts with third parties who perform business functions on our behalf. Agency Minder uses these third parties to assist with activities such as billing, processing payments, providing marketing assistance, and providing customer service. These entities may have access to personal information if needed to perform their functions for Agency Minder.
        <br />
        <br />
        Security: See “Information Security,” above.
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Enforcement
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Agency Minder will conduct compliance audits of its relevant privacy practices to verify adherence to this Policy. Any employee that Agency Minder determines is in violation of this policy will be subject to disciplinary action.
        <br />
        <br />
        Agency Minder is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC).
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Dispute Resolution
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
      Agency Minder commits to resolve complaints about your privacy and our collection or use of your personal information transferred to the United States. Inquiries or complaints should first contact Agency Minder by email at {" "}
        <a href="mailto:admin@agencyminder.com">admin@agencyminder.com</a>
      </Text>
      <div className="privacy-title">
        <Text fontSize={8} style={{ fontWeight: "bold" }}>
          Agency Minder Contact Info
        </Text>
      </div>
      <Text fontSize={7} style={{ lineHeight: "1.5" }}>
        Agency Minder is located at 2018 Three Forks, San Antonio, TX 78258. If you wish to contact us or have any questions about or complaints in relation to this Policy, please contact us at{" "}
        <a href="mailto:admin@agencyminder.com">admin@agencyminder.com</a>
      </Text>
    </div>
  );
}

export default Terms;
