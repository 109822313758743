import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { observer, inject } from "mobx-react";
import { string, object } from "yup";

import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Button from "../../uikit/button/Button";
import SelectField from "../../uikit/form/selectfield/SelectField";

import { mapServerErrors } from "../../../lib/utils";
import { setEmptyValuesToNull } from "../../shared/form/utils";

const Schema = object().shape({
  plan: string().required(),
});

function ProfileSubscriptionForm(props) {

  const [ values, setValues ] = useState(props.authStore.subscriptionPlans);
  const [ selectedValue, setSelectedValue ] = useState(null);

  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema
  });

  const [serverErrors, setServerErrors] = useState(null);

  useEffect(() => {
      props.authStore.subscriptionPlans &&
      setValues(props.authStore.subscriptionPlans);
  })

  const onSubmit = data => {
    setServerErrors(null);
    props.onSubmit(setEmptyValuesToNull(data)).catch(error => {
      console.log("server errors", error);
      if (error.response.error_number) {
        setServerErrors(mapServerErrors(error.response.error_number));
      } else {
        setServerErrors("An error occurred");
      }
    });
  };

  const setSubscriptionPlan = subscriptionPlan => {
    setSelectedValue(subscriptionPlan);
    props.authStore.setSubscriptionPlan(subscriptionPlan);
  }

  const selectFieldOptions = values.length && values.map((data) =>
    <option 
      key={data.id}
      value={data.id}
    >
      {data.name} - ${data.price}
    </option>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>

      <Box mb={5}>
        <Box my={3}>
          <SelectField
            style={{ background: '#ffffff', color: "#333333" }}
            required
            placeholder="Select a Plan"
            name="plan"
            value={selectedValue}
            onChange={e => setSubscriptionPlan(e.target.value)}
            ref={register}
            error={errors.plan && errors.plan.message}>
            {selectFieldOptions}
          </SelectField>
        </Box>
      </Box>

      <Box>
        {serverErrors && (
          <Text textAlign="center" color="accents.0" mb={4}>
            {serverErrors}
          </Text>
        )}
      </Box>
    </form>
  );
}

export default inject("authStore")(observer(ProfileSubscriptionForm));
