import React, { useState, useCallback, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { FiEdit, FiPlusCircle, FiChevronLeft, FiPlus, FiCheckCircle } from "react-icons/fi";

import Box from "../../../uikit/box/Box";
import Icon from "../../../uikit/icon/Icon";
import Text from "../../../uikit/text/Text";
import Modal from "../../../uikit/modal/Modal";
import Button from "../../../uikit/button/Button";
import SelectField from "../../../uikit/form/selectfield/SelectField";

import UserForm from "./UserForm";
import HeaderBlock from "../../../shared/HeaderBlock";
import TitleBlock from "../../../shared/TitleBlock";

const Member = ({ member, projectStore }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = member.is_admin ? useState("admin") : member.is_owner ? useState('owner') : useState('user');
  const [is_admin, setIsAdmin] = useState(false);
  const [is_owner, setIsOwner] = useState(false);

  useEffect(() => {
    if (value === 'admin') {
      setIsAdmin(true);
      setIsOwner(false);
    } else if (value === 'user') {
      setIsAdmin(false);
      setIsOwner(false);
    } else if (value === 'owner') {
      setIsAdmin(false);
      setIsOwner(true);
    }
    console.log('value: ', value)
  }, [value]);

 const savePermissions = () => {
  projectStore.updateUserPermissions(member.id, is_owner, is_admin)
    .then(res => {
      setIsEditing(false);
    })
    .catch(serverErrors => {
      console.log("server errors", serverErrors);
    });
  };

  const deleteMember = () => {
    projectStore.deleteUserFromProject(projectStore.project.id, member.id)
      .then(res => {
        setIsEditing(false);
      })
      .catch(serverErrors => {
        console.log("server errors", serverErrors);
      });
  };

  return (
    <Box mx={3} mb={6}>
      <Box flex="1">
        <Text fontFamily="montserrat" lineHeight={5}>
          {`${member.first_name} ${member.last_name}`}
        </Text>
        <Text fontFamily="montserrat" lineHeight={5}>
          {member.job_title} - {member.company_name}
        </Text>
        <Text fontFamily="montserrat" lineHeight={5}>
          {member.email_address}
        </Text>
        {/* TODO: replace once user.role exists instead of these boolean props */}
        {isEditing ? (
          <>
            <SelectField label="Role" name="role" value={value} onChange={e => setValue(e.target.value)}>
              <option value="owner">Owner</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </SelectField>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button onClick={() => savePermissions()}>Save</Button>
              <Button onClick={() => deleteMember()} variant="tertiary" color="accents.0">
                Delete
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Text fontFamily="montserrat" lineHeight={5}>
              {member.is_admin ? 'Admin' : member.is_owner ? 'Owner' : 'User' }
            </Text>
            <Box
              onClick={() => setIsEditing(true)}
              display="flex"
              alignItems="center"
              style={{ cursor: "pointer" }}
              mt={2}
            >
              <Icon icon={FiEdit} color="accents.3" mr={2} />
              <Text textDecoration="underline" color="accents.3">
                Edit
              </Text>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

function Members({ projectStore }) {
  const [isModalOpen, setModalOpen] = React.useState(false);

  if (!projectStore.members) {
    return null;
  }

  return (
    <>
      <Box my={5}>
        <Link to={`/project/${projectStore.project.id}`} style={{ display: "flex", alignItems: "center" }}>
          <Icon icon={FiChevronLeft} style={{ transform: "translateY(-1px)" }} />
          <Text>Back to project</Text>
        </Link>
      </Box>

      <HeaderBlock title="Edit Members" mb={5}>
        <Box onClick={() => setModalOpen(true)} display="flex" alignItems="center" style={{ cursor: "pointer" }}>
          <Icon icon={FiPlusCircle} color="primarys.0" style={{ transform: "translateY(-1px)" }} mr={1} />
          <Text textDecoration="underline">Add Member</Text>
        </Box>
      </HeaderBlock>

      <Box display="flex" justifyContent="space-between">
        <Box width="50%" mr={5}>
          <TitleBlock bg="none" color="secondarys.0" mb={4}>
            Administrators
          </TitleBlock>
          {projectStore.admins.map(admin => (
            <Member member={admin} projectStore={projectStore} key={admin.id} />
          ))}
        </Box>
        <Box width="50%" ml={5}>
          <TitleBlock bg="none" color="secondarys.0" mb={4}>
            Users
          </TitleBlock>
          {projectStore.users.map(user => (
            <Member member={user} projectStore={projectStore} key={user.id} />
          ))}
        </Box>
      </Box>

      <Modal
        title="Add User"
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      >
        <UserForm
          onSubmit={data => {
            return projectStore.addUserToProject(data).then(() => {
              setModalOpen(false);
            });
          }}
          onCancel={() => {
            setModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

export default inject("projectStore")(observer(Members));
