import { observable, action, toJS, computed } from "mobx";
import {
  getProjectMeetings,
  getMeetingById,
  createOrUpdate,
  getRecentMeetings,
  getContractMeetings,
  createMeeting
} from "./MeetingService";
import { v4 as uuidv4 } from 'uuid';

class MeetingStore {

  projectId = null;
  pages = null;
  @observable offset = 0;
  @observable meetings = null;
  @observable recentMeetings = null;
  @observable currentMeeting = null;

  constructor(projectId, projectStore) {
    if (projectId && projectStore) {
      this.projectId = projectId;
      this.projectStore = projectStore;
      this.fetchMeetings(projectId);
    } else {
      this.fetchRecentMeetings();
    }
  }

  @action.bound
  fetchMeetings(projectId) {
    return getProjectMeetings(projectId)
      .then(data => {
        this.meetings = data;
        return Promise.resolve(data);
      }).catch(e => {
        this.meetings = [];
        console.error('There was an error', e);
      });
  }

  @action.bound
  fetchRecentMeetings() {
    return getRecentMeetings(this.offset).then(data => {
      this.recentMeetings = data;
      if (this.recentMeetings.length > 0) { 
        this.pages = Array.from({ length: this.recentMeetings[0].total }, (_, i) => 1+i);
      }
      return Promise.resolve(data);
    }).catch(e => {
      this.recentMeetings = [];
      this.pages = null;
      console.error('There was an error', e);
    });
  }

  @action.bound
  fetchMeetingsByContract(contractId) {
    return getContractMeetings(contractId).then((data) => {
      this.meetings = data;
      return Promise.resolve(data);
    })
    .catch((e) => {
      this.meetings = [];
      console.error("There was an error", e);
    });
  }

  @action.bound
  setCurrentMeeting(meeting) {
    return getMeetingById(meeting.meeting_id)
      .then(data => {
        this.currentMeeting = data;
        return Promise.resolve(data);
      });
  }

  @action.bound
  getCurrentMeetingFormValues() {
    if (!this.currentMeeting) return null;
    let { date_created, date_modified, attendees, ...values } = toJS(this.currentMeeting);

    values.attendees_email = [];
    attendees.forEach(x => {
      if (x.is_organizer === 1) {
        values.organizer_email = x.email_address;
      } else {
        values.attendees_email.push(x.email_address);
      }
    });
    values.attendees_email = values.attendees_email.join(",");

    return values;
  }

  @action.bound
  create(payload) {
    payload.calendar_id = uuidv4();
    payload.attendees_email = payload.attendees_email.concat(`,${this.projectStore.project.bot_address}`);
    return createMeeting(payload).then(data => {
      if (data) {
        this.fetchMeetings(this.projectId);
        return Promise.resolve(data);
      }
      return Promise.resolve(null);
    });
  }

  @action.bound
  update(payload) {
    payload.calendar_id = this.currentMeeting.calendar_id;
    payload.attendees_email = payload.attendees_email.concat(`,${this.projectStore.project.bot_address}`);
    return createOrUpdate(payload).then(data => {
      if (data) {
        this.fetchMeetings(this.projectId);
        this.currentMeeting = null;
        return Promise.resolve(data);
      }
      return Promise.resolve(null);
    });
  }

  @action.bound
  submitReview() {
    return Promise.resolve(true);
  }
}

export default MeetingStore;
