import React from "react";
import Box from "../box/Box";
import { withTheme } from "styled-components";
import { themeGet } from "@styled-system/theme-get";

// interface Props extends BoxProps {
//     theme: ThemeInterface;
//     icon: IconType;
//     /** Pulls default size from theme.reactIconBase */
//     size?: number;
//     /** styled-system theme color string format. i.e "brand.1" or any color value i.e "red" */
//     color?: string;
//     /** Style for container */
//     style?: object;
//     /** Style for Icon component */
//     iconStyle?: object;

//     verticalAlign?: string;

//     onClick?: (e: any) => void;
// }

function Icon(props, ref) {
  const { icon: Component, iconStyle, color, size, style, verticalAlign = "middle", theme, ...rest } = props;

  const iconProps = {
    style: { ...iconStyle, height: "auto" },
    color: color ? themeGet(`colors.${color}`, color)(props) : theme.reactIconBase.color,
    size: size ? size : theme.reactIconBase.size
  };

  return (
    <Box
      ref={ref}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
      style={{
        overflow: "hidden",
        cursor: props.onClick ? "pointer" : "intial",
        verticalAlign,
        ...style
      }}
    >
      <Component {...iconProps} />
    </Box>
  );
}

export default withTheme(React.forwardRef(Icon));
