import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { useForm } from "react-hook-form";
import { string, object } from "yup";

import Box from "../uikit/box/Box";
import Text from "../uikit/text/Text";
import Button from "../uikit/button/Button";
import TextField from "../uikit/form/textfield/TextField";

import { mapServerErrors } from "../../lib/utils";
import { setEmptyValuesToNull } from "../shared/form/utils";

const Schema = object().shape({
  address: string().required(),
  city: string().required(),
  state: string().required(),
  zip_code: string().required()
});

function ProfileBillingForm(props) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Schema,
    defaultValues: props.user
  });

  const [serverErrors, setServerErrors] = useState(null);

  const onSubmit = data => {
    setServerErrors(null);
    props.onSubmit(setEmptyValuesToNull(data)).catch(error => {
      console.log("server errors", error);
      if (error.response.error_number) {
        setServerErrors(mapServerErrors(error.response.error_number));
      } else {
        setServerErrors("An error occurred");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box mb={5}>
        <Box my={3}>
          <TextField
            required
            label="Address"
            name="address"
            ref={register}
            error={errors.address && errors.address.message}
          />
        </Box>
        <Box my={3}>
          <TextField
            required
            label="City"
            name="city"
            ref={register}
            error={errors.city && errors.city.message}
          />
        </Box>
        <Box my={3}>
          <TextField
            required
            label="State"
            name="state"
            ref={register}
            error={errors.city && errors.city.message}
          />
        </Box>
        <Box my={3}>
          <TextField
            required
            label="Zip Code"
            name="zip_code"
            ref={register}
            error={errors.zip_code && errors.zip_code.message}
          />
        </Box>
      </Box>

      <Box>
        {serverErrors && (
          <Text textAlign="center" color="accents.0" mb={4}>
            {serverErrors}
          </Text>
        )}
        <Button type="submit" mr={4}>
          Save
        </Button>
        <Button variant="secondary" color="black" onClick={props.onClose}>
          Cancel
        </Button>
      </Box>
    </form>
  );
}

export default inject("authStore")(observer(ProfileBillingForm));
